import React, { useEffect } from "react";
import { numberformat } from "../../../../config";
import { Checkbox, Skeleton } from "antd";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import Chart from "react-apexcharts";

export default function Graph(
  series,
  graphLoading,
  selectedGraph,
  setSelectedGraph,
  graphData_,
  graphLabels
) {
  const colorList = [
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
  ];

  const listLabel = Object.fromEntries(
    Object.entries(graphLabels).map(([key, value]) => [value, key])
  );
  const findSign = {
    average_cr: "%",
    average_paid_traffic_cr: "%",
    external_clicks: "$",
    external_clicks_cr: "%",
    organic_clicks: "$",
    organic_clicks_cr: "%",
    paid_clicks: "$",
    paid_clicks_cr: "%",
    sponsored_brands: "$",
    sponsored_brands_cr: "%",
    sponsored_display: "$",
    sponsored_display_cr: "%",
    sponsored_products: "$",
    sponsored_products_cr: "%",
    sponsored_television: "$",
    sponsored_television_cr: "%",
    total_clicks: "$",
    total_paid_clicks: "$",
  };

  const lastGraph = {
    series: series,
    options: {
      chart: {
        type: "line",
        stacked: true,
        height: 350,

        toolbar: { show: !1 },
      },
      stroke: {
        width: series
          ?.filter((item) => selectedGraph?.includes(item.name))
          ?.map((d) => (d?.type === "bar" ? 0 : 3)),
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
        position: "left", // Change legend position to left
      },
      colors: series?.map((d) => d?.color),
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
      },

      yaxis: [
        {
          title: {
            text: "Page Views",
            style: {
              fontSize: "14px",
              fontFamily: `'Rubik',sans-serif`,
              fontWeight: 300,
              color: "#656565",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: `'Rubik',sans-serif`,
              fontWeight: 300,
              color: "#656565",
            },
            formatter: function (value) {
              return `$${numberformat(parseFloat(value).toFixed(0))}`;
            },
          },
        },
        {
          opposite: true,
          title: {
            text: "Ratio",
            style: {
              fontSize: "14px",
              fontFamily: `'Rubik',sans-serif`,
              fontWeight: 500,
              color: "#656565",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: `'Rubik',sans-serif`,
              fontWeight: 500,
              color: "#656565",
            },
            formatter: function (value) {
              return `${numberformat(parseFloat(value).toFixed(2))}%`;
            },
          },
        },
      ],
      // tooltip: {
      //   enabled: true,
      //   followCursor: true,
      //   marker: {
      //     show: true,
      //   },
      //   y: {
      //     formatter: function (
      //       value,
      //       { series, seriesIndex, dataPointIndex, w }
      //     ) {
      //       const seriesName = lastGraph?.series?.[seriesIndex]?.name;

      //       const sign = lastGraph?.series?.[seriesIndex]?.sign;
      //       return (
      //         (sign !== "%" ? sign : "") +
      //         (value || 0).toFixed(2) +
      //         (sign === "%" ? "%" : "")
      //       );
      //     },
      //     // style: {
      //     //   fontSize: "14px",
      //     //   fontFamily: `'Rubik',sans-serif`,
      //     //   fontWeight: 300,
      //     //   color: "#656565",
      //     // },
      //   },
      //   // style: {
      //   //   fontSize: "14px",
      //   //   fontFamily: `'Rubik',sans-serif`,
      //   //   fontWeight: 300,
      //   //   color: "#656565",
      //   // },
      // },
      tooltip: {
        enabled: true,
        followCursor: true,
        marker: {
          show: true,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            const seriesName = lastGraph?.series?.[seriesIndex]?.name;

            const sign = lastGraph?.series?.[seriesIndex]?.sign;
            return (
              (sign !== "%" ? sign : "") +
              (value || 0).toFixed(2) +
              (sign === "%" ? "%" : "")
            );
          },
        },
      },
      xaxis: {
        categories: graphData_?.chartDate || [],

        tickPlacement: "on",

        labels: {
          rotate: -45,
          style: {
            fontSize: "14px",
            fontFamily: `'Rubik',sans-serif`,
            fontWeight: 500,
            color: "#000",
            transform: "rotate(-45deg)",
          },
        },
      },
    },
  };

  const makeData = series
    ?.filter((item) => selectedGraph.includes(item.name))
    ?.map((item) => ({
      ...item,
      type: item.type === "bar" ? "column" : "line",
      lineWidth: selectedGraph?.includes(item.name) ? 3 : 0,
    }));
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const options = {
    chart: {
      type: "line",
      height: 540,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: graphData_?.chartDate || [],
      labels: {
        rotation: -45,
        style: {
          fontSize: "14px",
          fontFamily: `'Rubik',sans-serif`,
          fontWeight: 400,
          color: "#656565",
        },
      },
    },
    yAxis: [
      {
        title: {
          text: "Page Views",
          style: {
            fontSize: "14px",
            fontFamily: `'Rubik',sans-serif`,
            fontWeight: 400,
            color: "#656565",
          },
        },
        min: Math.min(...makeData?.map((d) => d?.data)?.flat()),
        max: Math.max(...makeData?.map((d) => d?.data)?.flat()),
        labels: {
          style: {
            fontSize: "14px",
            fontFamily: `'Rubik',sans-serif`,
            fontWeight: 400,
            color: "#656565",
          },
          formatter: function () {
            return `${numberformat(parseFloat(this.value))}`;
          },
        },
      },
      {
        title: {
          text: "Ratio",
          style: {
            fontSize: "14px",
            fontFamily: `'Rubik',sans-serif`,
            fontWeight: 400,
            color: "#656565",
          },
        },
        labels: {
          style: {
            fontSize: "14px",
            fontFamily: `'Rubik',sans-serif`,
            fontWeight: 400,
            color: "#656565",
          },
          formatter: function () {
            return `${numberformat(parseFloat(this.value))}%`;
          },
        },
        opposite: true,
      },
    ],
    legend: {
      enabled: false,
      align: "left",
      verticalAlign: "top",
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
      bar: {
        borderRadius: 5,
      },
    },
    series: makeData,
    colors: makeData?.map((d) => d?.color),
    tooltip: {
      // enabled: true,
      useHTML: true,
      shared: true,
      // followCursor: true,
      // formatter: function () {
      //   // Format the date and make it bold
      //   return "<strong>" + this.x + "</strong><br>" + "Value: " + this.y;
      // },
      // formatter: function () {
      //   let tooltipText = `<b>${this.x}</b><br/>`; // Use `this.x` for the category name

      //   if (this.points) {
      //     // Check if `this.points` is defined
      //     this.points.forEach((point) => {
      //       tooltipText += `<span style="color:${point.color}">\u25CF</span> ${
      //         point.series.name
      //       }: <b>${point.series.options?.prefix || ""}${numberWithCommas(
      //         point.y
      //       )}${point.series.options?.suffix || ""}</b><br/>`;
      //     });
      //   } else {
      //     // Fallback in case `this.points` is undefined
      //     const point = this;
      //     tooltipText += `<span style="color:${point.color}">\u25CF</span> ${
      //       point.series.name
      //     }: <b>${point.series.options?.prefix || ""}${numberWithCommas(
      //       point.y
      //     )}${point.series.options?.suffix || ""}</b><br/>`;
      //   }

      //   return tooltipText;
      // },
      formatter: function () {
        let tooltipText = `<b style="color: #252f4a; font-size: 14px; font-family: Rubik, sans-serif; font-weight: 500;">${this.x}</b><br/><br/>`; // Category (x-axis label)

        if (this.points) {
          // For shared tooltip (multiple points on the same category)
          this.points.forEach((point) => {
            tooltipText += `<div  style="display: flex; align-items: center;">  <div style="color:${
              point.color
            }; font-size: 19px; margin-right: 8px; ">\u25CF</div> <span style="color: #252f4a; font-size: 14px;">${
              point.series.name
            }</span>: <span style="color: #252f4a; font-size: 14px; font-weight: 600;">${
              point.series.options.prefix || ""
            }${numberWithCommas(point.y)}${
              point.series.options.suffix || ""
            }</span></div>`;
          });
        } else {
          // For single data point tooltip
          tooltipText += `<div  style="display: flex; align-items: center;">  <div style="color:${
            this.color
          }; font-size: 19px; margin-right: 8px; ">\u25CF</div> <span style="color: #252f4a; font-size: 14px;">${
            this.series.name
          }</span>: <span style="color: #252f4a; font-size: 14px; font-weight: 600;">${
            this.series.options.prefix || ""
          }${numberWithCommas(this.y)}${
            this.series.options.suffix || ""
          }</span></div>`;
        }

        return tooltipText;
      },
      shadow: {
        color: "rgba(82, 63, 105, 0.15)", // Shadow color
        offsetX: 0, // Horizontal offset
        offsetY: 0, // Vertical offset
        opacity: 1, // Shadow opacity
        width: 50, // Blur width
      },
      style: {
        fontSize: "14px",
        fontFamily: `'Rubik',sans-serif`,
        fontWeight: 400,
        color: "#656565",
      },
      marker: {
        show: true,
      },
    },
  };
  return (
    <div
      className={`card mt-5 ${lastGraph?.series?.length === 0 ? "d-none" : ""}`}
    >
      <div className="card-header" style={{ minHeight: "50px" }}>
        <div className="card-title">Funnel Performance</div>
      </div>
      <div
        className="card-body"
        style={{
          // filter: graphLoading ? "blur(5px)" : "blur(0)",
          zoom: "110%",
        }}
      >
        {/* lastGraph */}
        <div className="d-flex">
          <div
            className="w-300px"
            style={{
              borderRight: "1px dashed #dbdfe9",
              zoom: "90%",
              maxHeight: "360px",
              overflow: "hidden auto",
            }}
          >
            <div className="hide-show mt-5 d-grid">
              <Checkbox.Group
                onChange={(e) => {
                  setSelectedGraph(e);
                }}
                style={{ width: "300px", display: "grid" }}
                value={selectedGraph}
              >
                {graphLoading
                  ? Array(8)
                      ?.fill(1)
                      ?.map((d, i) => (
                        <div className="d-flex mb-2" key={i}>
                          <Skeleton.Avatar size={"small"} active />
                          <Skeleton.Button
                            active
                            size="small"
                            className="ms-3"
                          />
                        </div>
                      ))
                  : series?.map((d, i) => (
                      <Checkbox
                        className={
                          colorList?.find((f) => f?.key === d?.color)?.value
                        }
                        value={d?.name}
                      >
                        <div
                          className="apexcharts-tooltip-series-group apexcharts-active"
                          style={{ order: 1, display: "flex" }}
                          key={i}
                        >
                          <div
                            className="apexcharts-tooltip-text"
                            style={{
                              fontFamily: "'Montserrat' sans-serif",
                              fontSize: 12,
                            }}
                          >
                            <div className="apexcharts-tooltip-y-group">
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  // fontWeight: 500,
                                  color: "#656565",
                                }}
                                className="apexcharts-tooltip-text-y-label"
                              >
                                {d?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Checkbox>
                    ))}
              </Checkbox.Group>
            </div>
          </div>

          {graphLoading ? (
            <div
              style={{ width: "calc(100vw - 240px)", zoom: "90%" }}
              className="d-flex align-items-center justify-content-center"
            ></div>
          ) : (
            <div style={{ width: "calc(100vw - 240px)", zoom: "90%" }}>
              <HighchartsReact highcharts={Highcharts} options={options} />
              {/* <ReactApexChart
                options={lastGraph?.options}
                series={lastGraph?.series?.filter((item) =>
                  selectedGraph.includes(item.name)
                )}
                type="line"
                height={350}
              /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
