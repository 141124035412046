import React, { Suspense } from "react";
import { Tabs } from "antd";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CantentAccessCatelog from "./content-access-mapping";
import CantentCatelog from "./content-catalog";
import CantentLocationCatelog from "./content-location-catalog";
import Menus from "./menu";
import Location from "./location";
import LocationCatalog from "./location-catalog";
import PendingLocation from "./pending-location";
import LineItemsView from "./line-items";

const { TabPane } = Tabs;

const ContentManagement = () => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);

  const defaultActiveKey = queryParams.get("subTab") || "1";

  return (
    <div id="kt_content_container" className="custom-ui ">
      <div className="row g-2">
        <div className="col-xxl-12">
          <Suspense fallback={<div>Loading...</div>}>
            <Tabs
              // onChange={(e) => {
              //   setActiveTab(e);
              // }}
              onChange={(e) => {
                history.push(
                  `/seller-resources?tabs=${
                    queryParams.get("tabs") || "1"
                  }&subTab=${e}`
                );
              }}
              defaultActiveKey={defaultActiveKey}
            >
              <TabPane tab="Content Catalog" key="1">
                {defaultActiveKey === "1" && <CantentCatelog />}
              </TabPane>
              <TabPane tab="Location Catalog" key="5">
                {defaultActiveKey === "5" && <LocationCatalog />}
              </TabPane>
              <TabPane tab="Pending Location" key="2">
                {defaultActiveKey === "2" && <PendingLocation />}
              </TabPane>
              {/* <TabPane tab="Content Location Catalog" key="2">
                {defaultActiveKey === "2" && <CantentLocationCatelog />}
              </TabPane> */}
              <TabPane tab="Content Access Mapping" key="3">
                {defaultActiveKey === "3" && <CantentAccessCatelog />}
              </TabPane>

              <TabPane tab="Menu" key="4">
                {defaultActiveKey === "4" && <Menus />}
              </TabPane>
              <TabPane tab="Line Items" key="6">
                {defaultActiveKey === "6" && <LineItemsView />}
              </TabPane>
              {/* <TabPane tab="Location" key="5">
                {defaultActiveKey === "5" && <Location />}
              </TabPane> */}
            </Tabs>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default ContentManagement;
