import React, { useContext, useEffect, useRef, useState } from "react";

import { DotChartOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Empty,
  Input,
  Modal,
  Skeleton,
  Spin,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import * as XLSX from "xlsx";
import { MakeApiCall } from "../../../../apis";
import { numberformat, updatePrevDates } from "../../../../config";
import { PopOverDropDown, TableWrapper, Wrapper } from "../style";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // Import the plugin
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // Import if needed
import { GlobalContext } from "../../../../common-context";
import InfoVideo from "../../../../components/header-icons";
import InfoVideoMetric from "../../../../components/header-icons/index_metric";
import { TableLoading } from "../../../../components/table-animation";
import CredentialsError from "../../../credential-page";
import CardsView from "./cards";
import Config from "./lib/config";
import isoWeek from "dayjs/plugin/isoWeek";
import DatePickerView from "./date-picker-view";
import { useHistory } from "react-router-dom";

dayjs.extend(isSameOrAfter); // Extend dayjs with the plugin
dayjs.extend(isSameOrBefore); // Extend if required
dayjs.extend(isoWeek);
const { Text } = Typography;
const { RangePicker } = DatePicker;
dayjs.extend(quarterOfYear);

const Financial = (props) => {
  const { pageTitle, menusList } = props;
  const history = useHistory();

  const [filters, setFilters] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });

  const [filtersLine, setFiltersLine] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });

  const [selectedRange, setSelectedRange] = useState("This Month");

  const [dateFilter, setDateFilter] = useState(
    dayjs().subtract(1, "week").startOf("week")?.format("YYYY-MM-DD")
    // dayjs(getWeekDates(dayjs().format("YYYY-MM-DD"))?.start, "YYYY-MM-DD")
    //   ?.subtract(1, "day")
    //   ?.format("YYYY-MM-DD")
  );
  const a = "100";
  const l = "#F1F1F2";
  const green = "#50cd89";
  const yellow = "#ffc803";
  const red = "#f1416c";

  const optionsComman = {
    chart: {
      fontFamily: "Montserrat",
      type: "area",
      height: a,
      toolbar: { show: !1 },
    },
    legend: { show: !1 },
    dataLabels: { enabled: !1 },
    fill: { type: "solid", opacity: 0 },
    stroke: { curve: "smooth", show: !0, width: 2 },
    xaxis: {
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      labels: { show: !1 },
      crosshairs: {
        position: "front",
        stroke: { width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    yaxis: { labels: { show: !1 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: {
      enabled: false,
      style: { fontSize: "12px" },
      x: {
        formatter: function (e) {
          return "Feb " + e;
        },
      },
      y: {
        formatter: function (e) {
          return "$" + e + "K";
        },
      },
    },

    grid: {
      borderColor: l,
      strokeDashArray: 4,
      padding: { top: 0, right: -20, bottom: -20, left: -20 },
      yaxis: { lines: { show: !0 } },
    },
    markers: {
      size: 2,
      colors: "#FFF",

      hover: {
        size: 3,
      },
    },
  };

  const [viewConfig, setViewConfig] = useState(false);

  const [tabelLoading, setTabelLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState("");

  const [selectedCard, setSelectedCard] = useState({});

  const [convertedData, setConvertedData] = useState([]);
  const [convertedData2, setConvertedData2] = useState([]);

  const [overviewData, setOverviewData] = useState({});
  const [overviewLoading, setOverviewLoading] = useState(true);

  const [lineItemLoading, setLineItemLoading] = useState(false);
  const [lineItemList, setLineItemList] = useState([]);

  const [graphLoading, setGraphLoading] = useState(true);
  const [graphData, setGraphData] = useState({});

  const [selectedGraph, setSelectedGraph] = useState([]);

  const [metricSettingList, setMetricSettingList] = useState([]);
  const [metricSettingLoading, setMetricSettingLoading] = useState(true);

  const [eventNameList, setEventNameList] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({});

  const [resetTable, setResetTable] = useState(true);

  function filterFinancialData(data, filters) {
    if (Object?.keys(filters)?.length === 0) {
      return data;
    }
    return data.filter((item) => {
      for (const key in filters) {
        // Check if the key exists in the item and the value matches
        if (item[key] && filters[key].includes(item[key].toString())) {
          continue;
        } else {
          return false; // If any condition fails, exclude the item
        }
      }
      return true; // Include the item if all conditions are met
    });
  }
  const [allLineItemsList, setAllLineItemsList] = useState([]);
  const [resetFlag, setResetFlag] = useState(false);
  const filteredData = filterFinancialData(
    resetFlag ? allLineItemsList : lineItemList,
    selectedFilters
  );
  const [lineItemTag, setLineItemTag] = useState("This Month");
  const [searchText, setSearchText] = useState("");

  const sales = [
    "product_sales",
    "shipping",
    "reimbursements",
    "promotions",
    "refunds",
  ];
  const expenses = [
    "commission",
    "advertising",
    "fba_fees",
    "storage",
    "other_channel_expenses",
  ];
  const [otherStatic, setOtherStatic] = useState({
    total_net_sales: [],
    cogs: [],
    gross_profit: [],
    total_selling_expenses: [],
    channel_profit: [],
  });
  function convertData(one) {
    const outputObject = {};

    const two = Object?.keys(Object.values(one)?.[0])?.map((d) => {
      Object?.keys(one).forEach((key) => {
        const num = parseFloat(
          parseFloat(one?.[key]?.[d]).toFixed(key?.includes("_pect") ? 0 : 0)
        );

        outputObject[key] = num.toLocaleString("en-US", {
          minimumFractionDigits: key?.includes("_pect") ? 0 : 0,
        });
      });

      return {
        type: d,
        ...outputObject,
      };
    });

    return two;
  }
  function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const [locationData, setLocationData] = useState({});
  const [metricGraphLoading, setMetricGraphLoading] = useState(true);
  const [locationDataLoading, setLocationDataLoading] = useState(true);

  const [reportingData, setReportingData] = useState({});
  const [reportingDataLoading, setReportingDataLoading] = useState(true);

  const PageHeading = locationData?.contentLocation?.filter(
    (d) => d?.location === "Page Heading"
  );
  const FinancialSummary = locationData?.contentLocation?.filter(
    (d) => d?.location === "Financial Summary"
  );

  const getUserReportingPeriod = async () => {
    setReportingDataLoading(true);
    const response = await MakeApiCall(
      `user/reporting-period`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setReportingData(response?.data);

      setReportingDataLoading(false);
    } else {
      setReportingDataLoading(false);
      message.warning(response?.message);
    }
  };

  const getLocationData = async (data) => {
    setLocationDataLoading(true);
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=2`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);

      setLocationDataLoading(false);
    } else {
      setLocationDataLoading(false);
      message.warning(response?.message);
    }
  };
  const GraphAction = async (data) => {
    setGraphLoading(true);
    const response = await MakeApiCall(
      `finance/get-metric-chart?start_date=${
        data?.start_date || filters?.start_date || ""
      }&end_date=${data?.end_date || filters?.end_date || ""}&selection_type=${
        data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
        selectedRange?.split(" ")?.join("_")?.toLowerCase()
      }&menu_item_id=2`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSelectedGraph(
        Object.keys(response?.data?.chartData)?.map((d) =>
          d === "GP Margin" ? "GP Margin" : capitalizeEachWord(d?.toLowerCase())
        )
      );

      setGraphData(response?.data);
      setGraphLoading(false);
    } else {
      setGraphLoading(false);
      message.warning(response?.message);
    }
  };

  const lineItemsFilterList = [
    {
      label: "Product Sales",
      value: "product_sales",
    },
    {
      label: "Shipping",
      value: "shipping",
    },
    {
      label: "Reimbursements",
      value: "reimbursements",
    },
    {
      label: "Promotions",
      value: "promotions",
    },
    {
      label: "Refunds",
      value: "refunds",
    },
    {
      label: "Commission",
      value: "commission",
    },
    {
      label: "Advertising",
      value: "advertising",
    },
    {
      label: "Fba Fees",
      value: "fba_fees",
    },
    {
      label: "Storage",
      value: "storage",
    },
    {
      label: "Other Channel Expenses",
      value: "other_channel_expenses",
    },
  ];
  const [cardList, setCardList] = useState([]);

  const getMetricSetting = async () => {
    try {
      setMetricSettingLoading(true);
      const response = await MakeApiCall(
        `metric-setting?menu_item_id=2`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setMetricSettingList(
          response?.data?.map((r) => ({ ...r, menu_item_id: 2 }))
        );
        setMetricSettingLoading(false);
      } else {
        setMetricSettingLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setOverviewLoading(false);
    }
  };
  const getAllLineItems = async (data) => {
    const response = await MakeApiCall(
      `finance/line-items-by-metrics?start_date=${
        data?.start_date || filtersLine.start_date || ""
      }&end_date=${
        data?.end_date || filtersLine.end_date || ""
      }&metric_name=${lineItemsFilterList?.map((d) => d?.value)?.join(",")}`,
      "get",
      null,
      true
    );
    if (response?.status === true) {
      const tableData = response?.data
        ?.filter((item) => parseInt(item.amount) !== 0)
        ?.map((d) => {
          return {
            order_date: d?.finance_date,
            line_items:
              d?.metric_name
                ?.split("_")
                ?.join(" ")
                ?.replace(/\b\w/g, (c) => c.toUpperCase()) ?? "",
            type: d?.event_type,
            order_id: d?.order_id,
            sku: d?.sku,
            quantity: d?.quantity,
            event_list: d?.feesType,
            marketplace: d?.marketplace,
            sales_channel: d?.fulfilment,
            product_sales: parseFloat(d?.amount).toFixed(2),
            Description: d?.fees_type,
          };
        });
      setAllLineItemsList(tableData || []);
      setLineItemLoading(false);
    } else if (response?.status === false) {
      setLineItemLoading(false);
      setAllLineItemsList([]);
    }
  };
  const getList = async (data) => {
    GraphAction(data);
    getAllLineItems(data);
    const response = await MakeApiCall(
      `finance/get-summary?start_date=${
        data?.start_date || filters?.start_date || ""
      }&end_date=${data?.end_date || filters?.end_date || ""}&selection_type=${
        data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
        selectedRange?.split(" ")?.join("_")?.toLowerCase()
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTabelLoading(false);

      const one = convertData(response?.data)?.filter((item) =>
        sales.includes(item.type)
      );
      const two = convertData(response?.data)?.filter((item) =>
        expenses.includes(item.type)
      );
      setOtherStatic({
        total_net_sales: convertData(response?.data)?.filter((item) =>
          ["total_net_sales"].includes(item.type)
        )?.[0],
        cogs: convertData(response?.data)?.filter((item) =>
          ["cogs"].includes(item.type)
        )?.[0],
        gross_profit: convertData(response?.data)?.filter((item) =>
          ["gross_profit"].includes(item.type)
        )?.[0],
        total_selling_expenses: convertData(response?.data)?.filter((item) =>
          ["total_selling_expenses"].includes(item.type)
        )?.[0],
        channel_profit: convertData(response?.data)?.filter((item) =>
          ["channel_profit"].includes(item.type)
        )?.[0],
      });

      setConvertedData(one);
      setConvertedData2(two);
    } else {
      setTabelLoading(false);
      message.warning(response?.message);
    }
  };
  const getOverview = async (data) => {
    getMetricSetting();
    setMetricGraphLoading(true);
    setOverviewLoading(true);
    const response = await MakeApiCall(
      `finance/daily-overview?filter_date=${
        data || dateFilter || ""
      }&menu_item_id=2`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (Object.keys(response?.data || {})?.length === 0) {
        setOverviewData({});
        setCardList([]);
        setSelectedCard({});
        setOverviewLoading(false);
        setMetricGraphLoading(false);
        return;
      }
      const cardList__ = Object.entries(response?.data || {})?.reduce(
        (acc, [d, v]) => {
          return [
            ...acc,
            {
              label: v?.metric_name,
              root: d,
              metric_slug: v?.metric_slug,
              id: v?.metric_id,
              prefix:
                v?.metric_unit === "Range$" || v?.metric_unit === "$"
                  ? "$"
                  : "",
              suffix:
                v?.metric_unit === "Range%" || v?.metric_unit === "%"
                  ? "%"
                  : "",
            },
          ];
        },
        []
      );

      setCardList(cardList__);
      setSelectedCard({
        ...cardList__?.[0],
      });
      setMetricGraphLoading(false);
      setOverviewData(response?.data);
      setOverviewLoading(false);
    } else {
      setOverviewData({});
      setCardList([]);
      setSelectedCard({});
      setOverviewLoading(false);
      setMetricGraphLoading(false);
      message.warning(response?.message);
    }
  };
  const apiCall = {
    other_channel_expenses: [
      "Adjustments",
      "CouponPayment",
      "Service Fee",
      "Shipping Service",
      "Deal Fee",
    ],
    "Total Net Sales": [
      "product_sales",
      "shipping",
      "reimbursements",
      "promotions",
      "refunds",
    ],
  };

  const getLineItems = async (data) => {
    setLineItemLoading(true);
    const response = await MakeApiCall(
      `finance/${data?.api || "get-line-items"}?start_date=${
        data?.start_date || filtersLine.start_date || ""
      }&end_date=${
        data?.end_date || filtersLine.end_date || ""
      }&${`metric_name=${data?.FilterType ? "" : data?.type || selectedRow}`}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const tableData = response?.data
        ?.filter((item) => parseInt(item.amount) !== 0)
        ?.map((d) => {
          return {
            order_date: d?.finance_date,
            line_items:
              d?.metric_name
                ?.split("_")
                ?.join(" ")
                ?.replace(/\b\w/g, (c) => c.toUpperCase()) ?? "",
            type: d?.event_type,
            order_id: d?.order_id,
            sku: d?.sku,
            quantity: d?.quantity,
            event_list: d?.feesType,
            marketplace: d?.marketplace,
            sales_channel: d?.fulfilment,
            product_sales: parseFloat(d?.amount).toFixed(2),
            Description: d?.fees_type,
          };
        });

      setLineItemList(tableData);
      setLineItemLoading(false);
    } else {
      setLineItemList([]);

      setLineItemLoading(false);
      message.warning(response?.message);
    }
  };

  const saveMetricSetting = async (data) => {
    setMetricSettingLoading(true);
    const response = await MakeApiCall(
      `metric-setting/save`,
      "post",
      data,
      true
    );

    if (response?.status) {
      setOverviewLoading(true);
      getOverview();
      setViewConfig(false);
    } else {
      setOverviewLoading(true);
      getOverview();

      message.warning(response?.message);
    }
  };
  const contextValue = useContext(GlobalContext);
  const { lws, sp } = contextValue?.data?.credentialsStatus;

  useEffect(() => {
    try {
      setOverviewLoading(true);
      getOverview();
      setTabelLoading(true);
      getList();
      setReportingDataLoading(true);
      getUserReportingPeriod();
      setLocationDataLoading(true);
      getLocationData();
    } catch (error) {
    } finally {
      setOverviewLoading(false);
      setTabelLoading(false);
      setReportingDataLoading(false);
      setLocationDataLoading(false);
    }
  }, []);
  const filterIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6668 2H1.3335L6.66683 8.30667V12.6667L9.3335 14V8.30667L14.6668 2Z"
        stroke="#696974"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const filterIconSelected = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6668 2H1.3335L6.66683 8.30667V12.6667L9.3335 14V8.30667L14.6668 2Z"
        stroke="#00000000"
        fill="#1f97d3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const ColumnsList = {
    date: "order_date",
    line_items: "line_items",

    transaction_type: "type",

    Description: "Description",
    order_id: "order_id",

    sku: "sku",
    quantity: "quantity",
    marketplace: "marketplace",
    fulfilment: "sales_channel",
    amount: "product_sales",
  };
  const ColumnsWidth = {
    date: 150,
    transaction_type: 200,
    line_items: 180,
    order_id: 200,
    sku: 250,
    quantity: 150,
    marketplace: 150,
    fulfilment: 150,
    amount: 150,
    Description: 150,
  };

  const columnNames = {
    ...ColumnsList,
  };

  const columns = Object?.keys(columnNames)?.map((columnName, i) => ({
    title:
      columnName === "amount"
        ? "Amount $"
        : columnName === "sku"
        ? "SKU"
        : columnName
            ?.replace(/_/g, " ")
            ?.replace("_", " ")
            .replace(/\b\w/g, (c) => c.toUpperCase()),

    filters: [],
    filterMode: "tree",
    width: ColumnsWidth?.[columnName],
    defaultSortOrder: columnName === "date" ? "ascend" : "",
    align:
      columnName === "quantity" || "amount" === columnName ? "right" : "left",
    filterIcon: (filtered) => (filtered ? filterIconSelected : filterIcon),
    sorter: (a, b) => {
      return typeof a?.[columnNames?.[columnName]] === "string"
        ? (a?.[columnNames?.[columnName]] || "").localeCompare(
            b?.[columnNames?.[columnName]] || ""
          )
        : parseFloat(a?.[columnNames?.[columnName]] || 0) -
            parseFloat(b?.[columnNames?.[columnName]] || 0);
    },

    onFilter: (value, record) => {
      const columnValue = record[columnNames?.[columnName]]
        ?.toString()
        ?.toLowerCase();
      const filterValue = value?.toLowerCase();

      return columnValue?.includes(filterValue);
    },
    filterSearch: true,
    onFilterDropdownVisibleChange: (visible, _) => {
      if (visible) {
        // Optionally, perform additional actions when filter dropdown is opened
      }
    },
    defaultFilteredValue:
      columnName === "line_items"
        ? [
            ...new Set(
              filteredData?.map((d) => d?.[columnNames?.[columnName] || ""])
            ),
          ]
        : [],
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const newOP =
        "line_items" === columnName
          ? [...new Set(allLineItemsList?.map((d) => d?.line_items))]
          : [
              ...new Set(
                filteredData?.map((d) => d?.[columnNames?.[columnName] || ""])
              ),
            ];
      let filteredOptions;
      try {
        filteredOptions = newOP
          ?.filter((d) => !d && d !== "")
          ?.filter((option) =>
            option?.toLowerCase()?.includes(searchText?.toLowerCase())
          );
      } catch (error) {}

      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: 8, display: "block", width: "100%" }}
          />
          <Checkbox
            checked={selectedKeys?.length === filteredOptions?.length}
            onChange={(e) => {
              setSelectedKeys(
                selectedKeys?.length === filteredOptions?.length
                  ? []
                  : filteredOptions?.filter((d) => d)
              );
            }}
            className="my-3"
          >
            {selectedKeys?.length === filteredOptions?.length ? (
              <b>Deselect All</b>
            ) : (
              <b>Select All</b>
            )}
          </Checkbox>
          <Checkbox.Group
            style={{
              display: "grid",
              justifyItems: "start",
              gap: "10px",
              marginBottom: "15px",
              maxHeight: "250px",
              overflow: "auto",
            }}
            options={filteredOptions?.filter((d) => d)}
            value={selectedKeys}
            onChange={(e) => {
              setSelectedKeys(e);
            }}
          />

          <Button
            type="primary"
            disabled={selectedKeys?.length === 0}
            onClick={() => {
              setSelectedFilters(
                Object.fromEntries(
                  Object.entries({
                    ...selectedFilters,
                    [ColumnsList?.[columnName]]: selectedKeys,
                  }).filter(([key, value]) => value.length > 0)
                )
              );
              if (columnName === "line_items") {
                if (resetFlag === false) {
                  setResetFlag(true);
                }
              }
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Apply
          </Button>
          {(selectedFilters?.[ColumnsList?.[columnName]] ||
            columnName === "line_items") && (
            <Button
              onClick={() => {
                if (columnName === "line_items") {
                  if (resetFlag === false) {
                    setResetFlag(true);
                  }
                }

                clearFilters();
                confirm();
                setSelectedKeys([]);
                setSelectedFilters(
                  Object.fromEntries(
                    Object.entries({
                      ...selectedFilters,
                      [ColumnsList?.[columnName]]: [],
                    }).filter(([key, value]) => value.length > 0)
                  )
                );
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          )}
        </div>
      );
    },
    render: (e) => {
      if (columnName === "amount") {
        return `${parseFloat(
          parseFloat(e?.product_sales).toFixed(2)
        ).toLocaleString()}`;
      }

      return (
        <span
          style={{
            textTransform: columnName === "event_list" ? "capitalize" : "none",
          }}
        >
          {e?.[columnNames?.[columnName]] === "Fba Fees"
            ? "FBA Fees"
            : e?.[columnNames?.[columnName]] || "-"}
        </span>
      );
    },
  }));

  const presetRanges_ = [
    // {
    //   label: "Yesterday",
    //   value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    // },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs()],
    },
    {
      label: "Last Week",
      value: [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "week").endOf("week"),
      ],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs()],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs()],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs()],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];

  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });

  const signFind = {
    salesData: "$",
    grossProfitData: "$",
    gpMarginData: "%",
    expensesData: "$",
    totalProfitData: "$",
    netMarginData: "%",
    refundData: "$",
  };

  const borderColor = {
    1: "#E31715",
    2: "#FDC71E",
    3: "#00B55D",
  };
  const backgroundColor = {
    1: "#FFF6F6", // red
    2: "#FFFEF6", // yellow
    3: "#F6FFFA", // green
  };

  const getBorderColor = (value) => {
    if (value == 0 || value === "STAGNANT") {
      return 2;
    }
    if (value === "DOWN") {
      return 1;
    }
    if (value === "UP") {
      return 3;
    }
    return 3;
  };

  const getFooter = (value) => {
    if (value == 0 || value === "Flat") {
      return 2;
    }
    if (value === "FLAT") {
      return 2;
    }
    if (value === "Falling") {
      return 1;
    }
    if (value === "FALLING") {
      return 1;
    }
    if (value === "Rising") {
      return 3;
    }
    if (value === "RISING") {
      return 3;
    }
    return 3;
  };
  const updateChartOptions = (color) => {
    return {
      ...optionsComman,
      stroke: { ...optionsComman.stroke, colors: [color] },
      colors: [color],
      markers: {
        ...optionsComman.markers,
        strokeColors: color,
      },
      xaxis: {
        ...optionsComman?.xaxis,
        crosshairs: {
          ...optionsComman?.xaxis.crosshairs,
          stroke: {
            ...optionsComman?.xaxis.crosshairs.stroke,
            colors: [color],
          },
        },
      },
    };
  };

  const getOp = (value) => {
    switch (value) {
      case 0:
      case "STAGNANT":
        return updateChartOptions(yellow);
      case "DOWN":
        return updateChartOptions(red);
      case "UP":
        return updateChartOptions(green);
      default:
        return updateChartOptions(green); // or any default color
    }
  };

  const getArrowColor = (value) => {
    if (!value || value == 0 || value === "Flat") {
      return "rotateX(92deg)";
    }
    if (value === "FLAT") {
      return "rotateX(92deg)";
    }
    if (value === "Falling") {
      return "rotateX(180deg)";
    }
    if (value === "FALLING") {
      return "rotateX(180deg)";
    }
    if (value === "Rising") {
      return "rotateX(0deg)";
    }
    if (value === "RISING") {
      return "rotateX(0deg)";
    }
    return "rotateX(0deg)";
  };

  const [restart, setRestart] = useState(true);

  const graphDataFiltered =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.metric_value
    ) || [];

  const graphDataFilteredEma_value =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.ema_value
    ) || [];

  const numberformat_ = (num) => {
    // Format number with no decimals
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const minGraph = Math.min(
    ...([...graphDataFiltered, ...graphDataFilteredEma_value] || [])
  );
  const maxGraph = Math.max(
    ...([...graphDataFiltered, ...graphDataFilteredEma_value] || [])
  );

  const revenu_status = {
    chart: {
      height: 355,
      type: "area",
      toolbar: { show: !1 },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    colors: [
      overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
      borderColor?.[
        getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
      ] === "#E31715"
        ? "#00B55D"
        : overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
          borderColor?.[
            getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
          ] === "#00B55D"
        ? "#E31715"
        : borderColor?.[
            getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
          ],

      "#000000",
      "#3c71b8",
    ],
    markers: {
      size: [2, 0],
      colors: "#FFF",
      strokeColors: [
        overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
        borderColor?.[
          getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
        ] === "#E31715"
          ? "#00B55D"
          : overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
            borderColor?.[
              getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
            ] === "#00B55D"
          ? "#E31715"
          : borderColor?.[
              getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
            ],

        "#3c71b8",
      ],
      hover: {
        size: 5,
      },
    },
    xaxis: {
      tickAmount: 7,
      offsetX: 10,
      labels: {
        rotateAlways: -45,
        style: {
          fontWeight: "bold",
        },
      },
    },
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: ["straight", "smooth"],
      width: [3, 2],
      dashArray: [0, 4],
      lineCap: "round",
    },
    labels:
      overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
        (d) => d?.date || d?.weekNum || d?.weekDate || d?.chartDate
      ) || [],
    yaxis: [
      {
        seriesName: "",
        tickAmount: 7,
        axisTicks: {
          show: true,
        },
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
      {
        seriesName: "EMA",
        tickAmount: 7,
        opposite: true,
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        axisTicks: {
          show: true,
        },

        title: {
          text: "Path",
          style: {
            fontWeight: 700,
            color: "#065ad8",
            fontSize: "1.15rem",
          },
        },
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
    ],

    grid: {
      strokeDashArray: 3,
      yaxis: { lines: { show: !1 } },
      xaxis: {
        lines: {
          show: true,
          offsetX: 0,
        },
      },
    },
    legend: { show: false },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.05,
        opacityTo: 0.05,
        stops: [45, 100],
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const label = w?.globals?.categoryLabels?.[value - 1] || "";
          return `<strong>${label}</strong>`;
        },
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const prefix = selectedCard?.prefix || "";
          const suffix = selectedCard?.suffix || "";

          return signConvert(
            prefix +
              numberformat(parseFloat(parseFloat(value || 0).toFixed(2))) +
              suffix
          );
        },
      },
    },
  };
  const isThis =
    filters?.start_date === dayjs().startOf("month").format("YYYY-MM-DD") &&
    filters?.end_date === dayjs().format("YYYY-MM-DD");

  const typeGraph = {
    Expenses: "bar",
    "GP Margin": "bar",
    "Gross Profit": "bar",
    "Net Margin": "line",
    Profit: "line",
    Refunds: "line",
    Sales: "bar",
  };

  const chartRef = useRef(null);

  const colorList = [
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
  ];

  let series = Object?.entries(graphData?.chartData || {})?.map(
    ([key, value], i) => {
      const fSign =
        capitalizeEachWord(key?.toLowerCase()) !== "Gp Margin" &&
        capitalizeEachWord(key?.toLowerCase()) !== "Net Margin"
          ? "$"
          : "";

      return {
        name:
          key === "GP Margin"
            ? "GP Margin"
            : capitalizeEachWord(key?.toLowerCase()),
        type: typeGraph?.[key],

        data: value?.map((d) => parseFloat(d)),
        color: colorList?.map((r) => r?.key)?.[i],
      };
    }
  );

  const lastGraph = {
    series: series,
    options: {
      chart: {
        height: 350,

        type: "line",
        toolbar: { show: !1 },
      },
      stroke: {
        width: series
          ?.filter((item) => selectedGraph.includes(item.name))
          ?.map((d) => (d?.type === "bar" ? 0 : 3)),
        curve: "smooth",
      },
      legend: {
        show: false,
        position: "left", // Change legend position to left
      },
      colors: series?.map((d) => d?.color),
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontFamily: `'Rubik',sans-serif`,
            fontWeight: 300,
            color: "#656565",
          },
          formatter: function (value) {
            // Customize the Y-axis label format here
            return `$${numberformat(parseFloat(value).toFixed(0))}`;
          },
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        marker: {
          show: true,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            const seriesName = lastGraph?.series?.[seriesIndex]?.name;
            const fSign =
              seriesName !== "Gp Margin" && seriesName !== "Net Margin"
                ? "$"
                : "";
            const sSign =
              seriesName === "Gp Margin" || seriesName === "Net Margin"
                ? "%"
                : "";

            return (fSign + (value || 0).toFixed(2) + sSign).replace(
              "$-",
              "-$"
            );
          },
        },
        style: {
          fontSize: "14px",
          fontFamily: `'Rubik',sans-serif`,
          fontWeight: 300,
          color: "#656565",
        },
      },
      xaxis: {
        categories: graphData?.chartDate || [],

        tickPlacement: "on",

        labels: {
          // formatter: function (value, timestamp) {
          //   return dayjs(value, "MMM-DD").date() % 2 !== 0 ? value : "";
          // },
          rotate: -45,
          style: {
            fontSize: "14px",
            fontFamily: `'Rubik',sans-serif`,
            fontWeight: 300,
            color: "#656565",
            transform: "rotate(-45deg)",
          },
        },
      },
    },
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const downloadFileLineItems = async (filename, filtersLine, title) => {
    message.destroy();
    message.loading("Downloading...");
    const response = await MakeApiCall(
      `download-manager/export`,
      "post",
      {
        source_type: "Business 360",
        record_type: title,
        start_date: filtersLine?.start_date,
        end_date: filtersLine?.end_date,
        param_filter: {
          start_date: filtersLine?.start_date,
          end_date: filtersLine?.end_date,
          metric_name: filename,
          summary_type: "",
          selection_type: "",
        },
      },
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      history.push("/download-manager");
      console.log(response, "response");
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const downloadExcel = (filename, title) => {
    downloadFileLineItems(filename, filtersLine, title);
    return;

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    const changeKeyList = filteredData.map((item) => {
      // Destructure the item, rename the key, and return a new object
      const { product_sales, event_list, ...rest } = item;
      return { ...rest, amount: product_sales };
    });
    // Add a worksheet to the workbook
    const ws = XLSX.utils.json_to_sheet(changeKeyList);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Convert the workbook to a binary Excel file (binary string)
    const excelBinaryString = XLSX.write(wb, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const signConvert = (value) => {
    return value.replace("$-", "-$");
  };

  function getWeekDates(date) {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - dayOfWeek);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    return {
      start: startDate.toISOString().split("T")[0],
      end: endDate.toISOString().split("T")[0],
    };
  }

  const disabledDate = (current) => {
    return (
      current &&
      current >=
        moment(getWeekDates(dayjs().format("YYYY-MM-DD"))?.start).startOf("day")
    );
  };
  const handleDateRangeChange = (value, dateString) => {
    const selectedPresetRange = presetRanges.filter((range) => {
      return (
        dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
        dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
      );
    });

    const date1 = new Date(dateString?.[0]);
    const date2 = new Date(dateString?.[1]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days

    setSelectedRange(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    setLineItemTag(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    return selectedPresetRange?.length !== 0
      ? selectedPresetRange?.[0]?.label
      : "custom";
  };
  const handleDateRangeChangeLineItems = (value, dateString) => {
    const selectedPresetRange = presetRanges.filter((range) => {
      return (
        dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
        dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
      );
    });

    const date1 = new Date(dateString?.[0]);
    const date2 = new Date(dateString?.[1]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    setLineItemTag(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    return selectedPresetRange?.length !== 0
      ? selectedPresetRange?.[0]?.label
      : "custom";
  };

  useEffect(() => {
    if (!resetTable) {
      setSelectedFilters({});
      setTimeout(() => {
        setResetTable(true);
      }, 50);
    }
  }, [resetTable]);

  const showMetrix = () => {
    if (metricSettingList?.length === 0) {
      message.destroy();
      return message.warning("Config List is not available");
    }
    setViewConfig(true);
  };
  const [visible, setVisible] = useState(false);

  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "business-360")?.is_restricted === 1
  ) {
    return <CredentialsError type="financial" />;
  }
  if (selectedRow) {
    const calculateTotal = (data, key) => {
      return data.reduce((sum, item) => sum + parseFloat(item[key]), 0);
    };
    const getRowClassName = (record, index) => {
      return index % 2 === 0 ? "even-row-new" : "odd-row-new";
    };
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getTitles = () => {
      if (resetFlag) {
        return "Financial Transactions";
      }
      if (!selectedFilters?.line_items) {
        if (selectedRow === "fba_fees") {
          return "FBA Fees";
        }
        return selectedRow
          ?.split("_")
          ?.join(" ")
          .replace(/\b\w/g, (c) => c.toUpperCase());
      } else if (selectedFilters?.line_items?.length === 1) {
        return selectedFilters?.line_items?.[0]
          ?.split("_")
          ?.join(" ")
          .replace(/\b\w/g, (c) => c.toUpperCase());
      }
      return "Financial Transactions";
    };

    return (
      <TableWrapper
        id="kt_content_container"
        className="container-fluid custom-ui mt-5"
      >
        <div className="card">
          <div
            className="card-header"
            style={{
              background: "white",
              zIndex: 9,
              top: "0px",
              position: "sticky",
            }}
          >
            <div className="card-title">
              <div className="me-3">
                <span
                  className="svg-icon svg-icon-muted svg-icon-2hx"
                  onClick={() => {
                    setSelectedRow("");

                    setSelectedFilters({});
                    setResetFlag(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.3"
                      x={2}
                      y={2}
                      width={20}
                      height={20}
                      rx={5}
                      fill="currentColor"
                    />
                    <path
                      d="M12.0657 12.5657L14.463 14.963C14.7733 15.2733 14.8151 15.7619 14.5621 16.1204C14.2384 16.5789 13.5789 16.6334 13.1844 16.2342L9.69464 12.7029C9.30968 12.3134 9.30968 11.6866 9.69464 11.2971L13.1844 7.76582C13.5789 7.3666 14.2384 7.42107 14.5621 7.87962C14.8151 8.23809 14.7733 8.72669 14.463 9.03696L12.0657 11.4343C11.7533 11.7467 11.7533 12.2533 12.0657 12.5657Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>

              {getTitles()}
              <Button
                type="link"
                onClick={() => {
                  setResetTable(false);
                  setResetFlag(true);
                }}
                className="ms-4"
                style={{
                  textDecoration: "underline",
                }}
              >
                Reset Filters
              </Button>
            </div>
            <div className="card-toolbar">
              <Tag color="blue-inverse">
                {lineItemTag === "custom" ? "Selected Period" : lineItemTag}
              </Tag>

              <DatePickerView
                presets={presetRanges}
                format={"YYYY-MMM-DD"}
                allowClear={false}
                size="middle"
                className="me-4"
                style={{
                  width: "254px",
                  border: "1px solid #D7D7D7",
                }}
                value={
                  filtersLine?.start_date && filtersLine?.end_date
                    ? [
                        dayjs(filtersLine?.start_date, "YYYY-MM-DD"),
                        dayjs(filtersLine?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                onChange={(e, _) => {
                  handleDateRangeChangeLineItems(e, _);
                  const obj = {
                    start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };
                  setLineItemLoading(true);

                  setLineItemList([]);
                  if (resetFlag) {
                    getAllLineItems({
                      ...obj,
                    });
                  } else {
                    getLineItems({
                      ...obj,
                    });
                  }
                  setResetTable(true);

                  setFiltersLine(obj);
                }}
                disabledDate={disabledDate}
              />

              <div
                style={{
                  background: "#00B660",
                  borderRadius: 6,
                  padding: "6px 15px",
                  cursor: "pointer",
                }}
                onClick={() => downloadExcel(selectedRow, getTitles())}
              >
                <div
                  style={{
                    color: "#FAFAFB",
                    fontSize: 13,

                    fontWeight: 500,
                    letterSpacing: 0.1,
                    wordWrap: "break-word",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    fill="none"
                    className="me-3"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
                      fill="white"
                    />
                  </svg>
                  Export
                </div>
              </div>
            </div>
          </div>
          <div className="card-body pt-0 pb-0">
            {!resetTable || lineItemLoading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <div className=" selected-row-financial">
                <Table
                  dataSource={resetFlag ? allLineItemsList : lineItemList}
                  loading={lineItemLoading}
                  // scroll={{ y: "calc(100vh - 360px)" }}
                  fixed={true}
                  sticky={{
                    offsetHeader: "68px",
                  }}
                  columns={columns}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                  pagination={false}
                />
              </div>
            )}
          </div>
          <div
            className="card-footer pt-0"
            style={{
              background: "#FFF",
              // position: "sticky",
              // bottom: 0,
              // zIndex: 9,
            }}
          >
            <div
              style={{
                marginTop: "20px",
                textAlign: "right",
                display:
                  calculateTotal(filteredData, "product_sales") == 0
                    ? "none"
                    : "block",
              }}
              className="d-flex align-items-center justify-content-end"
            >
              <Text strong>
                Total Amount:{" "}
                <span
                  style={{
                    color:
                      calculateTotal(filteredData, "product_sales") < 0
                        ? "red"
                        : "green",
                  }}
                >
                  $
                  {numberWithCommas(
                    calculateTotal(filteredData, "product_sales").toFixed(2)
                  )}
                </span>
              </Text>
            </div>
          </div>
        </div>
      </TableWrapper>
    );
  }

  return (
    <Wrapper id="kt_content_container" className="container-fluid mt-2">
      <div className="row">
        <div className="header-title-page">
          <div className="d-flex align-items-center title-text">
            <h2 className="mb-0">{pageTitle}</h2>{" "}
            <InfoVideo
              data={locationData}
              title={"page_heading"}
              className={"ms-3"}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          justifyContent: viewConfig ? "flex-start" : "space-between",
        }}
        className="mb-6 top-bar-page-header mt-4"
      >
        <div className="page-header-info d-flex align-items-center">
          <div className="discription-text">
            Financial Metrics and Trends for the week of{" "}
            {dayjs(getWeekDates(dateFilter)?.start, "YYYY-MM-DD").format(
              "MM/DD/YYYY"
            )}{" "}
            to{" "}
            {dayjs(getWeekDates(dateFilter)?.end, "YYYY-MM-DD").format(
              "MM/DD/YYYY"
            )}
          </div>
          &nbsp;
          <div className="d-flex align-items-center">
            Select Week&nbsp;
            <DatePicker
              value={dayjs(dateFilter, "YYYY-MM-DD")}
              picker="week"
              className="custom-date"
              style={{ width: "140px" }}
              format={"YYYY-MMM-DD"}
              disabledDate={(current) => {
                // Disable the current week and future weeks
                const startOfWeek = current?.startOf("week");
                const endOfWeek = current?.endOf("week");
                const today = dayjs();
                return (
                  current &&
                  (endOfWeek.isSameOrAfter(today, "day") ||
                    startOfWeek.isSame(today.startOf("week"), "week"))
                );
              }}
              allowClear={false}
              onChange={(e) => {
                getOverview(dayjs(e).format("YYYY-MM-DD"));
                setDateFilter(dayjs(e).format("YYYY-MM-DD"));
              }}
            />
          </div>
        </div>

        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 20,
            display: "flex",
          }}
        >
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#00B55D",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#00B55D",
                fontSize: 14,

                fontWeight: 500,

                wordWrap: "break-word",
              }}
            >
              Growth
            </div>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#FDC71E",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#FDC71E",
                fontSize: 14,

                fontWeight: 500,

                wordWrap: "break-word",
              }}
            >
              Stagnant
            </div>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#E31715",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#E31715",
                fontSize: 14,

                fontWeight: 500,

                wordWrap: "break-word",
              }}
            >
              Decline
            </div>
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-5 Financial-cus-row">
        <div
          style={{
            zoom: "90%",
          }}
          className=" col-xl-8 mh-450px overflow-auto"
        >
          {console.log(cardList, "cardList")}
          <CardsView
            cardList={cardList}
            type="W"
            setSelectedCard={setSelectedCard}
            backgroundColor={backgroundColor}
            getBorderColor={getBorderColor}
            overviewData={overviewData}
            borderColor={borderColor}
            signConvert={signConvert}
            getOp={getOp}
            getFooter={getFooter}
            getArrowColor={getArrowColor}
            selectedCard={selectedCard}
            overviewLoading={overviewLoading}
            setCardList={setCardList}
            setMetricGraphLoading={setMetricGraphLoading}
          />
        </div>
        {GraphMetric(
          selectedCard,
          borderColor,
          getBorderColor,
          overviewData,
          signFind,
          restart,
          revenu_status,
          getArrowColor,
          getFooter,
          showMetrix,
          overviewLoading,
          locationData,
          metricGraphLoading
        )}
      </div>

      {tabelLoading ? (
        <div
          style={{
            height: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        SummaryTable(
          presetRanges,
          filters,
          setTabelLoading,
          getList,
          setFilters,
          tabelLoading,
          isThis,
          convertedData,
          setSelectedRow,
          otherStatic,
          convertedData2,
          getLineItems,
          signConvert,
          handleDateRangeChange,
          selectedRange,
          disabledDate,
          setFiltersLine,
          apiCall,
          setLineItemList,
          selectedRow,
          setSelectedFilters,

          contextValue,
          locationData,
          FinancialSummary,
          setVisible,
          visible
        )
      )}

      {graphLoading ? (
        <div
          style={{
            height: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div
          className={`card mt-5 ${
            lastGraph?.series?.length === 0 ? "d-none" : ""
          }`}
        >
          <div className="card-header" style={{ minHeight: "50px" }}>
            <div className="card-title">Financial Performance</div>
          </div>
          <div
            className="card-body"
            style={{
              // filter: graphLoading ? "blur(5px)" : "blur(0)",
              zoom: "110%",
            }}
          >
            {/* lastGraph */}
            <div className="d-flex">
              <div
                className="w-150px"
                style={{ borderRight: "1px dashed #dbdfe9", zoom: "90%" }}
              >
                <div className="hide-show mt-5 d-grid">
                  {console.log(lastGraph?.series, "lastGraph?.series")}
                  <Checkbox.Group
                    onChange={(e) => {
                      setSelectedGraph(e);
                    }}
                    style={{ width: "180px" }}
                    value={selectedGraph}
                  >
                    {graphLoading
                      ? Array(8)
                          ?.fill(1)
                          ?.map((d, i) => (
                            <div className="d-flex mb-2" key={i}>
                              <Skeleton.Avatar size={"small"} active />
                              <Skeleton.Button
                                active
                                size="small"
                                className="ms-3"
                              />
                            </div>
                          ))
                      : lastGraph?.series?.map((d, i) => (
                          <Checkbox
                            className={
                              colorList?.find((f) => f?.key === d?.color)?.value
                            }
                            value={d?.name}
                          >
                            <div
                              className="apexcharts-tooltip-series-group apexcharts-active"
                              style={{ order: 1, display: "flex" }}
                              key={i}
                            >
                              <div
                                className="apexcharts-tooltip-text"
                                style={{
                                  fontFamily: "'Montserrat' sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                <div className="apexcharts-tooltip-y-group">
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                      // fontWeight: 500,
                                      color: "#656565",
                                    }}
                                    className="apexcharts-tooltip-text-y-label"
                                  >
                                    {d?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Checkbox>
                        ))}
                  </Checkbox.Group>
                </div>
              </div>

              {graphLoading ? (
                <div
                  style={{ width: "calc(100vw - 190px)", zoom: "90%" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Skeleton.Node active>
                    <DotChartOutlined
                      style={{
                        fontSize: 40,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : (
                <div style={{ width: "calc(100vw - 190px)", zoom: "90%" }}>
                  <ReactApexChart
                    options={lastGraph?.options}
                    series={lastGraph?.series?.filter((item) =>
                      selectedGraph.includes(item.name)
                    )}
                    ref={chartRef}
                    type="line"
                    height={350}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {viewConfig && (
        <Config
          metricSettingLoading={metricSettingLoading}
          metricSettingList={metricSettingList}
          onClose={() => setViewConfig(false)}
          onSave={saveMetricSetting}
          open={viewConfig}
          data={[]}
        />
      )}

      {ReportData(visible, setVisible, reportingData)}
    </Wrapper>
  );
};

export default Financial;

export function ReportData(visible, setVisible, reportingData) {
  return (
    <Modal
      title="Reporting Period For Your Account"
      footer={[]}
      width={730}
      open={visible}
      onCancel={() => setVisible(false)}
    >
      <Typography.Text style={{ fontSize: 12, color: "#333333" }}>
        SIP stores your data after retrieving it from your Amazon Seller
        account. This means you can access your complete historical data through
        SIP, even though Amazon Seller Central only shows data for a limited
        time period. The starting date of your historical data in SIP is
        determined by two factors: when you connect your Seller Central account
        to SIP, and the earliest date from which Amazon allows data retrieval at
        that time.
      </Typography.Text>
      <PopOverDropDown style={{ marginTop: "10px" }}>
        <table class="reporting-table">
          {[1, 2]?.map((d, i) => (
            <tr key={i}>
              <td>
                {reportingData?.connection?.slice(0, 2)?.[i]?.event_name || "-"}
              </td>
              <td>
                {reportingData?.connection?.slice(0, 2)?.[i]?.created_at
                  ? moment
                      .utc(
                        new Date(
                          parseInt(
                            reportingData?.connection?.slice(0, 2)?.[i]
                              ?.created_at
                          ) * 1000
                        )
                      )
                      .tz("America/New_York")
                      .format("YYYY-MM-DD")
                  : "-"}
              </td>

              <td>
                {reportingData?.connection?.slice(2)?.[i]?.event_name || "-"}
              </td>
              <td>
                {reportingData?.connection?.slice(2)?.[i]?.created_at
                  ? moment
                      .utc(
                        new Date(
                          parseInt(
                            reportingData?.connection?.slice(2)?.[i]?.created_at
                          ) * 1000
                        )
                      )
                      .tz("America/New_York")
                      .format("YYYY-MM-DD")
                  : "-"}
              </td>
            </tr>
          ))}

          <tr>
            <td colSpan={4} />
          </tr>
          <tr>
            <td colSpan={4} />
          </tr>
          <tr>
            <th>Data Source</th>
            <th>Name</th>
            <th>Status</th>
            <th>Available From</th>
          </tr>
          {reportingData?.data_source?.map((d, i) => (
            <tr key={i}>
              <td>{d?.data_source}</td>
              <td>{d?.event_name}</td>
              <td>{d?.status}</td>
              <td>{d?.report_date || "-"}</td>
            </tr>
          ))}
        </table>
      </PopOverDropDown>
    </Modal>
  );
}

function SummaryTable(
  presetRanges,
  filters,
  setTabelLoading,
  getList,
  setFilters,
  tabelLoading,
  isThis,
  convertedData,
  setSelectedRow,
  otherStatic,
  convertedData2,
  getLineItems,
  signConvert,
  handleDateRangeChange,
  selectedRange,
  disabledDate,
  setFiltersLine,
  apiCall,
  setLineItemList,
  selectedRow,
  setSelectedFilters,

  contextValue,
  locationData,
  FinancialSummary,
  setVisible,
  visible
) {
  const icons = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      style={{
        position: "relative",
        top: "-4px",
        left: "0.5px",
      }}
    >
      <path
        d="M3.75 11.125L6.875 8L3.75 4.875M8.125 11.125L11.25 8L8.125 4.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const iconsBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M3.75 11.125L6.875 8L3.75 4.875M8.125 11.125L11.25 8L8.125 4.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const findYear = dayjs(filters?.start_date, "YYYY-MM-DD").format("YYYY");

  const findMonth = dayjs(filters?.start_date, "YYYY-MM-DD").format("MMM YY");
  const previousYear = dayjs(filters?.start_date, "YYYY-MM-DD")
    .subtract(1, "year")
    .format("MMM YY");
  const currentDate = dayjs(filters?.start_date, "YYYY-MM-DD");
  const lastMonth = currentDate.subtract(1, "month").format("MMM YY");
  const oneYearAgo = currentDate.subtract(1, "year").format("MMM YY");

  const findLastMonth = dayjs(filters?.start_date, "YYYY-MM-DD").format(
    "MMM YY"
  );
  const currentDate_ = dayjs(filters?.start_date, "YYYY-MM-DD");
  const findPrevMonth = currentDate_.subtract(1, "month").format("MMM YY");
  const oneYearAgo_ = currentDate_.subtract(1, "year").format("MMM YY");

  const selectedPeriod = `${dayjs(filters?.start_date, "YYYY-MM-DD").format(
    "MMM DD 'YY"
  )} - ${dayjs(filters?.end_date, "YYYY-MM-DD").format("MMM DD 'YY")}`;

  const columnsFilter = {
    This_Month: {
      [`${findMonth} Actual`]: "this_month",
      [`_`]: "this_month_ratio",
      [`${findMonth} Proj.`]: "projected_month",
      [`__`]: "projected_month_ratio",
      [lastMonth]: "last_month",
      [`___`]: "last_month_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      [oneYearAgo]: "this_month_last_year",
      "YoY Variance": "tmly_variance",
      " Variance Pect": "tmly_variance_pect",
    },
    custom: {
      [`${selectedPeriod}`]: "selected_range",
      [`_`]: "selected_range_ratio",
      [`${moment(
        updatePrevDates({
          endDate: filters?.end_date,
          startDate: filters?.start_date,
        })
      ).format("MMM DD 'YY")} - ${dayjs(filters?.start_date, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("MMM DD 'YY")}`]: "previous_period",
      [`__`]: "previous_period_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
    },

    Last_Month: {
      [findLastMonth]: "last_month",
      [`_`]: "last_month_ratio",
      [findPrevMonth]: "previous_month",
      [`__`]: "previous_month_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      [oneYearAgo_]: "last_month_last_year",
      "YoY Variance": "lmly_variance",
      " Variance Pect": "lmly_variance_pect",
    },
    Yesterday: {
      Yesterday: "yesterday",
      [`_`]: "yesterday_ratio",
      "Day Before Yesterday ": "day_before_yesterday",
      [`__`]: "day_before_yesterday_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Yesterday Last Year": "yesterday_last_year",
      "YoY Variance": "yly_variance",
      " Variance Pect": "yly_variance_pect",
    },
    This_Week: {
      "This Week Actual": "this_week",
      [`_`]: "this_week_ratio",
      "This Week Proj.": "projected_week",
      [`__`]: "projected_week_ratio",
      "Last Week": "last_week",
      [`___`]: "last_week_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Last Year This Week": "last_year_this_week",
      "YoY Variance": "lytw_variance",
      " Variance Pect": "lytw_variance_pect",
    },
    This_Quarter: {
      "This Quarter Actual": "this_quarter",
      [`_`]: "this_quarter_ratio",
      "This Quarter Proj.": "projected_quarter",
      [`__`]: "projected_quarter_ratio",
      "Last Quarter": "last_quarter",
      [`___`]: "last_quarter_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "This Quarter Last Year": "this_quarter_last_year",
      "YoY Variance": "tqly_variance",
      " Variance Pect": "tqly_variance_pect",
    },
    Last_Year: {
      [`${findYear} Q1`]: "last_year_q1",
      [`_`]: "last_year_q1_ratio",
      [`${findYear} Q2`]: "last_year_q2",
      [`__`]: "last_year_q2_ratio",
      [`${findYear} Q3`]: "last_year_q3",
      [`___`]: "last_year_q3_ratio",
      [`${findYear} Q4`]: "last_year_q4",
      [`____`]: "last_year_q4_ratio",
      Total: "last_year_total",
    },
    This_Year: {
      [`${findYear} Q1`]: "this_year_q1",
      [`_`]: "this_year_q1_ratio",
      [`${findYear} Q2`]: "this_year_q2",
      [`__`]: "this_year_q2_ratio",
      [`${findYear} Q3`]: "this_year_q3",
      [`___`]: "this_year_q3_ratio",
      [`${findYear} Q4`]: "this_year_q4",
      [`____`]: "this_year_q4_ratio",
      [`${findYear} Total `]: "this_year_total",
      [`${previousYear} Total`]: "last_year_total",
      [`This Year Proj.`]: "projected_year",
      [`Variance`]: "variance",
      [`Variance Pect`]: "variance_pect",
    },
  };

  const errorIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2927 5.49996C12.7119 5.49996 12.945 5.98493 12.6831 6.31231L8.39011 11.6786C8.18995 11.9288 7.8094 11.9288 7.60924 11.6786L3.31622 6.31231C3.05431 5.98493 3.2874 5.49996 3.70665 5.49996L12.2927 5.49996Z"
        fill="#E31715"
      />
    </svg>
  );

  const successIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70633 11.5C3.28708 11.5 3.05399 11.015 3.31589 10.6877L7.60891 5.32138C7.80908 5.07117 8.18962 5.07117 8.38978 5.32138L12.6828 10.6877C12.9447 11.015 12.7116 11.5 12.2924 11.5L3.70633 11.5Z"
        fill="#00B55D"
      />
    </svg>
  );

  const getValueShow = (value) => {
    try {
      if (value == 0) {
        return <span style={{ color: "#000" }}>{value}%</span>;
      } else if (parseFloat(value?.split(",")?.join("")) < 0) {
        return (
          <span style={{ color: "red" }}>
            {errorIcon}
            {value}%
          </span>
        );
      }
      return (
        <span style={{ color: "green" }}>
          {successIcon}
          {value}%
        </span>
      );
    } catch (error) {}
  };
  function hasHorizontalScrollbar(elementId) {
    const element = document.getElementById(elementId);
    if (!element) return false;

    return element.scrollWidth > element.clientWidth;
  }
  let hasScrollbar = hasHorizontalScrollbar("table-responsive");
  const FinSummarySales = locationData?.contentLocation?.filter(
    (d) => d?.location === "Sales" && d?.title === "Fin Summary Sales"
  );
  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">
          <h2 className="mb-0 me-2">Financial Summary</h2>
          <InfoVideo
            data={locationData}
            title="financial_summary"
            infoShow={false}
            className={"ms-3"}
          />
        </div>
        <div className="card-toolbar">
          <Tag color="blue">
            <svg
              width={16}
              height={16}
              fill="#065ad8"
              className="me-2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM11 6h2.4v2.4H11V6Zm0 4.8h2.4V18H11v-7.2Z"
                clipRule="evenodd"
              />
            </svg>
            Reported data determined by SIP Registration and Amazon constraints.{" "}
            <a
              style={{ textDecoration: "underline", color: "#065ad8" }}
              onClick={(e) => setVisible(true)}
            >
              See your reporting period
            </a>
          </Tag>
          <Tag color="blue-inverse" className="me-2">
            {selectedRange === "custom" ? "Selected period" : selectedRange}
          </Tag>

          <RangePicker
            presets={presetRanges}
            // needConfirm
            format={"YYYY-MMM-DD"}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e, _) => {
              const obj = {
                start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              };
              setTabelLoading(true);
              getList({
                ...obj,
                selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
              });
              setFilters(obj);
              setFiltersLine(obj);
            }}
            size="middle"
            allowClear={false}
            style={{
              width: "254px",
              border: "1px solid #D7D7D7",
            }}
            disabledDate={(current) => {
              return current && current > dayjs().endOf("day");
            }}
          />
        </div>
      </div>

      <div className="card-body pt-0 pb-4">
        {hasScrollbar && (
          <div id="leftArrow" className="left-arrow">
            <span className="svg-icon svg-icon-muted svg-icon-2hx">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                  fill="currentColor"
                />
                <path
                  d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
        )}

        <div className="table-container">
          {tabelLoading ? (
            <div
              style={{
                height: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin />
            </div>
          ) : !tabelLoading && convertedData?.length === 0 ? (
            <div
              style={{
                height: "400px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className=""
            >
              <Empty />
            </div>
          ) : (
            <div className="table-responsive " id="table-responsive">
              <table className=" align-middle overflow-scroll  table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
                <thead>
                  <tr>
                    <th className="pe-3 py-5 fixed-column">
                      <span className="d-none">_</span>
                    </th>

                    {Object?.entries(
                      columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                    )?.map(([key, value], i) => (
                      <th
                        key={i}
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        className={` text-end  pe-3 py-3 ${key}`}
                      >
                        {key?.includes("Pect") ? " " : key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      style={{ background: "transparent", zIndex: 99 }}
                      className="d-flex align-items-center justify-content-start fixed-column"
                    >
                      <h3 style={{ marginBottom: "0px" }}>Sales</h3>
                      <InfoVideo
                        data={locationData}
                        title="Sales_amount"
                        // PageHeading={FinSummarySales}
                        videoShow={false}
                        className={"ms-3"}
                      />
                    </td>
                    <td colSpan={9}></td>
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>

                  {convertedData?.map((d, i) => (
                    <tr key={i} className="">
                      <td
                        onClick={() => {
                          setSelectedRow(d?.type);

                          setLineItemList([]);
                          getLineItems({ type: d?.type });
                        }}
                        className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                        style={{
                          borderBottom: "1px solid #E5E5E5",
                          padding: "5px 10px 5px 10px",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span>
                            {d?.type
                              ?.split("_")
                              ?.join(" ")
                              .replace(/\b\w/g, (c) => c.toUpperCase())}
                          </span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (
                          (d?.type === "refunds" || d?.type === "promotions") &&
                          (key === "_" ||
                            key === "__" ||
                            key === "___" ||
                            key === "____")
                        ) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                borderBottom: "none",
                                padding: "5px 10px 5px 10px",
                              }}
                            >
                              {d?.[value] == 0 ? "-" : `${d?.[value]}%`}
                            </td>
                          );
                        }

                        if (
                          key === "_" ||
                          key === "__" ||
                          key === "___" ||
                          key === "____"
                        ) {
                          return <td key={i}></td>;
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              borderBottom: "1px solid #E5E5E5",
                              padding: "5px 10px 5px 10px",
                            }}
                          >
                            {d?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(d?.[value] || 0 || "-")
                              : signConvert(`$${d?.[value] || 0}`)}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      onClick={() => {
                        setSelectedRow("Total Net Sales");
                        setLineItemList([]);
                        getLineItems({ type: "total_net_sales" });
                      }}
                      className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                      style={{
                        background: "#E4F3FF",
                        padding: "5px 10px 5px 10px",
                        color: "#015986",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span>Total Net Sales</span>
                        <div
                          className="bg-primary ms-2 d-flex"
                          style={{
                            borderRadius: "100%",
                            postion: "relative",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          {iconsBtn}
                        </div>
                      </div>
                    </td>
                    {Object?.entries(
                      columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                    )?.map(([key, value], i) => {
                      if (
                        key === "_" ||
                        key === "__" ||
                        key === "___" ||
                        key === "____"
                      ) {
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          ></td>
                        );
                      }
                      return (
                        <td
                          key={i}
                          className="text-end   pe-3"
                          style={{
                            background: "#E4F3FF",
                            padding: "5px 10px 5px 10px",
                            color: "#015986",
                            fontWeight: 500,
                          }}
                        >
                          {otherStatic?.total_net_sales?.[value] == 0
                            ? "-"
                            : key?.includes("Pect")
                            ? getValueShow(
                                otherStatic?.total_net_sales?.[value] || 0
                              )
                            : signConvert(
                                `$${otherStatic?.total_net_sales?.[value] || 0}`
                              )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      onClick={() => {
                        setSelectedRow("Cost of Goods Sold (COGS)");
                        setLineItemList([]);
                        getLineItems({ type: "cogs" });
                      }}
                      className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                      style={{
                        background: "#FAFAFB",
                        padding: "5px 10px 5px 10px",
                        color: "#767676",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span>Cost of Goods Sold (COGS)</span>
                        <div
                          className="bg-primary ms-2 d-flex"
                          style={{
                            borderRadius: "100%",
                            postion: "relative",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          {iconsBtn}
                        </div>
                      </div>
                    </td>
                    {Object?.entries(
                      columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                    )?.map(([key, value], i) => {
                      if (
                        key === "_" ||
                        key === "__" ||
                        key === "___" ||
                        key === "____"
                      ) {
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#FAFAFB",
                              padding: "5px 10px 5px 10px",
                              color: "#767676",
                              fontWeight: 500,
                              border: "none",
                            }}
                          >
                            {otherStatic?.cogs?.[value] == 0
                              ? "-"
                              : `${otherStatic?.cogs?.[value] || 0}%`}
                          </td>
                        );
                      }

                      return (
                        <td
                          key={i}
                          className="text-end   pe-3"
                          style={{
                            background: "#FAFAFB",
                            padding: "5px 10px 5px 10px",
                            color: "#767676",
                            fontWeight: 500,
                          }}
                        >
                          {otherStatic?.cogs?.[value] == 0
                            ? "-"
                            : key?.includes("Pect")
                            ? getValueShow(otherStatic?.cogs?.[value] || 0)
                            : signConvert(
                                `$${otherStatic?.cogs?.[value] || 0}`
                              )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                      onClick={() => {
                        setSelectedRow("Gross Profit");
                        setLineItemList([]);
                        getLineItems({ type: "gross_profit" });
                      }}
                      style={{
                        background: "#E4F3FF",
                        padding: "5px 10px 5px 10px",
                        paddingRight: 0,
                        color: "#015986",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span>Gross Profit</span>
                        <div
                          className="bg-primary ms-2 d-flex"
                          style={{
                            borderRadius: "100%",
                            postion: "relative",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          {iconsBtn}
                        </div>
                      </div>
                    </td>
                    {Object?.entries(
                      columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                    )?.map(([key, value], i) => {
                      if (
                        key === "_" ||
                        key === "__" ||
                        key === "___" ||
                        key === "____"
                      ) {
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {otherStatic?.gross_profit?.[value] == 0
                              ? "-"
                              : `${otherStatic?.gross_profit?.[value] || 0}%`}
                          </td>
                        );
                      }

                      return (
                        <td
                          key={i}
                          className="text-end    pe-3"
                          style={{
                            background: "#E4F3FF",
                            padding: "5px 10px 5px 10px",
                            color: "#015986",
                            fontWeight: 500,
                          }}
                        >
                          {otherStatic?.gross_profit?.[value] == 0
                            ? "-"
                            : key?.includes("Pect")
                            ? getValueShow(
                                otherStatic?.gross_profit?.[value] || 0
                              )
                            : signConvert(
                                `$${otherStatic?.gross_profit?.[value] || 0}`
                              )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      style={{ background: "transparent" }}
                      className="d-flex align-items-center justify-content-start fixed-column"
                    >
                      <h3 style={{ marginBottom: "0px" }}>Selling Expenses</h3>
                      <InfoVideo
                        data={locationData}
                        title="selling_expenses"
                        videoShow={false}
                        className={"ms-3"}
                      />
                    </td>
                    <td colSpan={9}></td>
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  {convertedData2?.map((d, i) => (
                    <tr key={i}>
                      <td
                        onClick={() => {
                          setSelectedRow(d?.type);

                          setLineItemList([]);
                          getLineItems({ type: d?.type });
                        }}
                        className="text-end fixed-column text-hover-primary cursor-pointer  d-flex justify-content-end"
                        style={{
                          borderBottom: "1px solid #E5E5E5",
                          padding: "5px 10px 5px 10px",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span>
                            {d?.type === "fba_fees"
                              ? "FBA Fees"
                              : d?.type
                                  ?.split("_")
                                  ?.join(" ")
                                  .replace(/\b\w/g, (c) => c.toUpperCase())}
                          </span>
                          <div
                            className="bg-primary ms-2 d-flex"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {iconsBtn}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (
                          key === "_" ||
                          key === "___" ||
                          key === "__" ||
                          key === "____"
                        ) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                borderBottom: "none",
                                padding: "5px 10px 5px 10px",
                              }}
                            >
                              {d?.[value] == 0 ? "-" : `${d?.[value]}%`}
                            </td>
                          );
                        }

                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              borderBottom: "1px solid #E5E5E5",
                              padding: "5px 10px 5px 10px",
                            }}
                          >
                            {d?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(d?.[value])
                              : signConvert(`$${d?.[value]}`)}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      onClick={() => {
                        setSelectedRow("Total Selling Expenses");
                        setLineItemList([]);
                        getLineItems({ type: "total_selling_expenses" });
                      }}
                      className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                      style={{
                        background: "#E4F3FF",
                        padding: "5px 10px 5px 10px",
                        color: "#015986",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span>Total Selling Expenses</span>
                        <div
                          className="bg-primary ms-2 d-flex"
                          style={{
                            borderRadius: "100%",
                            postion: "relative",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          {iconsBtn}
                        </div>
                      </div>
                    </td>
                    {Object?.entries(
                      columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                    )?.map(([key, value], i) => {
                      if (
                        key === "_" ||
                        key === "__" ||
                        key === "___" ||
                        key === "____"
                      ) {
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          ></td>
                        );
                      }
                      return (
                        <td
                          key={i}
                          className="text-end   pe-3"
                          style={{
                            background: "#E4F3FF",
                            padding: "5px 10px 5px 10px",
                            paddingRight: 0,
                            color: "#015986",
                            fontWeight: 500,
                          }}
                        >
                          {otherStatic?.total_selling_expenses?.[value] == 0
                            ? "-"
                            : key?.includes("Pect")
                            ? getValueShow(
                                otherStatic?.total_selling_expenses?.[value] ||
                                  0
                              )
                            : signConvert(
                                `$${
                                  otherStatic?.total_selling_expenses?.[
                                    value
                                  ] || 0
                                }`
                              )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td className="p-1" colSpan={9} />
                  </tr>
                  <tr>
                    <td
                      onClick={() => {
                        setSelectedRow("Channel Profit");
                        setLineItemList([]);
                        getLineItems({ type: "channel_profit" });
                      }}
                      className="text-start text-hover-primary cursor-pointer fixed-column  d-flex align-items-center justify-content-start"
                      style={{
                        background: "#E4F3FF",
                        padding: "5px 10px 5px 10px",
                        color: "#015986",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      <div className="d-flex align-items-center ">
                        <span>Channel Profit</span>
                        <div
                          className="bg-primary ms-2 d-flex"
                          style={{
                            borderRadius: "100%",
                            postion: "relative",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          {iconsBtn}
                        </div>
                      </div>
                      <InfoVideo
                        data={locationData}
                        title="channel_profit"
                        videoShow={false}
                        className={"ms-3"}
                      />
                    </td>
                    {Object?.entries(
                      columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                    )?.map(([key, value], i) => {
                      if (
                        key === "_" ||
                        key === "__" ||
                        key === "___" ||
                        key === "____"
                      ) {
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",

                              fontWeight: 500,
                              color: "#015986",
                            }}
                          >
                            {otherStatic?.channel_profit?.[value] == 0
                              ? "-"
                              : `${otherStatic?.channel_profit?.[value] || 0}%`}
                          </td>
                        );
                      }
                      return (
                        <td
                          key={i}
                          className="text-end  pe-3"
                          style={{
                            background: "#E4F3FF",

                            fontWeight: 500,
                            color: "#015986",
                          }}
                        >
                          {otherStatic?.channel_profit?.[value] == 0
                            ? "-"
                            : key?.includes("Pect")
                            ? getValueShow(
                                otherStatic?.channel_profit?.[value] || 0
                              )
                            : signConvert(
                                `$${otherStatic?.channel_profit?.[value] || 0}`
                              )}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        {hasScrollbar && (
          <div id="rightArrow" className="right-arrow">
            <span className="svg-icon svg-icon-muted svg-icon-2hx">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                  fill="currentColor"
                />
                <path
                  d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
function GraphMetric(
  selectedCard,
  borderColor,
  getBorderColor,
  overviewData,
  signFind,
  restart,
  revenu_status,
  getArrowColor,
  getFooter,
  showMetrix,
  overviewLoading,
  locationData = {},
  metricGraphLoading
) {
  const cap = (value) => {
    return value?.charAt(0)?.toUpperCase() + value?.slice(1);
  };

  if (metricGraphLoading) {
    return (
      <div
        className=" col-xl-4 mb-md-5 mb-xl-11"
        style={{ maxHeight: "432px", height: "432px" }}
      >
        <div className="card d-flex align-items-center justify-content-center card-flush h-md-100">
          <Spin active />{" "}
        </div>{" "}
      </div>
    );
  }
  return (
    <div className=" col-xl-4 mb-md-5 mb-xl-11" style={{ maxHeight: "432px" }}>
      {/*begin::Maps widget 1*/}
      <div className="card card-flush h-md-100">
        {/*begin::Header*/}
        <div className="card-header py-0 pt-0 min-h-50px  align-items-center">
          {/*begin::Title*/}
          <h3 className="card-title fw-bolder">
            Metric:&nbsp;
            <span style={{ textTransform: "capitalize" }}>
              {" "}
              {overviewLoading ? (
                <Skeleton.Button active size="small" />
              ) : (
                <>{overviewData?.[selectedCard?.root]?.metric_name || ""}</>
              )}
            </span>
            <span style={{ position: "relative", top: "-1px" }}>
              <InfoVideoMetric
                data={locationData}
                slug={selectedCard?.metric_slug}
                title={selectedCard?.label + " " + "Metric"}
                className={"ms-3"}
              />
            </span>
          </h3>

          {overviewLoading ? (
            <Skeleton.Input active size="small" />
          ) : (
            <>
              <div
                className="card-toolbar text-primary text-hover-info cursor-pointer  fs-5"
                onClick={() => showMetrix()}
                style={{
                  fontWeight: 500,
                }}
              >
                Show Metrics{" "}
                <i
                  className="ki-duotone ki-to-left ms-1 fs-3"
                  style={{
                    transform: "rotate(26deg)",
                    position: "relative",
                    top: "-1px",
                  }}
                ></i>
              </div>
            </>
          )}
        </div>

        <div className="card-body px-0 ps-4  pb-4 pt-0">
          {Object.keys(selectedCard || {})?.length === 0 ? (
            <Empty />
          ) : (
            <>
              <div
                style={{ borderTop: "1px #F1F1F5 solid" }}
                className="d-none align-items-start justify-content-between pt-4"
              >
                <div
                  style={{
                    color:
                      overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
                      borderColor?.[
                        getBorderColor(
                          overviewData?.[selectedCard?.root]?.ema_trend
                        )
                      ] === "#E31715"
                        ? "#00B55D"
                        : overviewData?.[selectedCard?.root]?.is_inverse ===
                            1 &&
                          borderColor?.[
                            getBorderColor(
                              overviewData?.[selectedCard?.root]?.ema_trend
                            )
                          ] === "#00B55D"
                        ? "#E31715"
                        : borderColor?.[
                            getBorderColor(
                              overviewData?.[selectedCard?.root]?.ema_trend
                            )
                          ],
                    fontSize: 14,

                    fontWeight: 500,
                    wordWrap: "break-word",
                  }}
                  className="ps-8"
                >
                  {selectedCard?.prefix || ""}
                  {numberformat(
                    overviewData?.[selectedCard?.root]?.metric_average
                  )}
                  {selectedCard?.suffix || ""} (7W-Avg)
                </div>
                <div className=" pe-8 text-end">
                  <div>
                    <div
                      style={{
                        color:
                          overviewData?.[selectedCard?.root]?.is_inverse ===
                            1 &&
                          borderColor?.[
                            getBorderColor(
                              overviewData?.[selectedCard?.root]?.ema_trend
                            )
                          ] === "#E31715"
                            ? "#00B55D"
                            : overviewData?.[selectedCard?.root]?.is_inverse ===
                                1 &&
                              borderColor?.[
                                getBorderColor(
                                  overviewData?.[selectedCard?.root]?.ema_trend
                                )
                              ] === "#00B55D"
                            ? "#E31715"
                            : borderColor?.[
                                getBorderColor(
                                  overviewData?.[selectedCard?.root]?.ema_trend
                                )
                              ],
                        fontSize: 14,

                        fontWeight: 400,
                        wordWrap: "break-word",
                      }}
                    >
                      52-Week Range (3)
                    </div>
                  </div>
                  <div className="ps-4 fs-8 fw-bolder mt-2">
                    <div
                      style={{
                        color:
                          overviewData?.[selectedCard?.root]?.is_inverse ===
                            1 &&
                          borderColor?.[
                            getBorderColor(
                              overviewData?.[selectedCard?.root]?.ema_trend
                            )
                          ] === "#E31715"
                            ? "#00B55D"
                            : overviewData?.[selectedCard?.root]?.is_inverse ===
                                1 &&
                              borderColor?.[
                                getBorderColor(
                                  overviewData?.[selectedCard?.root]?.ema_trend
                                )
                              ] === "#00B55D"
                            ? "#E31715"
                            : borderColor?.[
                                getBorderColor(
                                  overviewData?.[selectedCard?.root]?.ema_trend
                                )
                              ],
                        fontSize: 14,

                        fontWeight: 400,
                        wordWrap: "break-word",
                      }}
                    >
                      {selectedCard?.prefix || ""}
                      {numberformat(
                        overviewData?.[selectedCard?.root]?.min_value
                      )}{" "}
                      - {selectedCard?.suffix || ""}
                      {numberformat(
                        overviewData?.[selectedCard?.root]?.max_value
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {overviewLoading ? (
                <div
                  id="Revenu_Status"
                  className="w-100 d-flex align-items-center justify-content-center"
                  style={{ minHeight: 245, height: 295, zoom: "110%" }}
                >
                  <Skeleton.Node active>
                    <DotChartOutlined
                      style={{
                        fontSize: 40,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : (
                <>
                  <div
                    id="Revenu_Status"
                    className="w-100"
                    style={{ minHeight: 245, height: 284, zoom: "110%" }}
                  >
                    {restart && (
                      <ReactApexChart
                        options={revenu_status}
                        type="area"
                        height={270}
                        series={[
                          {
                            name: cap(selectedCard?.label),

                            data: overviewData?.[
                              selectedCard?.root
                            ]?.metric_chart_data?.map((d) => {
                              try {
                                return parseFloat(d?.metric_value || 0);
                              } catch (error) {
                                return 0;
                              }
                            }),
                          },
                          {
                            name: "Trend",
                            data: overviewData?.[
                              selectedCard?.root
                            ]?.metric_chart_data?.map((d) => {
                              try {
                                return parseFloat(d?.ema_value || 0);
                              } catch (error) {
                                return 0;
                              }
                            }),
                          },
                        ]}
                      />
                    )}
                  </div>
                </>
              )}
              <div className="d-flex justify-content-between flex-wrap">
                <div className="fw-bolder fs-5 text-danger ps-4 d-flex align-items-center">
                  <span className="text-dark">Path:</span>
                  {overviewLoading ? (
                    <Skeleton.Button className="ms-2" active size="small" />
                  ) : (
                    <>
                      <div
                        style={{
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 4,
                          paddingBottom: 4,
                          background:
                            overviewData?.[selectedCard?.root]?.is_inverse ===
                              1 &&
                            borderColor?.[
                              getBorderColor(
                                overviewData?.[selectedCard?.root]?.ema_trend
                              )
                            ] === "#E31715"
                              ? "#00B55D"
                              : overviewData?.[selectedCard?.root]
                                  ?.is_inverse === 1 &&
                                borderColor?.[
                                  getBorderColor(
                                    overviewData?.[selectedCard?.root]
                                      ?.ema_trend
                                  )
                                ] === "#00B55D"
                              ? "#E31715"
                              : borderColor?.[
                                  getBorderColor(
                                    overviewData?.[selectedCard?.root]
                                      ?.ema_trend
                                  )
                                ],

                          borderRadius: 24,
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                          display: "flex",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            color: "#FCFCFD",
                            fontSize: 14,
                            fontWeight: 500,
                            wordWrap: "break-word",
                            textTransform: "capitalize",
                          }}
                        >
                          {(
                            overviewData?.[selectedCard?.root]?.ema_trend || "-"
                          )?.toLowerCase()}
                        </div>

                        <i
                          className="ki-outline ki-arrow-down fs-2"
                          style={{
                            color: "#FFF",
                            display:
                              getBorderColor(
                                overviewData?.[selectedCard?.root]?.ema_trend
                              ) === 2
                                ? "none"
                                : "block",
                            transform: `rotate(${
                              getBorderColor(
                                overviewData?.[selectedCard?.root]?.ema_trend
                              ) === 3
                                ? "180deg"
                                : "360deg"
                            })`,
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="fw-bolder fs-5 text-danger ms-10 pe-5  d-flex align-items-center">
                  <span className="text-dark">Movement:</span>
                  {overviewLoading ? (
                    <Skeleton.Button className="ms-2" active size="small" />
                  ) : (
                    <>
                      <div
                        style={{
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 4,
                          paddingBottom: 4,
                          background:
                            overviewData?.[selectedCard?.root]?.is_inverse ===
                              1 &&
                            borderColor?.[
                              getFooter(
                                overviewData?.[selectedCard?.root]?.movement
                              )
                            ] === "#E31715"
                              ? "#00B55D"
                              : overviewData?.[selectedCard?.root]
                                  ?.is_inverse === 1 &&
                                borderColor?.[
                                  getFooter(
                                    overviewData?.[selectedCard?.root]?.movement
                                  )
                                ] === "#00B55D"
                              ? "#E31715"
                              : borderColor?.[
                                  getFooter(
                                    overviewData?.[selectedCard?.root]?.movement
                                  )
                                ],

                          borderRadius: 24,
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                          display: "flex",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            position: "relative",
                            transform: getArrowColor(
                              overviewData?.[selectedCard?.root]?.movement
                            ),
                            display:
                              getArrowColor(
                                overviewData?.[selectedCard?.root]?.movement
                              ) === "rotateX(92deg)"
                                ? "none"
                                : "block",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M18.3337 5.83334L11.7765 12.3905C11.4465 12.7205 11.2814 12.8856 11.0912 12.9474C10.9238 13.0018 10.7435 13.0018 10.5761 12.9474C10.3859 12.8856 10.2209 12.7205 9.89085 12.3905L7.6098 10.1095C7.27979 9.77947 7.11478 9.61447 6.92451 9.55264C6.75714 9.49826 6.57685 9.49826 6.40948 9.55264C6.2192 9.61447 6.0542 9.77947 5.72418 10.1095L1.66699 14.1667M18.3337 5.83334H12.5003M18.3337 5.83334V11.6667"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          style={{
                            color: "#FFF",
                            fontSize: 14,
                            textTransform: "capitalize",
                            fontWeight: 500,
                            wordWrap: "break-word",
                          }}
                        >
                          {(
                            overviewData?.[selectedCard?.root]?.movement || "-"
                          )?.toLowerCase()}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export { GraphMetric };
