import React, { useEffect } from "react";
import { Wrapper } from "./style";
import { useState } from "react";
import Edit from "./lib/edit";
import { Input, Table, Tooltip, message } from "antd";
import { EditIcon, PropsFilter, RenderTable } from "../../../config";
import { MakeApiCall } from "../../../apis";
import Pagination from "../../../components/pagination";
import { useHistory, useLocation } from "react-router-dom";

const PartnerResources = () => {
  const history = useHistory();
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [searchKey, setSearchKey] = useState("");

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState({});

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const getList = async (data) => {
    // `seller-resource?page=${data?.page || page || ""}&per-page=${
    //     data?.pageSize || pageSize || ""
    //   }&field_name=${data?.field_name || ""}&sort_by=${
    //     data?.sort_by || ""
    //   }&search_key=${data?.searchKey || ""}`,
    const response = await MakeApiCall(
      `seller-resource?isAll=1`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setList(response?.data?.records || []);
      // setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  // const sortType = {
  //   descend: "desc",
  //   ascend: "asc",
  // };
  // const DefaultSortType = {
  //   desc: "descend",
  //   asc: "ascend",
  // };
  // const PropsFilter = (type) => ({
  //   defaultSortOrder:
  //     type === sortFilters?.field_name
  //       ? DefaultSortType?.[sortFilters?.sort_by]
  //       : [],
  //   sorter: () => {},
  // });
  // const handleChange = (_, __, sorter) => {
  //   const obj = {
  //     field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
  //     sort_by: sortType?.[sorter?.order] || "",
  //   };
  //   setSortFilters(obj);
  //   getList({ ...obj, searchKey: searchKey });
  // };
  const pageType = new URLSearchParams(location.search)?.get("viewType");
  const selectedID = new URLSearchParams(location.search)?.get("id");

  const updateUrl = (url) => {
    const urlObj = new URL("http://example.com" + url);
    urlObj.searchParams.delete("viewType");
    urlObj.searchParams.delete("id");
    return urlObj.pathname + urlObj.search;
  };

  const updatedUrl = updateUrl(`${location?.pathname}${location?.search}`);

  const updateRow = async (data) => {
    const response = await MakeApiCall(
      `seller-resource/${selectedID}`,
      "put",
      data,
      true
    );
    if (response?.status) {
      message.destroy();
      setEditMode(false);
      setSelectedRow({});
      message.success(response?.message);
      getList({
        page: page,
        pageSize: pageSize,
      });
      setLoading(true);
      history.push(updatedUrl);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getList({
      page: page,
      pageSize: pageSize,
    });
    setLoading(true);
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      align: "center",
      align: "center",

      render: (_, __, i) => {
        return (
          <div
            className="d-flex justify-content-center w-100"
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {(page - 1) * pageSize + 1 + i}
          </div>
        );
      },
    },
    {
      title: "Pages",
      dataIndex: "title",
      key: "title",
      filterIndex: "title",
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <div className="d-flex justify-content-center">
          <span
            onClick={() => {
              history.push(
                `${location?.pathname}${location?.search}${
                  location?.search ? "&" : "?"
                }viewType=edit&id=${text?.id}`
              );
              // setSelectedRow(text);
              // setEditMode(true);
            }}
            className="me-2 cursor-pointer"
          >
            {EditIcon}
          </span>
        </div>
      ),
    },
  ];

  const ClearSearch = async () => {
    setLoading(true);
    setSearchKey("");
    getList({ ...sortFilters, searchKey: "" });
  };

  if (pageType) {
    return (
      <Edit
        id={selectedID}
        pageType={pageType}
        // selectedRow={selectedRow}
        // setSelectedRow={setSelectedRow}
        updateRow={updateRow}
        close={() => {
          history.push(updatedUrl);
          // setEditMode(false);
          // setSelectedRow({});
        }}
      />
    );
  }

  return (
    <Wrapper id="kt_content_container" className="custom-ui ">
      <>
        <div className="row g-2">
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <div className="position-relative mr-4">
                    <Input
                      type="text"
                      className="w-250px"
                      allowClear
                      name="all_search"
                      onChange={(e) => {
                        setSearchKey((prev) => {
                          if (!e.target.value && prev) {
                            ClearSearch();
                          }
                          return e.target.value;
                        });
                      }}
                      value={searchKey}
                      placeholder="Search here..."
                    />
                  </div>
                  <button
                    onClick={() => {
                      setLoading(true);
                      getList({ ...sortFilters, searchKey: searchKey });
                    }}
                    className="btn btn-sm ms-3 btn-search"
                  >
                    Search
                  </button>
                </div>
                <div className="card-toolbar"></div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-0 pb-0">
                {/*begin::Table container*/}
                <div className=" ">
                  {/*begin::Table*/}

                  <Table
                    // onChange={handleChange}
                    fixed={true}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    columns={columns?.map((d) => ({
                      ...d,
                      className:
                        d?.title === "#" ? "maxWidth-table" : "minWidth-table",
                      ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                      render: (props, row, index) =>
                        RenderTable(props, row, index, d),
                    }))}
                    dataSource={list}
                    loading={loading}
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "even-row" : "odd-row";
                    }}
                    bordered
                    pagination={{
                      size: "default",
                      showSizeChanger: true,
                    }}
                    scroll={{ x: "max-content" }}
                    size="small"
                  />
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
              <div className="card-footer d-none pt-0 pb-5">
                <Pagination
                  loading={loading}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={(e) => {
                    setLoading(true);
                    setPageSize(e);
                    setPage(1);
                    getList({
                      page: 1,
                      pageSize: e,
                      ...sortFilters,
                      searchKey: searchKey,
                    });
                  }}
                  onPageNo={(e) => {
                    setLoading(true);
                    setPage(e);

                    getList({
                      page: e,
                      searchKey: searchKey,
                      ...sortFilters,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Wrapper>
  );
};

export default PartnerResources;
