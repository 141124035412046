import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Dropdown, message, Tag, Tooltip } from "antd";
import React, { useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../../common-context";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../apis";
import { ENDPOINT, LOGINURL } from "../../config";
import axios from "axios";
import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

const Topbar = (props) => {
  const history = useHistory();
  const {
    setMenuHide,
    menuHide,
    type,
    user,
    setUserList,
    userList,
    setUsersView,
    usersView,
    switchUser,
  } = props;
  const contextValue = useContext(GlobalContext);
  const location = useLocation();

  function capitalizeFirstLetterEachWord(str) {
    // Split the string into an array of words
    let words = str.split(" ");

    // Capitalize the first letter of each word
    let capitalizedWords = words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back into a string
    let result = capitalizedWords.join(" ");

    return result;
  }

  const logoutAction = async () => {
    if (parseInt(contextValue?.data?.user_?.user_type) === 1) {
      const LOGINURL_ = LOGINURL;
      localStorage.clear();
      window.location.href = LOGINURL_;
      return;
    }
    const response = await MakeApiCall(`seller-logout`, "post", null, true);

    if (response?.status) {
      const LOGINURL_ = LOGINURL;
      localStorage.clear();
      window.location.href = LOGINURL_;
    } else {
      message.warning(response?.message);
    }
  };
  const menus = [
    {
      key: "2",
      label: <Link to={"/profile"}>My Profile</Link>,
    },
    {
      key: "5",
      label: (
        <div
          onClick={() => {
            logoutAction();
          }}
          className="text-danger"
        >
          Logout
        </div>
      ),
    },
  ];
  const BackToAdmin = () => {
    let adminData = JSON.parse(localStorage.getItem("admin"));

    localStorage.setItem("user", JSON.stringify(adminData?.user_data));
    localStorage.setItem("token", adminData?.auth_token);
    localStorage.setItem("credentials", JSON.stringify(adminData?.credentials));
    localStorage.setItem("user-type", adminData?.user_data?.user_type);
    localStorage.removeItem("admin");
    contextValue?.updateCommonGlobalVal({
      user_: adminData?.user_data,
      credentials: adminData?.credentials,
    });
    history.push("/manage-seller");
  };

  const userData = async () => {
    try {
      const response = await axios.get(
        `${ENDPOINT}seller-user?page=1&per-page=500&field_name=&sort_by=&search_key=`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("admin"))?.auth_token
            }`,
            "Content-Type": "application/json", // Ensure JSON content type
          },
        }
      );
      if (response?.status) {
        setUserList(response?.data?.data?.records || []);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("admin")) {
      userData();
    }
    return () => {};
  }, []);

  const items = [
    ...userList
      ?.sort((a, b) => a.seller_name.localeCompare(b.seller_name))
      ?.slice(0, 5)
      ?.map((d, i) => ({
        key: (i + 1)?.toString(),
        label: (
          <Tooltip title="Click to Switch" placement="right">
            <div onClick={() => switchUser(d?.seller_user_id, d?.email)}>
              <span className="mb-1 fs-6" style={{ color: "#000" }}>
                {capitalizeFirstLetterEachWord(d?.seller_name)}
              </span>
            </div>
          </Tooltip>
        ),
      })),
    ...(userList?.length > 5
      ? [
          {
            key: "6",
            label: (
              <div
                onClick={() => {
                  setUsersView(true);
                }}
                className="text-primary text-center"
              >
                See All
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <Wrapper className="header align-items-stretch" id="kt_header">
      <div className="header-brand">
        <a href>
          <img
            alt="Logo"
            src="/assets/media/argometrix-logo.png"
            className="h-25px h-lg-35px"
          />
        </a>

        <div
          id="kt_aside_toggle"
          className="btn btn-icon btn-sm btn-light me-n8 px-0 btn-active-color-primary aside-minimize"
          onClick={() => setMenuHide(!menuHide)}
          style={{
            transform: `${menuHide ? "rotate(180deg)" : "rotate(0deg)"}`,
            transition: "0.5s",
          }}
        >
          <i className="ki-outline ki-double-right fs-1 me-n1 minimize-default "></i>
        </div>

        <div
          className="d-flex align-items-center d-lg-none me-n2"
          title="Show aside menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-30px h-30px"
            id="kt_aside_mobile_toggle"
            onClick={() => setMenuHide(!menuHide)}
          >
            <i className="ki-duotone ki-abstract-14 fs-1">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
        </div>
      </div>
      <div
        className="toolbar d-flex align-items-stretch"
        data-select2-id="select2-data-8-l8ho"
      >
        <div
          className="container-fluid py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between"
          data-select2-id="select2-data-7-xtmr"
        >
          <div className="page-title d-flex justify-content-center flex-column me-5">
            <Dropdown
              disabled={!localStorage.getItem("admin")}
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <h1 className="d-flex flex-row text-dark fw-bold fs-3 mb-0 align-items-center">
                <span className="">
                  {contextValue?.data?.user_?.seller_name || user?.name || "-"}
                </span>
                {contextValue?.data?.user_?.seller_name && (
                  <div
                    className="ms-3 ps-3"
                    style={{
                      borderLeft: "2px solid darkgray",
                      color: "#7e7e7e",
                    }}
                  >
                    United States
                  </div>
                )}
                {/* {localStorage.getItem("admin") && (
                  <i
                    className="ki-duotone ki-to-left ms-1 fs-3"
                    style={{
                      transform: "rotate(26deg)",
                      position: "relative",
                      top: -1,
                      left: 2,
                      color: "#3c3c3c",
                    }}
                  />
                )} */}
                {localStorage.getItem("admin") && (
                  <Icon
                    icon={"fe:arrow-down"}
                    width={20}
                    height={20}
                    style={{
                      color: "#3c3c3c",
                      position: "relative",
                      top: 1,
                      left: 3,
                    }}
                  />
                )}
              </h1>
            </Dropdown>

            {/* {location?.pathname === "/" ||
              location?.pathname === "/dashboard" ? (
                
              ) : (
                <span className="text-primary"></span>
              )} */}
          </div>
          <div className=" d-flex align-items-center">
            <img
              src="/assets/media/SIPDashboardLogo.png"
              className="h-35px"
              alt
            />{" "}
          </div>

          <div
            className="d-flex align-items-center  pt-3 pt-lg-0"
            data-select2-id="select2-data-6-jxxg"
          >
            {localStorage.getItem("admin") && (
              <div className="app-navbar-item ms-1 ms-md-3">
                <a
                  onClick={() => BackToAdmin()}
                  className="btn btn-icon btn-search text-light  w-35px h-35px w-md-40px h-md-40px"
                >
                  <i className="ki-outline ki-exit-left text-light fs-2" />
                </a>
              </div>
            )}
            {!localStorage.getItem("admin") && (
              <Link to={{ pathname: "/logs/admin-alerts" }}>
                <div className="app-navbar-item ms-1 ms-md-3">
                  <div
                    className="btn btn-icon btn-custom btn-color-gray-600 btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative"
                    id="kt_drawer_chat_toggle"
                  >
                    <i className="ki-outline ki-notification-on fs-1" />
                  </div>
                </div>
              </Link>
            )}
            <Dropdown
              menu={{
                items: menus,
              }}
              trigger={["click"]}
              placement="bottomLeft"
            >
              <div
                onClick={(e) => e.preventDefault()}
                className="btn btn-flex align-items-center bg-hover-dark bg-hover-opacity-10 py-2 ps-2 pe-2 me-n2"
              >
                <img
                  src={user?.photo}
                  onError={(e) => e.target.src}
                  className="border border-primary p-1"
                  alt
                  style={{
                    borderRadius: "100%",
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Topbar;
