import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm, Modal } from "antd";
import { Wrapper } from "./style";
import {
  configModal,
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../config";
import AddData from "./lib/seller-add";
import { MakeApiCall } from "../../../apis";
import Pagination from "../../../components/pagination";
import { render } from "@testing-library/react";

export default function SellerParameters() {
  const [modal, contextHolder] = Modal.useModal();
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const [selectedRow, setSelectedRow] = useState({});

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };

  const deleteAction = async (id) => {
    const response = await MakeApiCall(
      `seller-parameter/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      message.destroy();

      modal.success(configModal("Seller Parameter", response?.message));

      setLoading(true);
      setPage(1);
      getList({ page: 1 });
    } else {
      message.destroy();
      modal.warning(configModal("Seller Parameter", response?.message));
    }
  };
  // const PropsFilter = (type) => {
  //   return {
  //     defaultSortOrder:
  //       type === sortFilters?.field_name
  //         ? DefaultSortType?.[sortFilters?.sort_by]
  //         : [],
  //     sorter: () => {},
  //   };
  // };
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj });
  };

  const getList = async (data) => {
    // page=${data?.page || page || 1}&per-page=${
    //   data?.pageSize || pageSize
    // }&field_name=${data?.field_name || ""}&sort_by=${data?.sort_by || ""}
    setLoading(true);
    const response = await MakeApiCall(
      `seller-parameter?isAll=0&field_name=param_name&sort_by`,
      "get",
      null,
      true
    );

    if (response?.status) {
      // setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data || []);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getList({
      ...sortFilters,
    });
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      align: "center",
      render: (_, __, i) => {
        return (
          <div className="d-flex justify-content-center w-100">
            {(page - 1) * pageSize + 1 + i}
          </div>
        );
      },
    },
    {
      title: "Parameter Group Name",
      dataIndex: "parameter_group_name",
      key: "parameter_group_name",
      filterIndex: "parameter_group_name",
    },
    {
      title: "Parameter",
      dataIndex: "param_name",
      key: "param_name",
      filterIndex: "param_name",
    },
    {
      title: "Type",
      dataIndex: "param_type",
      key: "param_type",
      filterIndex: "param_type",
    },
    {
      title: "Default Value",
      dataIndex: "default_value",
      key: "default_value",
      align: "right",
      filterIndex: "default_value",
      render: (e) => {
        return e || 0;
      },
    },
    {
      title: "Default Lower Value",
      dataIndex: "default_lower_value",
      align: "right",
      key: "default_lower_value",
      filterIndex: "default_lower_value",
      render: (e) => {
        return e || 0;
      },
    },
    {
      title: "Default Upper Value",
      dataIndex: "default_upper_value",
      align: "right",
      key: "default_upper_value",
      filterIndex: "default_upper_value",
      render: (e) => {
        return e || 0;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterIndex: "status",
      render: (e) => {
        return e ? "Active" : "Inactive";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="d-flex">
          <a
            onClick={() => {
              setAddModal(true);
              setModalType("Update");
              setSelectedRow(_);
            }}
            className=" me-2"
          >
            {EditIcon}
          </a>

          {/* <Popconfirm
            title="Delete this Seller Parameter"
            description={`Are you sure to delete this ${_?.parameter_group_name}?`}
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);
              deleteAction(_?.id);
            }}
            placement="left"
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className=" me-2">
              {DeleteIcon}
            </a>
          </Popconfirm> */}
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      <div className="card mt-5">
        <div className="card-header">
          <div className="card-title">Seller Parameters</div>
          <div className="card-toolbar">
            <a
              className="add-btn "
              onClick={() => {
                setAddModal(true);
                setModalType("Add");
              }}
            >
              <svg
                width={16}
                height={16}
                className="me-2"
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
              </svg>
              Add Seller Parameters
            </a>
          </div>
        </div>
        <div className="card-body pt-0">
          <Table
            // onChange={handleChange}
            fixed={true}
            sticky={{
              offsetHeader: "0px",
            }}
            pagination={{
              size: "default",
              showSizeChanger: true,
              defaultPageSize: 100,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            bordered
            size="small"
            dataSource={list}
            scroll={{ x: "max-content" }}
            columns={columns?.map((d) => ({
              ...d,
              className: d?.title === "#" ? "maxWidth-table" : "minWidth-table",
              ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
              render: (props, row, index) => RenderTable(props, row, index, d),
            }))}
          />
        </div>
        {/* <div className="card-footer pt-0 pb-5">
          <Pagination
            loading={loading}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={(e) => {
              setLoading(true);
              setPageSize(e);
              setPage(1);
              getList({
                page: 1,
                pageSize: e,
                ...sortFilters,
              });
            }}
            onPageNo={(e) => {
              setLoading(true);
              setPage(e);
              getList({
                page: e,
                ...sortFilters,
              });
            }}
          />
        </div> */}
        {addModal && (
          <AddData
            visible={addModal}
            selectedRow={selectedRow}
            modalType={modalType}
            onClose={() => {
              setSelectedRow({});
              setAddModal(false);
            }}
            callAPI={() => {
              setPage(1);
              getList({ page: 1 });
            }}
          />
        )}
      </div>
    </Wrapper>
  );
}
