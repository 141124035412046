import { Button, Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { configModal } from "../../../../config";

const AddData = ({ visible, onClose, modalType, callAPI, selectedRow }) => {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [parameterGroupList, setParameterGroupList] = useState([]);
  const [parameterGroupLoading, setParameterGroupLoading] = useState(true);

  const [metricGroupList, setMetricGroupList] = useState([]);
  const [metricGroupLoading, setMetricGroupLoading] = useState(true);

  const isMetric = selectedRow?.type === "metric";

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      if (isMetric) {
        form.setFieldsValue({
          metric_name: selectedRow?.metric_name,
          metric_group_id: selectedRow?.metric_group_id,
          metric_type: selectedRow?.metric_type,
          // metric_unit: selectedRow?.metric_unit,
          description: selectedRow?.description,
          param_type: selectedRow?.param_type,
          default_lower_value: selectedRow?.default_lower_value,
          default_upper_value: selectedRow?.default_upper_value,
        });
      } else {
        form.setFieldsValue({
          parameter_group_id: selectedRow?.parameter_group_id,
          param_name: selectedRow?.param_name,
          param_type: selectedRow?.param_type,
          default_value: selectedRow?.default_value,
          default_upper_value: selectedRow?.default_upper_value,
          default_lower_value: selectedRow?.default_lower_value,
        });
      }
    }
  }, [selectedRow]);
  const UpdateAction = async (data) => {
    const response = await MakeApiCall(
      `${isMetric ? "metric" : "seller-parameter"}/${selectedRow?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
      modal
        .success(configModal("Seller Parameter", response?.message))
        .then(() => {
          callAPI();
          onClose();
        });
    } else {
      modal.error(configModal("Seller Parameter", response?.message));

      message.destroy();
    }
  };
  const AddUpdate = async (data) => {
    const response = await MakeApiCall(`seller-parameter`, "post", data, true);

    if (response?.status) {
      message.destroy();

      modal
        .success(configModal("Seller Parameter", response?.message))
        .then(() => {
          callAPI();
          onClose();
        });
    } else {
      message.destroy();
      modal.error(configModal("Seller Parameter", response?.message));
    }
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        message.destroy();
        message.loading("Loading...", 0);

        if (modalType === "Add") {
          AddUpdate(values);
        } else {
          UpdateAction(values);
        }
      })
      .catch((info) => {});
  };
  const handleCancel = () => {
    onClose();
  };
  const GetParameterGroupList = async () => {
    setParameterGroupLoading(true);
    const response = await MakeApiCall(
      `parameter-group?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setParameterGroupList(response?.data?.records || []);
      setParameterGroupLoading(false);
    } else {
      message.destroy();
      setParameterGroupLoading(false);
      message.warning(response?.message);
    }
  };
  const getGroupList = async () => {
    setMetricGroupLoading(true);
    const response = await MakeApiCall(
      `metric-group?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setMetricGroupList(response?.data?.records || []);
      setMetricGroupLoading(false);
    } else {
      setMetricGroupList([]);
      setMetricGroupLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    if (isMetric) {
      getGroupList();
    }
    return () => {};
  }, []);
  useEffect(() => {
    GetParameterGroupList();

    return () => {};
  }, []);

  return (
    <Modal
      title={`${modalType === "Add" ? "Add New" : "Edit"} Seller Parameter`}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {isMetric ? (
        <Form form={form} layout="vertical">
          {selectedRow?.id && (
            <Form.Item label="ID">
              <Input
                disabled
                style={{ width: "100%" }}
                value={selectedRow?.id}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Metric Group"
            name="metric_group_id"
            rules={[{ required: true, message: "Please Enter Metric Group" }]}
          >
            <Select
              placeholder="Enter Metric Group"
              loading={metricGroupLoading}
              style={{ width: "100%" }}
              options={metricGroupList?.map((d) => ({
                label: d?.group_name,
                value: d?.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Metric Name"
            name="metric_name"
            rules={[{ required: true, message: "Please Enter Metric Name" }]}
          >
            <Input placeholder="Enter Metric Name" />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input placeholder="Enter Description" />
          </Form.Item>

          <Form.Item
            label="Type"
            name="metric_type"
            rules={[{ required: true, message: "Please Select Type" }]}
          >
            <Select placeholder="Select Type" style={{ width: "100%" }}>
              <Select.Option value="Company">Company</Select.Option>
              <Select.Option value="Product">Product</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Parameter Type"
            name="param_type"
            rules={[
              { required: true, message: "Please input your Parameter Type!" },
            ]}
          >
            <Select
              options={[
                {
                  label: "Range%",
                  value: "Range%",
                },
                {
                  label: "Range$",
                  value: "Range$",
                },
                {
                  label: "RangeInt",
                  value: "RangeInt",
                },
              ]}
              onChange={() => {
                form.setFieldsValue({
                  default_value: "",
                  default_upper_value: "",
                  default_lower_value: "",
                });
              }}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const paramType = getFieldValue("param_type");
              return (
                <>
                  {!["Text", "Single%", "Single$", "SingleInt"].includes(
                    paramType
                  ) && (
                    <Form.Item
                      label="Default Lower Value"
                      name="default_lower_value"
                      rules={[
                        {
                          pattern: /^(?:\d+|\d+\.\d+)$/,
                          message: "Please enter a valid number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {!["Text", "Single%", "Single$", "SingleInt"].includes(
                    paramType
                  ) && (
                    <Form.Item
                      label="Default Upper Value"
                      name="default_upper_value"
                      rules={[
                        {
                          pattern: /^(?:\d+|\d+\.\d+)$/,
                          message: "Please enter a valid number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Item>
        </Form>
      ) : (
        <Form form={form} layout="vertical" name="seller-parameter">
          <Form.Item
            label="Parameter Group"
            name="parameter_group_id"
            rules={[
              {
                required: true,
                message: "Please input your Parameter Group!",
              },
            ]}
          >
            <Select
              options={parameterGroupList?.map((d) => ({
                label: d?.group_name,
                value: d?.id,
              }))}
              loading={parameterGroupLoading}
            />
          </Form.Item>
          <Form.Item
            label="Parameter Name"
            name="param_name"
            rules={[
              { required: true, message: "Please input your Parameter Name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Parameter Type"
            name="param_type"
            rules={[
              { required: true, message: "Please input your Parameter Type!" },
            ]}
          >
            <Select
              options={[
                {
                  label: "Text",
                  value: "Text",
                },
                {
                  label: "Range%",
                  value: "Range%",
                },
                {
                  label: "Range$",
                  value: "Range$",
                },
                {
                  label: "RangeInt",
                  value: "RangeInt",
                },
                {
                  label: "Single%",
                  value: "Single%",
                },
                {
                  label: "Single$",
                  value: "Single$",
                },
                {
                  label: "SingleInt",
                  value: "SingleInt",
                },
              ]}
              onChange={() => {
                form.setFieldsValue({
                  default_value: "",
                  default_upper_value: "",
                  default_lower_value: "",
                });
              }}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const paramType = getFieldValue("param_type");
              return (
                <>
                  {["Text", "Single%", "Single$", "SingleInt"].includes(
                    paramType
                  ) && (
                    <Form.Item
                      label="Default Value"
                      name="default_value"
                      dependencies={["param_type"]}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const paramType = getFieldValue("param_type");
                            if (!value || paramType === "Text") {
                              return Promise.resolve();
                            }
                            const regex = /^(?:\d+|\d+\.\d+)$/;
                            if (!regex.test(value)) {
                              return Promise.reject(
                                new Error(
                                  "Invalid Value, please input a number!"
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {!["Text", "Single%", "Single$", "SingleInt"].includes(
                    paramType
                  ) && (
                    <Form.Item
                      label="Default Lower Value"
                      name="default_lower_value"
                      rules={[
                        {
                          pattern: /^(?:\d+|\d+\.\d+)$/,
                          message: "Please enter a valid number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {!["Text", "Single%", "Single$", "SingleInt"].includes(
                    paramType
                  ) && (
                    <Form.Item
                      label="Default Upper Value"
                      name="default_upper_value"
                      rules={[
                        {
                          pattern: /^(?:\d+|\d+\.\d+)$/,
                          message: "Please enter a valid number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Item>
        </Form>
      )}
      {contextHolder}
    </Modal>
  );
};

export default AddData;
