import { DatePicker, Table, Tag, Tooltip } from "antd";
import React from "react";
import { numberformat, RenderTable } from "../../../../config";
import dayjs from "dayjs";

const Top10ItemPerformance = ({
  onLineItem,
  setViewAllTop10Items,
  viewAllTop10Items,
  topTenItemsPerformanceLoading,
  topTenItemsPerormance,
  getDataAction,
  selectedRange,
  presetRanges,
  filters,
  actionCall,
}) => {
  const icons = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      style={{
        position: "relative",
        top: "-4px",
        left: "0.5px",
      }}
    >
      <path
        d="M3.75 11.125L6.875 8L3.75 4.875M8.125 11.125L11.25 8L8.125 4.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  // Define columns
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: (e) => {
        return <div className="d-flex justify-content-center w-100">{e}</div>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (e, row) => {
        return (
          <div
            onClick={() => {
              console.log(row, e, "asdasdasd");

              onLineItem({ asin_: e, sku: row?.sku, title: row?.product_name });
            }}
            className="d-flex align-items-center justify-content-between w-100 text-hover-primary cursor-pointer"
          >
            {e}
            <div
              className="bg-primary ms-2"
              style={{
                borderRadius: "100%",
                postion: "relative",
                width: "15px",
                height: "15px",
              }}
            >
              {icons}
            </div>
          </div>
        );
      },
    },
    {
      title: "Item",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Title",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (e) => {
        return (
          <Tooltip title={e}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "300px",
                display: "block",
              }}
            >
              {e}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Units Sold",
      dataIndex: "unit_sold",
      key: "unit_sold",
      render: (text) => `${text?.toLocaleString()}`,
      align: "right",
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Sessions",
      dataIndex: "session",
      key: "session",
      align: "right",
    },
    {
      title: "USP (%)",
      dataIndex: "usp",
      key: "usp",
      align: "right",
      render: (text) => `${text}%`,
    },
    {
      title: "BB (%)",
      dataIndex: "buy_box_pect",
      key: "buy_box_pect",
      align: "right",
      render: (text) => `${text}%`,
    },
    {
      title: "Velocity",
      dataIndex: "velocity",
      key: "velocity",
      align: "right",
    },
    {
      title: "LP",
      dataIndex: "last_period",
      key: "last_period",
      align: "right",
    },
    {
      title: "LP Velocity",
      dataIndex: "last_period_velocity",
      key: "last_period_velocity",
      align: "right",
    },
    {
      title: "Variance (%)",
      dataIndex: "variance",
      key: "variance",
      align: "right",
      render: (text) => `${parseFloat(text).toFixed(2)}%`,
    },
    {
      title: "Share (%)",
      dataIndex: "share",
      key: "share",
      align: "right",
      render: (text) => `${parseFloat(text).toFixed(2)}%`,
    },
  ];
  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          {viewAllTop10Items && (
            <div className="me-3">
              <span
                className="svg-icon svg-icon-muted svg-icon-2hx"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setViewAllTop10Items(false);
                  getDataAction(0);
                }}
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={5}
                    fill="currentColor"
                  />
                  <path
                    d="M12.0657 12.5657L14.463 14.963C14.7733 15.2733 14.8151 15.7619 14.5621 16.1204C14.2384 16.5789 13.5789 16.6334 13.1844 16.2342L9.69464 12.7029C9.30968 12.3134 9.30968 11.6866 9.69464 11.2971L13.1844 7.76582C13.5789 7.3666 14.2384 7.42107 14.5621 7.87962C14.8151 8.23809 14.7733 8.72669 14.463 9.03696L12.0657 11.4343C11.7533 11.7467 11.7533 12.2533 12.0657 12.5657Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          )}
          <b>{`${
            viewAllTop10Items ? "Catalog" : "Top10 Item"
          }  Performance`}</b>
        </div>
        <div className="card-toolbar">
          {viewAllTop10Items && (
            <>
              <Tag color="blue-inverse" className="me-2">
                {selectedRange === "custom" ? "Selected period" : selectedRange}
              </Tag>

              <DatePicker.RangePicker
                presets={presetRanges}
                allowClear={false}
                size="middle"
                style={{
                  width: "280px",
                  border: "1px solid #D7D7D7",
                }}
                format={"YYYY-MMM-DD"}
                value={
                  filters?.start_date && filters?.end_date
                    ? [
                        dayjs(filters?.start_date, "YYYY-MM-DD"),
                        dayjs(filters?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                disabledDate={(current) => {
                  // Disable future dates
                  return current && current >= dayjs().endOf("day");
                }}
                onChange={(e, _) => {
                  const obj = {
                    start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };

                  actionCall(obj, e, _, 1);
                }}
              />
            </>
          )}
        </div>
      </div>
      <div
        className="card-body pt-0"
        style={{ width: "100%", position: "relative" }}
      >
        <Table
          size="small"
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "even-row" : "odd-row";
          }}
          scroll={{ x: "max-content" }}
          columns={columns?.map((d) => ({
            ...d,
            className: d?.title === "#" ? "maxWidth-table" : "minWidth-table",
            render: (props, row, index) => RenderTable(props, row, index, d),
          }))}
          dataSource={
            topTenItemsPerormance?.item_data?.map((d, i) => ({
              ...d,
              key: i + 1,
            })) || []
          }
          loading={topTenItemsPerformanceLoading}
          bordered
          pagination={false}
          summary={() => {
            const {
              amount,
              buy_box_pect,
              last_period,
              last_period_velocity,
              session,
              share,
              unit_sold,
              usp,
              variance,
              velocity,
            } = topTenItemsPerormance?.total || {};
            return (
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{ background: "#e6eaf1", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell index={0} colSpan={4}>
                    <strong>Total</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={1}>
                    {numberformat(unit_sold || 0, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={2}>
                    ${numberformat(amount || 0, 2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={3}>
                    {numberformat(session || 0, 0)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell align="right" index={9}>
                    {numberformat(usp || 0, 0)}%
                  </Table.Summary.Cell>

                  <Table.Summary.Cell align="right" index={10}>
                    {numberformat(buy_box_pect || 0, 0)}%
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={11}>
                    {numberformat(velocity || 0, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={12}>
                    {numberformat(last_period || 0, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={13}>
                    {numberformat(last_period_velocity || 0, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={13}>
                    {numberformat(variance || 0, 0)}%
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={13}>
                    {numberformat(share || 0, 0)}%
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        {!viewAllTop10Items && (
          <div className="row">
            <div
              className="col-12 d-flex justify-content-end align-items-center"
              style={{ position: "relative", top: "10px" }}
            >
              <div
                className="d-flex align-items-center  text-hover-primary cursor-pointer"
                onClick={() => {
                  setViewAllTop10Items(true);
                  getDataAction(1);
                }}
              >
                View All{" "}
                <div
                  className="bg-primary ms-2"
                  style={{
                    borderRadius: "100%",
                    postion: "relative",
                    width: "15px",
                    height: "15px",
                  }}
                >
                  {icons}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Top10ItemPerformance;
