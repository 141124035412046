import styled from "styled-components";

export const WrapperContant = styled.div`
  table {
    thead {
      tr {
        th {
          background: #f2f2f2;
          padding: 5px;
        }
      }
    }
    tbody {
      tr {
        td {
          color: #000;
          font-size: 14px;

          font-weight: 400;
        }
      }
    }
  }
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  .table-fixed th.fixed-column,
  tr > td.fixed-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f2f2f2;
    padding-right: 10px;
  }
  .odd-row-new {
    background: #ebebeb;
  }
  .even-row-new {
    background: #fff;
  }
  .table-container {
    position: relative;
  }
  table {
    width: auto;
  }
  th,
  td {
    padding: 5px 15px;

    white-space: nowrap;
  }
  ._,
  .__,
  .___,
  .____ {
    color: #00000000;
  }
  .box-content {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 6px;
  }
  .performance_part {
    background: #fff;
    padding: 1px 20px;
    margin-top: 15px;
    border-radius: 7px;
    .card {
      border: 1px solid #80808033;
    }
  }

  .top-bar-page-header {
    width: 100%;
    box-shadow: rgb(226, 226, 234) 0px -1px 0px inset;

    transition: all 0.6s ease 0s;
    align-items: center;
    display: inline-flex;
    padding-bottom: 10px;

    padding-bottom: 5px;
    margin-bottom: 10px !important;
    margin-top: 0px !important;

    .page-header-info {
      // width: 598px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 6px;
      display: inline-flex;
      .discription-text {
        color: rgb(68, 68, 79);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        overflow-wrap: break-word;
      }
      .header-title-page {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2px;
        display: flex;
        .title-text {
          text-align: center;
          color: rgba(24, 24, 25, 0.9);
          font-size: 18px;
          font-weight: 700;
          overflow-wrap: break-word;
        }
      }
    }
  }
`;
export const TableWrapper = styled.div`
  .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .createDateRangePicker {
    .ant-picker-presets {
      ul {
        display: flex;

        list-style: none;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 110px;
      }

      li {
        height: fit-content;
        margin: 5px 0;
      }
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }

  .ant-table-thead .ant-table-cell {
    background: #f1f1f1 !important;
    color: #202020 !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }

  .selected-row-financial {
    .ant-table-thead .ant-table-cell {
      // background: #f1f1f1 !important;
      // color: #202020 !important;
    }
  }
  .odd-row-new {
    background: #ebebeb;
  }
  .even-row-new {
    background: #fff;
  }
  .selected-row-financial .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: #fafafa00 !important;
  }
`;
