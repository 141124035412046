import { Popover, Tooltip } from "antd";
import React, { useState } from "react";
import PlayView from "../video";

const InfoVideo = ({ className, data = {}, title }) => {
  const [videoView, setVideoView] = useState(false);
  const [videoData, setVideoData] = useState({});

  const locationId =
    data?.contentLocation?.filter((d) => {
      return d?.slug === title && parseInt(d?.status) === 1; //
    })?.[0]?.id || "";

  const checkVideoAndInfo = (data?.contentData || []).reduce(
    (acc, { location, catalogContent }) => {
      if (location?.id === locationId) {
        const key =
          parseInt(catalogContent?.type) === 2
            ? "video"
            : parseInt(catalogContent?.type) === 1
            ? "info"
            : null;
        if (key)
          acc[key].push({
            location,
            catalogContent,
          });
      }
      return acc;
    },
    { video: [], info: [] }
  );

  const info = checkVideoAndInfo?.info?.[0]?.catalogContent;
  const video = checkVideoAndInfo?.video?.[0]?.catalogContent;

  return (
    <>
      <div className={className}>
        {checkVideoAndInfo?.info?.length > 0 && (
          <Popover
            placement="top"
            title={checkVideoAndInfo?.info?.[0]?.catalogContent?.title}
            // getPopupContainer={(triggerNode) => triggerNode.parentNode}
            content={
              <div
                style={{
                  maxWidth: "350px",
                  maxHeight: "200px",
                  overflow: "auto",
                  fontSize: `${parseInt(info?.font_size)}px`,
                  fontFamily: info?.font_style,
                  fontWeight: parseInt(info?.font_weight),
                }}
                dangerouslySetInnerHTML={{
                  __html: info?.info_text,
                }}
              />
            }
          >
            <span>
              <svg
                style={{ width: "15px", marginLeft: "5px" }}
                aria-hidden="true"
                viewBox="0 0 512 512"
                className=" fas fa-info-circle"
              >
                <path
                  fill="#6d6d6d"
                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                />
              </svg>
            </span>
          </Popover>
        )}
        {checkVideoAndInfo?.video?.length > 0 && (
          <span>
            <svg
              width={22}
              height={22}
              fill="#3c71b8"
              onClick={() => {
                setVideoView(true);
                setVideoData(video);
              }}
              className="ms-3 cursor-pointer"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7Z" />
            </svg>
          </span>
        )}
      </div>
      {videoView && (
        <PlayView
          visible={videoView}
          videoData={videoData}
          setVisible={() => {
            setVideoView(false);
            setVideoData({});
          }}
        />
      )}
    </>
  );
};

export default InfoVideo;
