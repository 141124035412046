import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Checkbox, Flex, Popconfirm, Table, Tag, Tooltip, message } from "antd";
import {
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../../config";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import AddData from "./lib";
import { MakeApiCall } from "../../../../apis";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import dayjs from "dayjs";
import { MessageSquare } from "lucide-react";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

export default function MetricsMenuItemMapping() {
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const [selectedRow, setSelectedRow] = useState({});

  // const sortType = {
  //   descend: "desc",
  //   ascend: "asc",
  // };
  // const DefaultSortType = {
  //   desc: "descend",
  //   asc: "ascend",
  // };
  // const PropsFilter = (type) => {
  //   return {
  //     defaultSortOrder:
  //       type === sortFilters?.field_name
  //         ? DefaultSortType?.[sortFilters?.sort_by]
  //         : [],
  //     sorter: () => {},
  //   };
  // };
  // const handleChange = (_, __, sorter) => {
  //   const obj = {
  //     field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
  //     sort_by: sortType?.[sorter?.order] || "",
  //   };
  //   setSortFilters(obj);
  //   getList({ ...obj });
  // };

  const getList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `metric-menu-item?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const setDefault = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `metric-menu-item/set-default`,
      "post",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setList((prev) => {
        return prev?.map((d) => {
          if (d?.menu_item_id === data?.menu_item_id) {
            return {
              ...d,
              metrics: d?.metrics?.map((t) => {
                if (parseInt(t?.id) === parseInt(data?.metric_id)) {
                  return {
                    ...t,
                    is_default: data?.is_default,
                  };
                }
                return t;
              }),
            };
          }
          return d;
        });
      });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const deleteAction = async (id) => {
    const response = await MakeApiCall(
      `metric-menu-item/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setLoading(true);
      setPage(1);
      getList({ page: 1 });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getList();
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      align: "center",
      dataIndex: "index",
      key: "index",
      render: (_) => {
        return <div className="d-flex justify-content-center w-100">{_}</div>;
      },
    },

    {
      title: "Metric Group",
      render: (e) => {
        console.log(
          [...new Set(e?.metrics?.map((d) => d?.group_name))],

          "e"
        );

        return (
          <Flex gap={2}>
            {[...new Set(e?.metrics?.map((d) => d?.group_name))]?.map(
              (d, i) => (
                <Tag color="blue">{d}</Tag>
              )
            )}
          </Flex>
        );
      },
    },
    {
      title: "Metric Item",
      dataIndex: "menu_item",
      key: "menu_item",
    },
  ];

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: "Metric Group",
        dataIndex: "group_name",
        key: "group_name",
      },
      {
        title: "Metric",
        dataIndex: "metric_name",
        key: "metric_name",
      },
      {
        title: "Metric Type",
        dataIndex: "metric_type",
        key: "metric_type",
        render: (e) => {
          return <span style={{ textTransform: "capitalize" }}>{e}</span>;
        },
      },
      {
        title: "Default",
        dataIndex: "is_default",
        key: "is_default",
        render: (defaultValue, row) => {
          const disabledCheck =
            record.metrics?.filter((d) => d?.is_default)?.length > 7 &&
            !defaultValue;
          return (
            <Tooltip
              placement="right"
              title={disabledCheck ? "Max Select 8" : ""}
            >
              <Checkbox
                disabled={disabledCheck}
                onChange={(e) => {
                  const obj = {
                    menu_item_id: record?.menu_item_id,
                    metric_group_id: row?.metric_group_id,
                    metric_id: row?.id,
                    is_default: e.target.checked ? 1 : 0,
                  };
                  setDefault(obj);
                }}
                checked={defaultValue}
              />
            </Tooltip>
          );
        },
      },
      {
        title: "Create Date",
        dataIndex: "created_at",
        key: "created_at",
        render: (e) => {
          return dayjs(new Date(e * 1000)).format("DD/MM/YYYY");
        },
      },
    ];
    return (
      <div>
        <Table
          columns={expandedColumns}
          dataSource={record.metrics}
          pagination
          rowKey="id"
        />
      </div>
    );
  };

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="card">
        <div className="card-header">
          <div className="card-title">Metric Mapping</div>
          <div className="card-toolbar">
            <a
              onClick={() => {
                setAddModal(true);
                setModalType("Add");
              }}
              className="add-btn "
            >
              <svg
                width={16}
                height={16}
                className="me-2"
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
              </svg>
              Add Mapping
            </a>
          </div>
        </div>
        <div className="card-body pt-0 pb-0">
          {loading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <div className="">
              <Table
                columns={columns?.map((d) => ({
                  ...d,
                  className:
                    d?.title === "#" ? "maxWidth-table" : "minWidth-table",
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                scroll={{ x: "max-content" }}
                expandIconColumnIndex={1}
                dataSource={list?.map((d, i) => ({
                  ...d,
                  index: i + 1,
                }))}
                expandable={{
                  expandedRowRender,
                  rowExpandable: (record) => record?.metrics?.length > 0,
                }}
                rowKey="index"
              />
            </div>
          )}
        </div>
        <div className="card-footer d-none pt-0 pb-5">
          <Pagination
            loading={loading}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={(e) => {
              setLoading(true);
              setPageSize(e);
              setPage(1);
              getList({
                page: 1,
                pageSize: e,
                ...sortFilters,
              });
            }}
            onPageNo={(e) => {
              setLoading(true);
              setPage(e);
              getList({
                page: e,
                ...sortFilters,
              });
            }}
          />
        </div>
      </div>
      {addModal && (
        <AddData
          visible={addModal}
          selectedRow={selectedRow}
          modalType={modalType}
          onClose={() => {
            setSelectedRow({});
            setAddModal(false);
          }}
          callAPI={() => {
            setPage(1);
            getList({ page: 1 });
          }}
        />
      )}
    </Wrapper>
  );
}
