import dayjs from "dayjs";
import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect } from "react";

const StackedBarLineChart = ({
  updated_data,
  colorList,
  selectedGraph,
  series,
}) => {
  const signFind = {
    AOV: "$",
    "Paid Sales": "$",
    "Organic Sales": "$",
    "External Sales": "$",
    "Sponsored Products": "$",
    "Sponsored Brand": "$",
    "Sponsored Brand Video": "$",
    "Sponsored Display": "$",
    "Sponsored Television": "$",

    "Paid Sales (Units)": "",
    "Organic Sales (Units)": "",
    "External Sales (Units)": "",
    "Sponsored Products (Units)": "",
    "Sponsored Brand (Units)": "",
    "Sponsored Brand Video (Units)": "",
    "Sponsored Display (Units)": "",
    "Sponsored Television (Units)": "",
  };
  const titleNames = {
    aov: {
      name: "AOV",
      stack: "",
      yAxis: 2,
      type: "line",
    },
    sales_paid_sales: {
      name: "Paid Sales",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sales_organic_sales: {
      name: "Organic Sales",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sales_external_sales: {
      name: "External Sales",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sp_sales: {
      name: "Sponsored Products",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sb_sales: {
      name: "Sponsored Brand",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sbv_sales: {
      name: "Sponsored Brand Video",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sd_sales: {
      name: "Sponsored Display",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    st_sales: {
      name: "Sponsored Television",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },

    units_paid_sales: {
      name: "Paid Sales (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },
    units_organic_sales: {
      name: "Organic Sales (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },
    units_external_sales: {
      name: "External Sales (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },
    sp_units: {
      name: "Sponsored Products (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },
    sb_units: {
      name: "Sponsored Brand (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },
    sbv_units: {
      name: "Sponsored Brand Video (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },
    sd_units: {
      name: "Sponsored Display (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },

    st_units: {
      name: "Sponsored Television (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },
  };

  useEffect(() => {
    if (Object?.keys(updated_data?.chartData || {})?.length === 0) return;

    Highcharts.chart("line-chart", {
      chart: {
        renderTo: "sales-by-week-graph",
        height: "430px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
        type: "column",
      },

      title: "",
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      xAxis: {
        title: {
          text: "",
        },
        labels: {
          style: {
            fontWeight: 400,
            color: "#656565",
            fontSize: "14px",
          },
        },
        categories: updated_data?.chartDate,
      },
      yAxis: [
        {
          title: {
            text: "Sales",
            style: {
              fontWeight: "bold",

              fontSize: "14px",
            },
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return this.stack;
            },
            style: {
              fontWeight: "bold",
              color:
                (Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                "gray",
            },
          },
          labels: {
            style: {
              fontWeight: 400,
              color: "#656565",
              fontSize: "14px",
            },
            formatter: function () {
              return "$" + this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "Units",
            style: {
              fontWeight: "bold",
              fontSize: "14px",
            },
          },
          opposite: true,
          labels: {
            style: {
              fontWeight: 400,
              color: "#656565",
              fontSize: "14px",
            },
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "AOV",
            style: {
              fontWeight: 400,
              color: "#656565",

              fontSize: "14px",
            },
          },
          opposite: true,
          labels: {
            style: {
              fontWeight: 400,
              color: "#656565",
              fontSize: "14px",
            },
            formatter: function () {
              return "$" + this.value?.toLocaleString();
            },
          },
        },
      ],

      series: Object.entries(updated_data?.chartData || {})
        ?.filter(([key, _]) =>
          selectedGraph?.map((d) => d?.toLowerCase())?.includes(key)
        )
        ?.map(([key, value], i) => {
          const type = titleNames?.[key]?.stack === "sales";
          console.log(
            series?.filter((d) => d?.name?.toLowerCase() === key)?.[0]?.color,
            "colorCheck"
          );

          return {
            ...titleNames?.[key],

            data: type ? Object.values(value) : Object.values(value),
            color: series?.filter((d) => d?.name?.toLowerCase() === key)?.[0]
              ?.color,
          };
        }),
      tooltip: {
        useHTML: true,
        shared: true,

        // formatter: function () {
        //   let tooltipText = `<b style="color: #252f4a; font-size: 14px; font-family: Rubik, sans-serif; font-weight: 500;">${this.x}</b><br/><br/>`; // Category (x-axis label)

        //   if (this.points) {
        //     // For shared tooltip (multiple points on the same category)
        //     this.points.forEach((point) => {
        //       tooltipText += `<div  style="display: flex; align-items: center;">  <div style="color:${
        //         point.color
        //       }; font-size: 19px; margin-right: 8px; ">\u25CF</div> <span style="color: #252f4a; font-size: 14px;">${
        //         point.series.name
        //       }</span>: <span style="color: #252f4a; font-size: 14px; font-weight: 600;">${
        //         point.series.options.prefix || ""
        //       }${numberWithCommas(point.y)}${
        //         point.series.options.suffix || ""
        //       }</span></div><br/>`;
        //     });
        //   } else {
        //     // For single data point tooltip
        //     tooltipText += ` <div  style="display: flex; align-items: center;"> <span style="color:${
        //       this.color
        //     };  font-size: 19px;  margin-right: 8px;">\u25CF</span> <span style="color: #252f4a; font-size: 14px;">${
        //       this.series.name
        //     }</span>: <span style="color: #252f4a; font-size: 14px; font-weight: 600;">${
        //       this.series.options.prefix || ""
        //     }${numberWithCommas(this.y)}${
        //       this.series.options.suffix || ""
        //     }</span></div><br/>`;
        //   }

        //   return tooltipText;
        // },
        // pointFormatter: function () {
        //   return (
        //     '<span style="color: #656565; font-weight: 300; font-size: 14px;">\u25CF</span> ' +
        //     '<span style="text-transform: capitalize">' +
        //     this.series.name +
        //     "</span>" +
        //     ": <b>" +
        //     (signFind?.[this.series.name] || "") +
        //     Math.round(this.y || 0)?.toLocaleString() +
        //     "</b><br/>"
        //   );
        // },
        formatter: function () {
          let tooltipText = `
            <div style="color: #252f4a; font-size: 14px; font-family: Rubik, sans-serif; font-weight: 500;">
              ${this.x}
            </div><br/>`; // Custom x label styling

          this.points.forEach((point) => {
            tooltipText += `
              <div style="display: flex; align-items: center;">  <div style="color:${
                point.color
              }; font-size: 19px; margin-right: 8px; ">\u25CF</div>
              <span style="color: #252f4a; font-size: 14px;">${
                point.series.name
              }</span> :&nbsp;<span style="color: #252f4a; font-size: 14px; font-weight: 600;">${
              signFind?.[point.series.name] || ""
            }${Math.round(point.y || 0)?.toLocaleString()}</span></div><br/>`;
          });

          return tooltipText;
        },
        shadow: {
          color: "rgba(82, 63, 105, 0.15)", // Shadow color
          offsetX: 0, // Horizontal offset
          offsetY: 0, // Vertical offset
          opacity: 1, // Shadow opacity
          width: 50, // Blur width
        },
        style: {
          fontSize: "14px",
          fontFamily: `'Rubik',sans-serif`,
          fontWeight: 400,
          color: "#656565",
        },
      },
      legend: {
        enabled: false, // Hide legends
      },
    });
  }, [updated_data, selectedGraph]);

  return (
    <div
      id="line-chart"
      style={{ width: "100%", height: "420px" }}
      // ref={chartRef}
    ></div>
  );
};

export default StackedBarLineChart;
