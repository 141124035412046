import {
  Checkbox,
  DatePicker,
  Empty,
  Flex,
  message,
  Modal,
  Segmented,
  Select,
  Spin,
  Table,
  Tabs,
  Tag,
  Watermark,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { WrapperContant } from "./style";

import dayjs from "dayjs";
import moment from "moment";
import { MakeApiCall } from "../../../apis";
import { GlobalContext } from "../../../common-context";
import {
  numberformat,
  NumberWithCommas,
  updatePrevDates,
} from "../../../config";
import CredentialsError from "../../credential-page";
import CardsView from "../business/lib/cards";
import { GraphMetric, ReportData } from "../business/lib/financial";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // Import the plugin
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // Import if needed
import Config from "../business/lib/lib/config";
import LineItemsSales from "../business/lib/line-items-sales";
import { Wrapper } from "../business/style";
import { PremiumContent } from "../premium-content";
import StackedBarLineChart from "./StackedBarLineChart";
import LineItems from "./line-items";

dayjs.extend(isSameOrAfter); // Extend dayjs with the plugin
dayjs.extend(isSameOrBefore); // Extend if required
const AdvertisingDashboard = ({ menusList, pageTitle }) => {
  const [selectedTab, setSelectedTab] = useState("Month");
  const contextValue = useContext(GlobalContext);
  const [date, setDate] = useState(dayjs());
  const [metricSettingList, setMetricSettingList] = useState([]);
  const [metricSettingLoading, setMetricSettingLoading] = useState(true);

  const [viewConfig, setViewConfig] = useState(false);
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [metricGraphLoading, setMetricGraphLoading] = useState(true);
  const [tabelLoading, setTabelLoading] = useState(true);
  const [convertedData, setConvertedData] = useState({
    salesData: [],
    salasTotal: {},
    costTotal: {},
    costData: [],
  });
  const [visible, setVisible] = useState(false);
  const [reportingData, setReportingData] = useState({});
  const [reportingDataLoading, setReportingDataLoading] = useState(true);
  const [filters, setFilters] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [filtersPremiumContent, setFiltersPremiumContent] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [performanceTracker, setPerformanceTracker] = useState({});
  const [performanceTrackerLoading, setPerformanceTrackerLoading] =
    useState(true);
  const [selection_type, setSelection_type] = useState("month");
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [snapshotData, setSnapshotData] = useState({});
  const [snapshotLoading, setSnapshotLoading] = useState(true);
  const [overviewFilter, setOverviewFilter] = useState(
    dayjs().subtract(1, "week").startOf("week")?.format("YYYY-MM-DD")
  );
  const [cardList, setCardList] = useState([]);
  const [selectedCard, setSelectedCard] = useState([
    "unitsSold",
    "metric_chart_data",
    "Units Sold",
  ]);
  const [overviewData, setOverviewData] = useState({});
  const [graphLoading, setGraphLoading] = useState(true);
  const [summaryType, setSummaryType] = useState("");
  const [graphData, setGraphData] = useState({});
  const [graphLabels, setGraphLabels] = useState({});
  const [selectedGraph, setSelectedGraph] = useState([]);
  const [filterList, setFilterList] = useState({});
  const [filterListLoading, setFilterListLoading] = useState(true);
  const [selectedFilterTab, setSelectedFilterTab] = useState({
    ad_product: null,
    sip_targeting_type: null,
    sip_medium: null,
    sip_destination: null,
    sip_placement: null,
  });
  const [advertisingPerformance, setAdvertisingPerformance] = useState([]);
  const [advertisingPerformanceLoading, setAdvertisingPerformanceLoading] =
    useState(true);
  const [advertisingPerformanceLabel, setAdvertisingPerformanceLabel] =
    useState({});

  const [advertisingPerformanceLineItems, setAdvertisingPerformanceLineItems] =
    useState({
      start_date: null,
      end_date: null,
      metric_name: null,
      ad_product: null,
      sip_targeting_type: null,
      sip_medium: null,
      sip_destination: null,
      sip_placement: null,
    });
  const [advertisingPerformanceLineView, setAdvertisingPerformanceLineView] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [getAllLineItemList, setGetAllLineItemList] = useState([]);
  const [lineItemListLoading, setLineItemListLoading] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState([]);
  const [filtersLine, setFiltersLine] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [selectedRangeLine, setSelectedRangeLine] = useState("This Month");
  const [titles, setTitles] = useState({});
  const [urlChange, setUrlChange] = useState("");

  const borderColor = {
    1: "#E31715",
    2: "#FDC71E",
    3: "#00B55D",
  };
  const backgroundColor = {
    1: "#FFF6F6",
    2: "#FFFEF6",
    3: "#F6FFFA",
  };
  const [dateFilter, setDateFilter] = useState(
    dayjs(getWeekDates(dayjs().format("YYYY-MM-DD"))?.start, "YYYY-MM-DD")
      ?.subtract(1, "day")
      ?.format("YYYY-MM-DD")
  );

  const SellerStatus = [
    {
      title: "Paid Sales",

      key: "paid_sales",
      subTitle: "Units Sold",

      subKey: "units_sold",
      suffix: "",
      subSuffix: "",
      prefix: "$",
      subPrefix: "",
    },
    {
      title: "Advertising Cost",
      key: "advertising_cost",

      suffix: "",
      prefix: "$",
    },
    {
      title: "ACOS",
      key: "acos",

      suffix: "%",
      prefix: "",
    },
    {
      title: "ROAS",
      key: "roas",

      suffix: "",
      prefix: "",
    },

    {
      title: "TACOS",
      key: "tacos",

      suffix: "%",
      prefix: "",
    },
    {
      title: "Avg CPA",
      key: "avg_cpa",

      suffix: "",
      prefix: "$",
    },
    // {
    //   title: "Avg CPAS",
    //   key: "avg_cpas",

    //   suffix: "",
    //   prefix: "$",
    // },
    {
      title: "Impressions",

      key: "impressions",
      subTitle: "Share",
      subKey: "impression_share",

      suffix: "",
      subSuffix: "%",
      prefix: "",
      subPrefix: "",
    },
    {
      title: "Clicks",
      key: "clicks",

      suffix: "",
      prefix: "",
    },
    {
      title: "Avg CTR",
      key: "avg_ctr",

      suffix: "%",
      prefix: "",
    },
    {
      title: "Avg CPC",
      key: "avg_cpc",

      suffix: "",
      prefix: "$",
    },
    {
      title: "Daily Spends",
      key: "daily_spends",

      suffix: "",
      prefix: "$",
    },
    {
      title: "Avg Sale Value",
      key: "avg_sale_value",

      suffix: "",
      prefix: "$",
    },
    // {
    //   title: "Avg Ad Sale Value",
    //   key: "avg_ad_sale_value",
    //   suffix: "",
    //   prefix: "$",
    // },
  ];
  const getBorderColor = (value) => {
    if (value == 0 || value === "STAGNANT") {
      return 2;
    }
    if (value === "DOWN") {
      return 1;
    }
    if (value === "UP") {
      return 3;
    }
    return 3;
  };
  const graphDataFiltered =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.metric_value
    ) || [];
  const graphDataFilteredEma_value =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.ema_value
    ) || [];
  function formatNumberWithCommas(number) {
    return number?.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }
  const numberformat_ = (num) => {
    // Format number with no decimals
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const objTypes = {
    Destination: "sip_destination",
    Medium: "sip_medium",
    Targeting: "sip_targeting_type",
    Placement: "sip_placement",
  };
  const minGraph = Math.min(
    ...([...graphDataFiltered, ...graphDataFilteredEma_value] || [])
  );
  const maxGraph = Math.max(
    ...([...graphDataFiltered, ...graphDataFilteredEma_value] || [])
  );
  const revenu_status = {
    chart: {
      height: 355,

      type: "area",
      toolbar: { show: !1 },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    colors: [
      overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
      borderColor?.[
        getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
      ] === "#E31715"
        ? "#00B55D"
        : overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
          borderColor?.[
            getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
          ] === "#00B55D"
        ? "#E31715"
        : borderColor?.[
            getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
          ],

      "#000000",
      "#3c71b8",
    ],
    markers: {
      size: [2, 0],
      colors: "#FFF",
      strokeColors: [
        overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
        borderColor?.[
          getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
        ] === "#E31715"
          ? "#00B55D"
          : overviewData?.[selectedCard?.root]?.is_inverse === 1 &&
            borderColor?.[
              getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
            ] === "#00B55D"
          ? "#E31715"
          : borderColor?.[
              getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
            ],

        "#3c71b8",
      ],
      hover: {
        size: 5,
      },
    },
    xaxis: {
      tickAmount: 7,
      offsetX: 10,
      labels: {
        rotateAlways: -45,
        style: {
          fontWeight: "bold",
        },
      },
    },
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: "smooth",
      width: [3, 3],
      dashArray: [0, 4],
      lineCap: "round",
    },

    labels:
      overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
        (d) => d?.date || d?.weekNum || d?.weekDate || d?.chartDate
      ) || [],
    yaxis: [
      {
        seriesName: "",
        tickAmount: 7,
        axisTicks: {
          show: true,
        },
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
      {
        seriesName: "EMA",
        tickAmount: 7,
        opposite: true,
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        axisTicks: {
          show: true,
        },

        title: {
          text: "Path",
          style: {
            fontWeight: 700,
            color: "#065ad8",
            fontSize: "1.15rem",
          },
        },
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
    ],
    grid: {
      strokeDashArray: 3,
      yaxis: { lines: { show: !1 } },
      xaxis: {
        lines: {
          show: true,
          offsetX: 0,
        },
      },
    },

    legend: { show: !0 },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.05,
        opacityTo: 0.05,
        stops: [45, 100],
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const label = w?.globals?.categoryLabels?.[value - 1] || "";
          return `<strong>${label}</strong>`;
        },
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const prefix = selectedCard?.prefix || "";
          const suffix = selectedCard?.suffix || "";

          return signConvert(
            prefix +
              numberformat(parseFloat(parseFloat(value || 0).toFixed(2))) +
              suffix
          );
        },
      },
    },
  };

  const getAllLineItemsList = async (data, url) => {
    setSelectedMetric(data?.metric_name);
    setSummaryType(data?.summary_type);
    setLineItemListLoading(true);
    try {
      const response = await MakeApiCall(
        `${url || "ads/line-items"}?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${data?.end_date || filters?.end_date || ""}&metric_name=${
          typeof data?.metric_name === "string"
            ? data?.metric_name
            : data?.metric_name?.join(",")
        }${
          data?.summary_type ? `&metric_type=${data?.summary_type || ""}` : ""
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setGetAllLineItemList(response?.data || []);

        setLineItemListLoading(false);
      } else {
        setGetAllLineItemList([]);
        setLineItemListLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLineItemListLoading(false);

      message.destroy();
      message.error(error?.message);
    }
  };
  const getUserReportingPeriod = async () => {
    setReportingDataLoading(true);
    const response = await MakeApiCall(
      `user/reporting-period`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setReportingData(response?.data);

      setReportingDataLoading(false);
    } else {
      setReportingDataLoading(false);
      message.warning(response?.message);
    }
  };

  const getMetricSetting = async (data) => {
    setMetricSettingLoading(true);
    const response = await MakeApiCall(
      `metric-setting?menu_item_id=32`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const cardList__ = Object.entries(data || {})?.reduce((acc, [d, v]) => {
        return [
          ...acc,
          {
            label: v?.metric_name,
            root: d,
            sign: v?.metric_unit || "",
            id: v?.metric_id,
          },
        ];
      }, []);

      if (response?.data.length === 0) {
        setCardList(cardList__.slice(0, 8));
      } else {
        const metricId = response?.data
          .filter(
            (d) =>
              parseInt(d?.status) === 1 &&
              parseInt(d?.master_metric_status) === 1
          )
          .map((d) => parseInt(d?.metric_id));
        const obj = cardList__.filter((item) => metricId.includes(item.id));

        setCardList(obj);

        setSelectedCard([obj?.[0]?.root, obj?.[0]?.label, obj?.[0]?.sign]);
        setOverviewLoading(false);
      }
      setMetricSettingList(
        response?.data?.map((r) => ({ ...r, menu_item_id: 32 }))
      );

      setMetricSettingLoading(false);
      setOverviewLoading(false);
    } else {
      setOverviewLoading(false);
      setMetricSettingLoading(false);
      message.warning(response?.message);
    }
  };
  const saveMetricSetting = async (data) => {
    setMetricSettingLoading(true);
    const response = await MakeApiCall(
      `metric-setting/save`,
      "post",
      data,
      true
    );

    if (response?.status) {
      getOverview();
      setViewConfig(false);
    } else {
      getOverview();

      message.warning(response?.message);
    }
  };
  const getOverview = async (data) => {
    setOverviewLoading(true);
    getMetricSetting();
    setOverviewLoading(true);
    const response = await MakeApiCall(
      `ads/overview?filter_date=${
        data || overviewFilter || ""
      }&menu_item_id=32`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (Object.keys(response?.data || {})?.length === 0) {
        setOverviewData({});
        setCardList([]);
        setSelectedCard({});
        setOverviewLoading(false);
        setMetricGraphLoading(false);
        return;
      }

      const cardList__ = Object.entries(response?.data || {})?.reduce(
        (acc, [d, v]) => {
          return [
            ...acc,
            {
              label: v?.metric_name,
              root: d,
              id: v?.metric_id,
              metric_slug: v?.metric_slug,
              prefix:
                v?.metric_unit === "Range$" || v?.metric_unit === "$"
                  ? "$"
                  : "",
              suffix:
                v?.metric_unit === "Range%" || v?.metric_unit === "%"
                  ? "%"
                  : "",
            },
          ];
        },
        []
      );

      setCardList(cardList__);
      setSelectedCard({
        ...cardList__?.[0],
      });
      setMetricGraphLoading(false);
      setOverviewData(response?.data);
      setOverviewLoading(false);
    } else {
      setOverviewData({});
      setCardList([]);
      setSelectedCard({});
      setOverviewLoading(false);
      setMetricGraphLoading(false);
      message.warning(response?.message);
    }
  };
  const showMetrix = () => {
    if (metricSettingList?.length === 0) {
      message.destroy();
      return message.warning("Config List is not available");
    }
    setViewConfig(true);
  };

  const GraphAction = async (data) => {
    setGraphLoading(true);
    const response = await MakeApiCall(
      `ads/chart-data?start_date=${
        data?.start_date || filters?.start_date || ""
      }&end_date=${data?.end_date || filters?.end_date || ""}&selection_type=${
        data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
        selectedRange?.split(" ")?.join("_")?.toLowerCase()
      }&menu_item_id=32`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setGraphLabels(response?.data?.chartLabel || {});
      setSelectedGraph(Object.values(response?.data?.chartLabel || {}));
      const data = {
        ...response?.data,
        chartData: Object.entries(response?.data?.chartData || {})?.reduce(
          (acc, d, i) => ({
            ...acc,
            [d[0]]: Object.values(d?.[1] || {}),
          }),
          {}
        ),
      };
      setGraphData(data);
      setGraphLoading(false);
    } else {
      setGraphLoading(false);
      message.warning(response?.message);
    }
  };
  const AdsPremiumContent = async (data) => {
    setAdvertisingPerformance([]);
    setAdvertisingPerformanceLabel({});
    setAdvertisingPerformanceLoading(true);
    const response = await MakeApiCall(
      `ads/premium-content?ad_product=${
        data?.ad_product || ""
      }&sip_targeting_type=${data?.sip_targeting_type || ""}&sip_medium=${
        data?.sip_medium || ""
      }&sip_destination=${data?.sip_destination || ""}&sip_placement=${
        data?.sip_placement || ""
      }&start_date=${data?.start_date || ""}&end_date=${data?.end_date || ""}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setAdvertisingPerformanceLabel(response?.data?.label || {});
      setAdvertisingPerformance(response?.data?.content?.reverse() || []);
      setAdvertisingPerformanceLoading(false);
    } else {
      setAdvertisingPerformanceLoading(false);
      setAdvertisingPerformance([]);
      setAdvertisingPerformanceLabel({});
      message.warning(response?.message);
    }
  };
  const AdsPremiumFilter = async () => {
    setFilterList({});
    setFilterListLoading(true);
    const response = await MakeApiCall(`ads/premium-filter`, "get", null, true);
    if (response?.status) {
      setFilterListLoading(false);
      const obj = {
        ad_product: "SPONSORED_PRODUCTS",
        sip_targeting_type: null,
        sip_medium: null,
        sip_destination: null,
        sip_placement: null,
      };
      AdsPremiumContent({
        ...obj,
        ...filtersPremiumContent,
      });
      setSelectedFilterTab(obj);
      setFilterList(response?.data || {});
    } else {
      setFilterList({});
      setFilterListLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getOverview();
    AdsPremiumFilter();

    return () => {};
  }, []);

  const columns = {
    week: [
      {
        title: "",
        width: 200,
        dataIndex: "key",
        key: "key",
      },
      ...(performanceTracker?.columnsList || [])
        ?.map((d, i) => ({
          title: d,
          align: "center",
          dataIndex: `timeline_${i + 1}`,
          key: `timeline_${i + 1}`,
        }))
        ?.reverse(),
    ],
    month: [
      {
        title: "",
        dataIndex: "key",
        width: 200,
        key: "key",
      },
      ...(performanceTracker?.columnsList || [])
        ?.map((d, i) => ({
          title: d,
          align: "center",
          dataIndex: `timeline_${i + 1}`,
          key: `timeline_${i + 1}`,
        }))
        ?.reverse(),
    ],
  };

  const errorIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2927 5.49996C12.7119 5.49996 12.945 5.98493 12.6831 6.31231L8.39011 11.6786C8.18995 11.9288 7.8094 11.9288 7.60924 11.6786L3.31622 6.31231C3.05431 5.98493 3.2874 5.49996 3.70665 5.49996L12.2927 5.49996Z"
        fill="#E31715"
      />
    </svg>
  );

  const successIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70633 11.5C3.28708 11.5 3.05399 11.015 3.31589 10.6877L7.60891 5.32138C7.80908 5.07117 8.18962 5.07117 8.38978 5.32138L12.6828 10.6877C12.9447 11.015 12.7116 11.5 12.2924 11.5L3.70633 11.5Z"
        fill="#00B55D"
      />
    </svg>
  );

  const getValueShow = (value) => {
    try {
      if (value == 0) {
        return <b style={{ color: "#000" }}>{value}%</b>;
      } else if (parseFloat(value?.split(",")?.join("")) < 0) {
        return (
          <b style={{ color: "red" }}>
            {errorIcon}
            {value}%
          </b>
        );
      }
      return (
        <b style={{ color: "green" }}>
          {successIcon}
          {value}%
        </b>
      );
    } catch (error) {}
  };
  const getUpdatedPCA = async () => {
    const response = await MakeApiCall(
      `funnel/premium-content-status`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const obj = {
        sp: response?.data?.amazon_connected,
        lws: response?.data?.amazon_ads_connected,
      };
      const user_ = {
        ...JSON.parse(localStorage.getItem("user")),
        pca_connected: response?.data?.pca_connected,
        pca_current_stage: response?.data?.pca_current_stage,
        pca_close_status: response?.data?.pca_close_status,
      };
      localStorage.setItem("user", JSON.stringify(user_));
      contextValue.updateCommonGlobalVal({
        user_: user_,
        credentialsStatus: obj,
      });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getUpdatedPCA();
    }, 10000);
    getUserReportingPeriod();
    return () => {
      clearInterval(interval);
    };
  }, []);
  const PCAUpdate = async (data, message_) => {
    const response = await MakeApiCall(
      `seller/pca-stage/${contextValue?.data?.user_?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
      const obj = {
        sp: response?.data?.amazon_connected,
        lws: response?.data?.amazon_ads_connected,
      };
      const user = {
        ...JSON.parse(localStorage.getItem("user")),
        pca_connected: response?.data?.pca_connected,
        pca_current_stage: response?.data?.pca_current_stage,
        pca_close_status: response?.data?.pca_close_status,
      };
      localStorage.setItem("user", JSON.stringify(user));
      contextValue.updateCommonGlobalVal({
        user_: user,
        credentialsStatus: obj,
      });
      if (message_) {
        Modal.success({
          content: message_,
          centered: true,
        });
      }
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const AdsPerformanceTracker = async (type) => {
    setPerformanceTrackerLoading(true);
    setPerformanceTracker({});
    const response = await MakeApiCall(
      `ads/performance-tracker?selection_type=${type}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      setPerformanceTrackerLoading(false);
      const metrics = ["ACOS", "ROAS", "TACOS"];
      const data = metrics.map((metric) => {
        const row = { key: metric };
        response?.data?.forEach((week, index) => {
          row[`timeline_${index + 1}`] = `${
            week[metric]
              ? NumberWithCommas(
                  parseFloat(parseFloat(week[metric] || 0).toFixed(0))
                )
              : 0
          }${metric !== "ROAS" ? "%" : ""}`;
        });

        return row;
      });

      const columnsList = response?.data?.map((d) => {
        return (
          d?.month_name ||
          `${d?.week_number} ${dayjs(d?.week_start_date, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          )} To ${dayjs(d?.week_end_date, "YYYY-MM-DD").format("MM/DD/YYYY")}`
        );
      });

      setPerformanceTracker({
        tableList: data || [],
        columnsList: columnsList,
      });
    } else {
      message.destroy();
      setPerformanceTrackerLoading(false);
      setPerformanceTracker([]);
      message.warning(response?.message);
    }
  };

  function convertData(one) {
    const outputObject = {};

    const two = Object?.keys(Object.values(one)?.[0])?.map((d) => {
      Object?.keys(one).forEach((key) => {
        const num = Math.round(one?.[key]?.[d] || 0);

        outputObject[key] = num?.toLocaleString("en-US", {
          minimumFractionDigits: 0,
        });
      });

      return {
        type: d,
        ...outputObject,
      };
    });

    return two;
  }

  const get_ads_summary = {
    salesData: [
      "sponsored_products",
      "sponsored_brands",
      "sponsored_brands_video",
      "sponsored_display",
      "sponsored_television",
    ],
    salasTotal: ["total"],
    costTotal: ["total"],
    costData: [
      "sponsored_products",
      "sponsored_brands",
      "sponsored_brands_video",
      "sponsored_display",
      "sponsored_television",
    ],
  };

  const findYear = dayjs(filters?.start_date, "YYYY-MM-DD").format("YYYY");
  const selectedPeriod = `${dayjs(filters?.start_date, "YYYY-MM-DD").format(
    "MMM DD 'YY"
  )} - ${dayjs(filters?.end_date, "YYYY-MM-DD").format("MMM DD 'YY")}`;

  const columnsFilterTop = {
    This_Month: {
      [`This Month`]: "this_month",
      [`%Share`]: "this_month_ratio",
      [`Proj. Month`]: "projected_month",
      ["Last Month"]: "last_month",
      ["%Share  "]: "last_month_ratio",
      ["Variance"]: "variance",
      [`  `]: "variance_pect",
      [`This Month Last Year`]: "this_month_last_year",
      ["%Share "]: "tmly_ratio",
      [`TMLY Variance`]: "tmly_variance",
      [` `]: "tmly_variance_pect",
    },
    custom: {
      [`${selectedPeriod}`]: "selected_range",
      [`%Share`]: "selected_range_ratio",
      [`${moment(
        updatePrevDates({
          endDate: filters?.end_date,
          startDate: filters?.start_date,
        })
      ).format("MMM DD 'YY")} - ${dayjs(filters?.start_date, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("MMM DD 'YY")}`]: "previous_period",
      [`%Share `]: "previous_period_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
    },
    Last_Month: {
      [`Last Month`]: "last_month",
      [`%Share`]: "last_month_ratio",
      [`Previous Month`]: "previous_month",
      [`%Share `]: "previous_month_ratio",
      [`Variance`]: "variance",
      [` `]: "variance_pect",
      [`Last Month Last Year`]: "last_month_last_year",
      [`%Share  `]: "lmly_ratio",
      [`LMLY Variance`]: "lmly_variance",
      [`  `]: "lmly_variance_pect",
    },
    Yesterday: {
      [`Yesterday`]: "yesterday",
      [`%Share`]: "yesterday_ratio",
      "Day Before Yesterday ": "day_before_yesterday",

      [`%Share `]: "day_before_yesterday_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Yesterday Last Year": "yesterday_last_year",
      [`%Share  `]: "yly_ratio",
      "YoY Variance": "yly_variance",
      " Variance Pect": "yly_variance_pect",
    },
    This_Week: {
      [`This Week Actual`]: "this_week",
      [`%Share`]: "this_week_ratio",
      "This Week Proj. ": "projected_week",

      "Last Week": "last_week",
      [`%Share `]: "last_week_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Last Year This Week": "last_year_this_week",
      [`%Share  `]: "lytw_ratio",
      "YoY Variance": "lytw_variance",
      " Variance Pect": "lytw_variance_pect",
    },
    This_Quarter: {
      [`This Quarter Actual`]: "this_quarter",
      [`%Share`]: "this_quarter_ratio",
      "This Quarter Proj.": "projected_quarter",

      "Last Quarter": "last_quarter",
      [`%Share `]: "last_quarter_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "This Quarter Last Year": "this_quarter_last_year",
      [`%Share  `]: "tyql_ratio",
      "YoY Variance": "tqly_variance",
      " Variance Pect": "tqly_variance_pect",
    },
    Last_Year: {
      [`${findYear} Q1`]: "last_year_q1",
      [`%Share `]: "last_year_q1_ratio",
      [`${findYear} Q2`]: "last_year_q2",
      [`%Share  `]: "last_year_q2_ratio",
      [`${findYear} Q3`]: "last_year_q3",
      [`%Share   `]: "last_year_q3_ratio",
      [`${findYear} Q4`]: "last_year_q4",
      [`%Share`]: "last_year_q4_ratio",

      [`${findYear} Total`]: "last_year_total",
    },
    This_Year: {
      [`${findYear} Q1`]: "this_year_q1",
      [`%Share`]: "this_year_q1_ratio",
      [`${findYear} Q2`]: "this_year_q2",
      [`%Share `]: "this_year_q2_ratio",
      [`${findYear} Q3`]: "this_year_q3",
      [`%Share  `]: "this_year_q3_ratio",
      [`${findYear} Q4`]: "this_year_q4",
      [`%Share   `]: "this_year_q4_ratio",
      [`${findYear} Total `]: "this_year_total",
      // [`${previousYear} Total`]: "last_year_total",
      [`This Year Proj. `]: "projected_year",
      [`Variance`]: "variance",
      [`Variance Pect`]: "variance_pect",
    },
  };
  const columnsFilterAdsCost = {
    This_Month: {
      [`This Month`]: "this_month",
      [`ACOS`]: "this_month_acos",
      [`Proj. Month`]: "projected_month",
      ["Last Month"]: "last_month",
      [`ACOS `]: "last_month_acos",
      ["Variance"]: "variance",
      [`  `]: "variance_pect",
      [`This Month Last Year`]: "this_month_last_year",
      [`ACOS  `]: "tmly_acos",
      [`TMLY Variance`]: "tmly_variance",
      [` `]: "tmly_variance_pect",
    },
    custom: {
      [`${selectedPeriod}`]: "selected_range",
      [`ACOS`]: "selected_range_acos",
      [`${moment(
        updatePrevDates({
          endDate: filters?.end_date,
          startDate: filters?.start_date,
        })
      ).format("MMM DD 'YY")} - ${dayjs(filters?.start_date, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("MMM DD 'YY")}`]: "previous_period",
      [`ACOS `]: "previous_period_acos",
      Variance: "variance",
      "Variance Pect": "variance_pect",
    },
    Last_Month: {
      [`Last Month`]: "last_month",
      [`ACOS`]: "last_month_acos",
      [`Previous Month`]: "previous_month",
      [`ACOS `]: "previous_month_acos",
      [`Variance`]: "variance",
      [` `]: "variance_pect",
      [`Last Month Last Year`]: "last_month_last_year",
      [`ACOS  `]: "lmly_acos",
      [`LMLY Variance`]: "lmly_variance",
      [`  `]: "lmly_variance_pect",
    },
    Yesterday: {
      [`Yesterday`]: "yesterday",
      [`ACOS`]: "yesterday_acos",
      "Day Before Yesterday ": "day_before_yesterday",
      [`ACOS `]: "day_before_yesterday_acos",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Yesterday Last Year": "yesterday_last_year",
      [`ACOS  `]: "yly_acos",
      "YoY Variance": "yly_variance",
      " Variance Pect": "yly_variance_pect",
    },
    This_Week: {
      [`This Week Actual`]: "this_week",
      [`ACOS`]: "this_week_acos",
      "This Week Proj. ": "projected_week",
      // [`ACOS `]: "projected_week_ratio",
      "Last Week": "last_week",
      [`ACOS  `]: "last_week_acos",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Last Year This Week": "last_year_this_week",
      [`ACOS   `]: "lytw_acos",
      "YoY Variance": "lytw_variance",
      " Variance Pect": "lytw_variance_pect",
    },
    This_Quarter: {
      [`This Quarter Actual`]: "this_quarter",
      [`ACOS`]: "this_quarter_acos",
      "This Quarter Proj.": "projected_quarter",
      "Last Quarter": "last_quarter",
      [`ACOS  `]: "last_quarter_acos",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "This Quarter Last Year": "this_quarter_last_year",
      [`ACOS `]: "tqly_acos",
      "YoY Variance": "tqly_variance",
      " Variance Pect": "tqly_variance_pect",
    },
    Last_Year: {
      [`${findYear} Q1`]: "last_year_q1",
      [`ACOS`]: "last_year_q1_acos",
      [`${findYear} Q2`]: "last_year_q2",
      [`ACOS `]: "last_year_q2_acos",
      [`${findYear} Q3`]: "last_year_q3",
      [`ACOS  `]: "last_year_q3_acos",
      [`${findYear} Q4`]: "last_year_q4",
      [`ACOS  `]: "last_year_q4_acos",

      [`${findYear} Total`]: "last_year_total",
    },
    This_Year: {
      [`${findYear} Q1`]: "this_year_q1",
      [`ACOS`]: "this_year_q1_acos",
      [`${findYear} Q2`]: "this_year_q2",
      [`ACOS `]: "this_year_q2_acos",
      [`${findYear} Q3`]: "this_year_q3",
      [`ACOS  `]: "this_year_q3_acos",
      [`${findYear} Q4`]: "this_year_q4",
      [`ACOS   `]: "this_year_q4_acos",
      [`${findYear} Total `]: "this_year_total",
      [`This Year Proj. `]: "projected_year",
      [`Variance`]: "variance",
      [`Variance Pect`]: "variance_pect",
    },
  };

  const getList = async (data) => {
    setTabelLoading(true);
    GraphAction(data);
    setConvertedData({
      salesData: [],
      costData: [],
      salasTotal: {},
      costTotal: {},
    });

    try {
      const response = await MakeApiCall(
        `ads/get-summary?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${
          data?.end_date || filters?.end_date || ""
        }&selection_type=${
          data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
          selectedRange?.split(" ")?.join("_")?.toLowerCase()
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setConvertedData({
          salesData: convertData(response?.data?.salesData)?.filter((item) =>
            get_ads_summary?.salesData.includes(item.type)
          ),
          costData: convertData(response?.data?.costData)?.filter((item) =>
            get_ads_summary?.costData.includes(item.type)
          ),
          costTotal: convertData(response?.data?.costData)?.filter((item) =>
            ["total"].includes(item.type)
          )?.[0],
          salasTotal: convertData(response?.data?.salesData)?.filter((item) =>
            ["total"].includes(item.type)
          )?.[0],
        });
        setTabelLoading(false);
      } else {
        setTabelLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setTabelLoading(false);
      setConvertedData({
        salesData: [],
        costData: [],
        salasTotal: {},
        costTotal: {},
      });
      message.destroy();
      message.error(error?.message);
    }
  };

  const AdsPerformanceSnapshot = async (data) => {
    setSnapshotLoading(true);
    setSnapshotData({});
    try {
      const response = await MakeApiCall(
        `ads/performance-snapshot?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${
          data?.end_date || filters?.end_date || ""
        }&selection_type=${
          data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
          selectedRange?.split(" ")?.join("_")?.toLowerCase()
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setSnapshotLoading(false);
        setSnapshotData(response?.data || {});
      } else {
        setSnapshotLoading(false);
        setSnapshotData({});
        message.warning(response?.message);
      }
    } catch (error) {
      setSnapshotLoading(false);
      setSnapshotData({});
      message.destroy();
      message.error(error?.message);
    }
  };

  const typeGraph = {
    cost_sponsored_brands: "line",
    cost_sponsored_brands_video: "line",
    cost_sponsored_display: "line",
    cost_sponsored_products: "line",
    cost_sponsored_television: "line",
    sales_sponsored_brands: "bar",
    sales_sponsored_brands_video: "bar",
    sales_sponsored_display: "bar",
    sales_sponsored_products: "bar",
    sales_sponsored_television: "bar",
  };
  const colorList = [
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
  ];

  const series = Object?.entries(graphData?.chartData || {})?.map(
    ([key, value], i) => {
      return {
        name: graphLabels?.[key],
        type: typeGraph?.[key],
        key: key,
        data: value?.map((d) => Math.round(d || 0)),
        color: colorList?.map((r) => r?.key)?.[i],
        // yAxis: typeGraph?.[key] === "line" ? 1 : 0,
        // sign: findSign?.[key],
        // suffix: findSign?.[key] === "%" ? "%" : "",
      };
    }
  );

  useEffect(() => {
    setTabelLoading(true);
    getList();
    AdsPerformanceSnapshot();

    AdsPerformanceTracker(selection_type);
    return () => {};
  }, []);

  const items = [
    {
      key: "SPONSORED_PRODUCTS",
      label: "Sponsored Products",
    },
    {
      key: "SPONSORED_BRANDS",
      label: "Sponsored Brands",
    },
    {
      key: "SPONSORED_BRANDS_VIDEO",
      label: "Sponsored Brands Video",
    },
    {
      key: "SPONSORED_DISPLAY",
      label: "Sponsored Display",
    },
  ];

  const a = "100";
  const l = "#F1F1F2";
  const green = "#50cd89";
  const yellow = "#ffc803";
  const red = "#f1416c";
  const optionsComman = {
    chart: {
      fontFamily: "Montserrat",
      type: "area",
      height: a,
      toolbar: { show: !1 },
    },
    legend: { show: !1 },
    dataLabels: { enabled: !1 },
    fill: { type: "solid", opacity: 0 },
    stroke: { curve: "smooth", show: !0, width: 2 },
    xaxis: {
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      labels: { show: !1 },
      crosshairs: {
        position: "front",
        stroke: { width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    yaxis: { labels: { show: !1 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: {
      enabled: false,
      style: { fontSize: "12px" },
      x: {
        formatter: function (e) {
          return "Feb " + e;
        },
      },
      y: {
        formatter: function (e) {
          return "$" + e + "K";
        },
      },
    },

    grid: {
      borderColor: l,
      strokeDashArray: 4,
      padding: { top: 0, right: -20, bottom: -20, left: -20 },
      yaxis: { lines: { show: !0 } },
    },
    markers: {
      size: 2,
      colors: "#FFF",

      hover: {
        size: 3,
      },
    },
  };

  const updateChartOptions = (color) => {
    return {
      ...optionsComman,
      stroke: { ...optionsComman.stroke, colors: [color] },
      colors: [color],
      markers: {
        ...optionsComman.markers,
        strokeColors: color,
      },
      xaxis: {
        ...optionsComman?.xaxis,
        crosshairs: {
          ...optionsComman?.xaxis.crosshairs,
          stroke: {
            ...optionsComman?.xaxis.crosshairs.stroke,
            colors: [color],
          },
        },
      },
    };
  };

  const getOp = (value) => {
    switch (value) {
      case 0:
      case "STAGNANT":
        return updateChartOptions(yellow);
      case "DOWN":
        return updateChartOptions(red);
      case "UP":
        return updateChartOptions(green);
      default:
        return updateChartOptions(green); // or any default color
    }
  };
  const getFooter = (value) => {
    if (value == 0 || value === "Flat") {
      return 2;
    }
    if (value === "FLAT") {
      return 2;
    }
    if (value === "Falling") {
      return 1;
    }
    if (value === "FALLING") {
      return 1;
    }
    if (value === "Rising") {
      return 3;
    }
    if (value === "RISING") {
      return 3;
    }
    return 3;
  };
  const getArrowColor = (value) => {
    if (!value || value == 0 || value === "Flat") {
      return "rotateX(92deg)";
    }
    if (value === "FLAT") {
      return "rotateX(92deg)";
    }
    if (value === "Falling") {
      return "rotateX(180deg)";
    }
    if (value === "FALLING") {
      return "rotateX(180deg)";
    }
    if (value === "Rising") {
      return "rotateX(0deg)";
    }
    if (value === "RISING") {
      return "rotateX(0deg)";
    }
    return "rotateX(0deg)";
  };

  const presetRanges_ = [
    // {
    //   label: "Yesterday",
    //   value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    // },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs()],
    },
    {
      label: "Last Week",
      value: [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "week").endOf("week"),
      ],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs()],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs()],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];

  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });

  const handleDateRangeChange = (value, dateString) => {
    const selectedPresetRange = presetRanges.filter((range) => {
      return (
        dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
        dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
      );
    });

    const date1 = new Date(dateString?.[0]);
    const date2 = new Date(dateString?.[1]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    setSelectedRange(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    // setSelectedRangeLine(
    //   selectedPresetRange?.length !== 0
    //     ? selectedPresetRange?.[0]?.label
    //     : "custom"
    // );
    return selectedPresetRange?.length !== 0
      ? selectedPresetRange?.[0]?.label
      : "custom";
  };
  const ratioView = (key) => {
    return (
      key === "_" ||
      key === "__" ||
      key === "___" ||
      key === "____" ||
      key === "Avg CR"
    );
  };
  const signConvert = (value) => {
    return value.replace("$-", "-$");
  };
  const restart = true;
  const signFind = {
    salesData: "$",
    grossProfitData: "$",
    gpMarginData: "%",
    expensesData: "$",
    totalProfitData: "$",
    netMarginData: "%",
    refundData: "$",
  };
  function getWeekDates(date) {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - dayOfWeek);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    return {
      start: startDate.toISOString().split("T")[0],
      end: endDate.toISOString().split("T")[0],
    };
  }

  const lastGraph = {
    series: series,
    options: {
      chart: {
        height: 350,

        type: "line",
        toolbar: { show: !1 },
      },
      stroke: {
        width: [0, 0, 0, 3, 3, 3, 3],
        curve: "smooth",
      },
      legend: {
        show: false,
        position: "left", // Change legend position to left
      },
      colors: series?.map((d) => d?.color),
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return `$${numberformat(parseFloat(value).toFixed(0))}`;
          },
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        marker: {
          show: true,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            const seriesName = lastGraph?.series?.[seriesIndex]?.name;
            const fSign = seriesName?.includes("Sales") ? "$" : "";
            const sSign = "";
            return (fSign + (value || 0) + sSign).replace("$-", "-$");
          },
        },
      },
      xaxis: {
        categories: graphData?.chartDate || [],
        tickPlacement: "on",

        labels: {
          // formatter: function (value, timestamp) {
          //   return dayjs(value, "MMM-DD").date() % 2 !== 0 ? value : "";
          // },
          rotate: -45,
          style: {
            fontSize: "12px",
            transform: "rotate(-45deg)",
          },
        },
      },
    },
  };

  const icons = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      style={{
        position: "relative",
        top: "-4px",
        left: "0.5px",
      }}
    >
      <path
        d="M3.75 11.125L6.875 8L3.75 4.875M8.125 11.125L11.25 8L8.125 4.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const { lws, sp } = contextValue?.data?.credentialsStatus;
  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "ads-360")?.is_restricted === 1
  ) {
    return <CredentialsError type="ads-360" />;
  }
  if (advertisingPerformanceLineView) {
    return (
      <LineItems
        filters={advertisingPerformanceLineItems}
        onClose={() => {
          setAdvertisingPerformanceLineItems({
            start_date: null,
            end_date: null,
            metric_name: null,
            ad_product: null,
            sip_targeting_type: null,
            sip_medium: null,
            sip_destination: null,
            sip_placement: null,
          });
          setAdvertisingPerformanceLineView(false);
        }}
        setAdvertisingPerformanceLineItems={setAdvertisingPerformanceLineItems}
        open={advertisingPerformanceLineView}
        filterListLoading={filterListLoading}
        filterList={filterList}
        selectedFilterTab={selectedFilterTab}
        setSelectedFilterTab={setSelectedFilterTab}
        AdsPremiumContent={(e) =>
          AdsPremiumContent({ ...e, ...filtersPremiumContent })
        }
        objTypes={objTypes}
      />
    );
  }
  if (Object.keys(selectedRow || {})?.length !== 0) {
    return (
      <LineItemsSales
        close={() => {
          setSelectedRow(false);
          setUrlChange("");
        }}
        selectedRow={selectedRow}
        getAllLineItemList={getAllLineItemList}
        loading={lineItemListLoading}
        selectedMetric={selectedMetric}
        APIAll={getAllLineItemsList}
        setFiltersLine={setFiltersLine}
        filtersLine={filtersLine}
        selectedRangeLine={selectedRangeLine}
        setSelectedRangeLine={setSelectedRangeLine}
        endPoint={urlChange || "ads/line-items"}
        summaryType={summaryType}
        titles={titles}
        page="Ads 360"
      />
    );
  }

  return (
    <WrapperContant
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="row">
        <div className="header-title-page">
          <div className="d-flex align-items-center title-text">
            <h2 className="mb-0">{pageTitle}</h2>{" "}
          </div>
        </div>
      </div>

      <div id="kt_app_toolbar" className="app-toolbar  ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div
            style={{
              justifyContent: viewConfig ? "flex-start" : "space-between",
              width: "100%",
            }}
            className="mb-6 d-flex top-bar-page-header mt-4"
          >
            <div className="page-header-info  d-flex align-items-center ">
              <div className="discription-text">
                Ads 360 Metrics and Trends for the week of{" "}
                {dayjs(
                  getWeekDates(overviewFilter)?.start,
                  "YYYY-MM-DD"
                ).format("MM/DD/YYYY")}{" "}
                to{" "}
                {dayjs(getWeekDates(overviewFilter)?.end, "YYYY-MM-DD").format(
                  "MM/DD/YYYY"
                )}
              </div>
              &nbsp;
              <div className="d-flex align-items-center">
                Select Week&nbsp;
                <DatePicker
                  className="w-150px custom-date"
                  onChange={(e) => {
                    getOverview(dayjs(e).format("YYYY-MM-DD"));
                    setOverviewFilter(dayjs(e).format("YYYY-MM-DD"));
                  }}
                  disabledDate={(current) => {
                    // Disable the current week and future weeks
                    const startOfWeek = current?.startOf("week");
                    const endOfWeek = current?.endOf("week");
                    const today = dayjs();
                    return (
                      current &&
                      (endOfWeek.isSameOrAfter(today, "day") ||
                        startOfWeek.isSame(today.startOf("week"), "week"))
                    );
                  }}
                  allowClear={false}
                  value={dayjs(overviewFilter, "YYYY-MM-DD")}
                  picker="week"
                  format={"YYYY-MMM-DD"}
                />
              </div>
            </div>

            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 20,
                display: "flex",
              }}
            >
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 6,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 13,
                    height: 13,
                    background: "#00B55D",
                    borderRadius: 9999,
                  }}
                />
                <div
                  style={{
                    color: "#00B55D",
                    fontSize: 14,

                    fontWeight: "500",

                    wordWrap: "break-word",
                  }}
                >
                  Growth
                </div>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 6,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 13,
                    height: 13,
                    background: "#FDC71E",
                    borderRadius: 9999,
                  }}
                />
                <div
                  style={{
                    color: "#FDC71E",
                    fontSize: 14,
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  Stagnant
                </div>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 6,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 13,
                    height: 13,
                    background: "#E31715",
                    borderRadius: 9999,
                  }}
                />
                <div
                  style={{
                    color: "#E31715",
                    fontSize: 14,
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  Decline
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Wrapper>
        <div
          className="row g-5 g-xl-5 Financial-cus-row"
          style={{
            zoom: "90%",
          }}
        >
          <div className=" col-xl-8 mb-5 mb-xl-5 mh-450px overflow-auto">
            <CardsView
              cardList={cardList}
              type="W"
              setSelectedCard={setSelectedCard}
              backgroundColor={backgroundColor}
              getBorderColor={getBorderColor}
              overviewData={overviewData}
              borderColor={borderColor}
              signConvert={signConvert}
              getOp={getOp}
              getFooter={getFooter}
              getArrowColor={getArrowColor}
              selectedCard={selectedCard}
              overviewLoading={false}
              setCardList={setCardList}
              setMetricGraphLoading={setMetricGraphLoading}
            />
          </div>
          {GraphMetric(
            selectedCard,
            borderColor,
            getBorderColor,
            overviewData,
            signFind,
            restart,
            revenu_status,
            getArrowColor,
            getFooter,
            showMetrix,
            overviewLoading,
            metricGraphLoading
          )}
        </div>
      </Wrapper>

      <div id="kt_app_toolbar" className="app-toolbar mb-2 mt-5 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-row justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading me-3 d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Performance Tracker
            </h1>
            <Segmented
              options={[
                {
                  label: "Week",
                  value: "week",
                },
                {
                  label: "Month",
                  value: "month",
                },
              ]}
              value={selection_type}
              onChange={(value) => {
                AdsPerformanceTracker(value);
                setSelection_type(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Table
            columns={columns?.[selection_type]}
            dataSource={performanceTracker?.tableList || []}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            loading={performanceTrackerLoading}
            bordered
            size="small"
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
      <div id="kt_app_toolbar" className="app-toolbar mb-0 mt-5 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-row justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading me-3 d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Performance Snapshot
            </h1>
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <Tag color="blue">
              <svg
                width={16}
                height={16}
                fill="#065ad8"
                className="me-2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM11 6h2.4v2.4H11V6Zm0 4.8h2.4V18H11v-7.2Z"
                  clipRule="evenodd"
                />
              </svg>
              Reported data determined by SIP Registration and Amazon
              constraints.{" "}
              <a
                style={{ textDecoration: "underline", color: "#065ad8" }}
                onClick={(e) => setVisible(true)}
              >
                See your reporting period
              </a>
            </Tag>
            <Tag color="blue-inverse" className="me-2">
              {selectedRange === "custom" ? "Selected period" : selectedRange}
            </Tag>
            <DatePicker.RangePicker
              allowClear={false}
              presets={presetRanges}
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              size="middle"
              style={{
                width: "280px",
                border: "1px solid #D7D7D7",
              }}
              format={"YYYY-MMM-DD"}
              value={
                filters?.start_date && filters?.end_date
                  ? [
                      dayjs(filters?.start_date, "YYYY-MM-DD"),
                      dayjs(filters?.end_date, "YYYY-MM-DD"),
                    ]
                  : []
              }
              onChange={(e, _) => {
                const obj = {
                  start_date: e?.[0]
                    ? dayjs(e?.[0]).format("YYYY-MM-DD")
                    : null,
                  end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                };
                getList({
                  ...obj,
                  selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                });
                AdsPerformanceSnapshot({
                  ...obj,
                  selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                });

                setFilters(obj);
              }}
            />
          </div>
        </div>
      </div>
      <Spin spinning={snapshotLoading}>
        <div>
          <div className="row g-3 mb-4 mt-3">
            {SellerStatus.slice(0, 6)?.map((d, i) => (
              <div key={i} className="col">
                <div
                  style={{
                    background: "#FFF",
                    minWidth: "150px",

                    borderRight: i === 6 ? "" : "1px solid #80808036",
                  }}
                  className="card px-0"
                >
                  <div className="card-body px-4 py-4 d-flex align-items-center justify-content-between">
                    <div>
                      <div className=" text-gray-600 fs-5 mt-2 mb-2">
                        {d?.title}
                      </div>
                      <div className="text-gray-900 fw-semibold fs-2 ">
                        {d?.prefix}
                        {formatNumberWithCommas(
                          parseFloat(
                            parseFloat(
                              snapshotData?.snapshotData?.[d?.key] || 0
                            ).toFixed(0)
                          )
                        )}
                        {d?.suffix}
                      </div>
                    </div>
                    <div className="d-grid" style={{ justifyItems: "end" }}>
                      {d?.subTitle && (
                        <>
                          <div className="fs-5 text-primary mt-2 mb-2">
                            {d?.subTitle}
                          </div>
                          <div className="text-gray-700 text-primary fw-semibold fs-4 ">
                            {d?.subPrefix}
                            {formatNumberWithCommas(
                              parseFloat(
                                parseFloat(
                                  snapshotData?.snapshotData?.[d?.subKey] || 0
                                ).toFixed(0)
                              )
                            )}
                            {d?.subSuffix}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row g-3">
            {SellerStatus.slice(-6)?.map((d, i) => (
              <div key={i} className="col">
                <div
                  style={{
                    background: "#FFF",
                    minWidth: "150px",

                    borderRight: i === 6 ? "" : "1px solid #80808036",
                  }}
                  className="card px-0"
                >
                  <div className="card-body px-4  py-4 d-flex align-items-center justify-content-between">
                    <div>
                      <div className=" text-gray-600 fs-5 mt-2 mb-2">
                        {d?.title}
                      </div>
                      <div className="text-gray-900 fw-semibold fs-2 ">
                        {d?.prefix}
                        {formatNumberWithCommas(
                          parseFloat(
                            parseFloat(
                              snapshotData?.snapshotData?.[d?.key] || 0
                            ).toFixed(0)
                          )
                        )}
                        {d?.suffix}
                      </div>
                    </div>
                    <div className="d-grid" style={{ justifyItems: "end" }}>
                      {d?.subTitle && (
                        <>
                          <div className="fs-5 text-primary mt-2 mb-2">
                            {d?.subTitle}
                          </div>
                          <div className="text-gray-700 text-primary fw-semibold fs-4 ">
                            {d?.subPrefix}
                            {formatNumberWithCommas(
                              parseFloat(
                                parseFloat(
                                  snapshotData?.snapshotData?.[d?.subKey] || 0
                                ).toFixed(0)
                              )
                            )}
                            {d?.subSuffix}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Spin>
      <div className="card mt-5">
        <div className="card-header py-0" style={{ minHeight: "40px" }}>
          <div className="card-title">Advertising Performance Summary</div>
        </div>
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
              <thead>
                <tr>
                  <th />
                  {Object?.entries(
                    columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                  )
                    ?.filter((d) => {
                      return selectedTab === "5" && d?.[0] === "%"
                        ? d?.[0] !== "%"
                        : d;
                    })
                    ?.map(([key, value], i) => {
                      return (
                        <th
                          key={i}
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          className={`${key} text-end  pe-3 py-3`}
                        >
                          {key}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                <tr>
                  <td
                    style={{ background: "transparent" }}
                    className="d-flex align-items-center fixed-column justify-content-start"
                  >
                    <h4
                      style={{
                        marginBottom: "0px",
                        color: "#515151",
                        fontWeight: 500,
                      }}
                    >
                      Paid Sales
                    </h4>
                  </td>
                  <td colSpan={9}></td>
                </tr>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>

                {convertedData?.salesData?.map((d, i) => (
                  <tr key={i}>
                    <td
                      className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                      style={{ borderBottom: "1px solid #E5E5E5" }}
                      onClick={() => {
                        getAllLineItemsList({
                          metric_name: convertedData?.salesData?.map(
                            (r) => r.type
                          ),
                          summary_type: "Paid Sales",
                        });
                        setFiltersLine({
                          ...filtersLine,
                          metric_type: "Paid Sales",
                        });

                        setSelectedRow({
                          type: d?.type,
                          columns: "ads_sponsored_products",
                          totalLabel: {
                            label: "Cost",
                            key: "cost",
                          },
                          filterType: "source",
                          title: d?.type?.split("_")?.join(" "),
                        });
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span style={{ textTransform: "capitalize" }}>
                          {d?.type?.split("_")?.join(" ")}
                        </span>
                        <div
                          className="bg-primary ms-2"
                          style={{
                            borderRadius: "100%",
                            postion: "relative",
                            width: "15px",
                            height: "15px",
                            opacity: d.type !== "organic_clicks" ? 1 : 0,
                          }}
                        >
                          {icons}
                        </div>
                      </div>
                    </td>
                    {Object?.entries(
                      columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                    )?.map(([key, value], i) => {
                      return (
                        <td
                          key={i}
                          className="text-end pe-3"
                          style={{
                            borderBottom: `${
                              value?.includes("pr_share") ||
                              value?.includes("month_pre") ||
                              value === "expected_variance_pr"
                                ? "1px solid #00000000"
                                : "1px solid #E5E5E5"
                            }`,
                          }}
                        >
                          {value === "variance_pect" ||
                          value === "tmly_variance_pect"
                            ? getValueShow(d?.[value])
                            : (value?.includes("ratio") ||
                              value?.includes("pect")
                                ? ""
                                : "$") + d?.[value]
                            ? (value?.includes("ratio") ||
                              value?.includes("pect")
                                ? ""
                                : "$") +
                              formatNumberWithCommas(d?.[value]) +
                              (value?.includes("ratio") ||
                              value?.includes("pect")
                                ? "%"
                                : "")
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                ))}

                <tr>
                  <td
                    className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                    style={{
                      borderBottom: "1px solid #E5E5E5",
                      background: "#e4f3ff",
                      color: "#015986",
                      fontWeight: 500,
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <span>Total Paid Sales</span>
                    </div>
                  </td>
                  {Object?.entries(
                    columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                  )?.map(([key, value], i) => {
                    if (ratioView(key)) {
                      return (
                        <td
                          key={i}
                          className="text-end   pe-3"
                          style={{
                            background: "#E4F3FF",
                            padding: "5px 10px 5px 10px",
                            color: "#015986",
                            fontWeight: 500,
                          }}
                        ></td>
                      );
                    }
                    return (
                      <td
                        key={i}
                        className="text-end   pe-3"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontWeight: 500,
                        }}
                      >
                        {convertedData?.salasTotal?.[value] == 0 ||
                        !convertedData?.salasTotal?.[value]
                          ? "-"
                          : value === "variance_pect" ||
                            value === "tmly_variance_pect"
                          ? getValueShow(
                              convertedData?.salasTotal?.[value] || "0"
                            )
                          : `$${signConvert(
                              `${convertedData?.salasTotal?.[value]}`
                            )}`}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive mt-5">
            <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
              <thead>
                <tr>
                  <th />
                  {Object?.entries(
                    columnsFilterAdsCost?.[selectedRange?.split(" ")?.join("_")]
                  )
                    ?.filter((d) => {
                      return selectedTab === "5" && d?.[0] === "%"
                        ? d?.[0] !== "%"
                        : d;
                    })
                    ?.map(([key, value], i) => {
                      return (
                        <th
                          key={i}
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          className={`${key} text-end  pe-3 py-3`}
                        >
                          {key}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                <tr>
                  <td
                    style={{ background: "transparent" }}
                    className="d-flex align-items-center fixed-column justify-content-start"
                  >
                    <h4
                      style={{
                        marginBottom: "0px",
                        color: "#515151",
                        fontWeight: 500,
                      }}
                    >
                      Advertising Cost
                    </h4>
                  </td>
                  <td colSpan={9}></td>
                </tr>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                {convertedData?.costData?.map((d, i) => (
                  <tr key={i}>
                    <td
                      className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                      style={{ borderBottom: "1px solid #E5E5E5" }}
                      onClick={() => {
                        getAllLineItemsList({
                          metric_name: convertedData?.costData?.map(
                            (r) => r.type
                          ),
                          summary_type: "Advertising Cost",
                        });
                        setFiltersLine({
                          ...filtersLine,
                          metric_type: "Advertising Cost",
                        });

                        setSelectedRow({
                          type: d?.type,
                          columns: "ads_avd_sponsored_products",
                          filterType: "source",
                          title: d?.type?.split("_")?.join(" "),
                          totalLabel: {
                            label: "Cost",
                            key: "cost",
                          },
                        });
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span style={{ textTransform: "capitalize" }}>
                          {d?.type?.split("_")?.join(" ")}
                        </span>
                        <div
                          className="bg-primary ms-2"
                          style={{
                            borderRadius: "100%",
                            postion: "relative",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          {icons}
                        </div>
                      </div>
                    </td>
                    {Object?.entries(
                      columnsFilterAdsCost?.[
                        selectedRange?.split(" ")?.join("_")
                      ]
                    )?.map(([key, value], i) => {
                      return (
                        <td
                          key={i}
                          className="text-end pe-3"
                          style={{
                            borderBottom: `${
                              value?.includes("pr_share") ||
                              value?.includes("month_pre") ||
                              value === "expected_variance_pr"
                                ? "1px solid #00000000"
                                : "1px solid #E5E5E5"
                            }`,
                          }}
                        >
                          {value === "variance_pect" ||
                          value === "tmly_variance_pect"
                            ? getValueShow(d?.[value])
                            : (value?.includes("ratio") ||
                              value?.includes("acos") ||
                              value?.includes("pect")
                                ? ""
                                : "$") + d?.[value]
                            ? (value?.includes("ratio") ||
                              value?.includes("acos") ||
                              value?.includes("pect")
                                ? ""
                                : "$") +
                              formatNumberWithCommas(d?.[value]) +
                              (value?.includes("ratio") ||
                              value?.includes("acos") ||
                              value?.includes("pect")
                                ? "%"
                                : "")
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                ))}

                <tr>
                  <td
                    className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                    style={{
                      borderBottom: "1px solid #E5E5E5",
                      background: "#e4f3ff",
                      color: "#015986",
                      fontWeight: 500,
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <span>Total Advertising Costs</span>
                    </div>
                  </td>
                  {Object?.entries(
                    columnsFilterAdsCost?.[selectedRange?.split(" ")?.join("_")]
                  )?.map(([key, value], i) => {
                    if (ratioView(key)) {
                      return (
                        <td
                          key={i}
                          className="text-end   pe-3"
                          style={{
                            background: "#E4F3FF",
                            padding: "5px 10px 5px 10px",
                            color: "#015986",
                            fontWeight: 500,
                          }}
                        ></td>
                      );
                    }

                    return (
                      <td
                        key={i}
                        className="text-end   pe-3"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontWeight: 500,
                        }}
                      >
                        {convertedData?.costTotal?.[value] == 0 ||
                        !convertedData?.costTotal?.[value]
                          ? "-"
                          : value === "variance_pect" ||
                            value === "tmly_variance_pect"
                          ? getValueShow(
                              convertedData?.costTotal?.[value] || "0"
                            )
                          : `${
                              value?.includes("acos")
                                ? ""
                                : value === "this_month_acos"
                                ? ""
                                : "$"
                            }${signConvert(
                              `${convertedData?.costTotal?.[value]}`
                            )}${
                              value === "this_month_acos" ||
                              value?.includes("acos")
                                ? "%"
                                : ""
                            }`}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card mt-5">
        <div className="card-header py-0 pt-2" style={{ minHeight: "40px" }}>
          <div className="card-title">Advertising Performance</div>
        </div>
        <div
          className="card-body"
          style={{
            filter: graphLoading ? "blur(5px)" : "blur(0)",
            zoom: "110%",
          }}
        >
          <Wrapper>
            {/* lastGraph */}
            <div className="d-flex">
              <div
                className="w-250px"
                style={{ borderRight: "1px dashed #dbdfe9" }}
              >
                <div
                  className="hide-show mt-5 d-grid"
                  style={{
                    overflow: "auto",
                    maxHeight: "309px",
                  }}
                >
                  <Checkbox.Group
                    onChange={(e) => {
                      setSelectedGraph(e);
                    }}
                    style={{ width: "300px", zoom: "90%" }}
                    className="d-grid"
                    value={selectedGraph}
                  >
                    {lastGraph?.series?.map((d, i) => (
                      <Checkbox
                        className={
                          colorList?.find((f) => f?.key === d?.color)?.value
                        }
                        value={d?.name}
                      >
                        <div
                          className="apexcharts-tooltip-series-group apexcharts-active"
                          style={{ display: "flex" }}
                          key={i}
                        >
                          <div
                            className="apexcharts-tooltip-text"
                            style={{
                              fontFamily: "'Montserrat' sans-serif",
                              fontSize: 12,
                            }}
                          >
                            <div className="apexcharts-tooltip-y-group">
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  color: "#656565",
                                }}
                                className="apexcharts-tooltip-text-y-label"
                              >
                                {d?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>

              <div style={{ width: "calc(100vw - 300px)", zoom: "90%" }}>
                <StackedBarLineChart
                  colorList={colorList}
                  updated_data={graphData}
                  series={lastGraph?.series}
                  graphLabels={graphLabels}
                  selectedGraph={selectedGraph}
                />
              </div>
            </div>
          </Wrapper>
        </div>
      </div>

      {PremiumContent({
        contextValue: contextValue,
        PCAUpdate: (e, _) => PCAUpdate(e, _),
      })}
      {contextValue?.data?.user_?.pca_connected === 1 ? (
        <div className="performance_part">
          <div id="kt_app_toolbar" className="app-toolbar mb-3 mt-3 ">
            <div
              id="kt_app_toolbar_container"
              className="app-container d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                  Analyze Advertising Performance
                </h1>
              </div>
              <div>
                <Tabs
                  onChange={(e) => {
                    const obj = {
                      ...selectedFilterTab,
                      ad_product: e,
                      sip_targeting_type: null,
                      sip_medium: null,
                      sip_destination: null,
                      sip_placement: null,
                    };
                    setSelectedFilterTab(obj);
                    AdsPremiumContent({
                      ...obj,
                      ...filtersPremiumContent,
                    });
                  }}
                  type="line"
                  defaultActiveKey="1"
                  items={items}
                />
              </div>
            </div>
          </div>

          <Flex justify="space-between">
            <Flex gap={4}>
              {["Targeting", "Medium", "Destination", "Placement"]?.map(
                (d, i) => {
                  return (
                    <Select
                      loading={filterListLoading}
                      className="w-200px"
                      options={filterList?.[selectedFilterTab?.ad_product]?.[
                        d
                      ]?.map((r) => ({
                        label: r?.mapped_ad_type,
                        value: r?.mapped_ad_type,
                      }))}
                      onChange={(e) => {
                        setSelectedFilterTab({
                          ...selectedFilterTab,
                          [objTypes?.[d]]: e,
                        });
                        AdsPremiumContent({
                          ...selectedFilterTab,
                          ...filtersPremiumContent,
                          [objTypes?.[d]]: e,
                        });
                      }}
                      allowClear
                      value={selectedFilterTab?.[objTypes?.[d]]}
                      size="middle"
                      placeholder={
                        {
                          Targeting: "Select Targeting Type",
                          Medium: "Select Medium",
                          Destination: "Select Destination",
                          Placement: "Select Placement",
                        }?.[d]
                      }
                    />
                  );
                }
              )}
            </Flex>
            <div>
              <DatePicker.RangePicker
                allowClear={false}
                presets={presetRanges}
                disabledDate={(current) => {
                  return current && current > dayjs().endOf("day");
                }}
                size="middle"
                style={{
                  width: "280px",
                  border: "1px solid #D7D7D7",
                }}
                format={"YYYY-MMM-DD"}
                value={
                  filtersPremiumContent?.start_date &&
                  filtersPremiumContent?.end_date
                    ? [
                        dayjs(filtersPremiumContent?.start_date, "YYYY-MM-DD"),
                        dayjs(filtersPremiumContent?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                onChange={(e, _) => {
                  const obj = {
                    start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };
                  AdsPremiumContent({
                    ...selectedFilterTab,
                    ...obj,
                  });

                  setFiltersPremiumContent(obj);
                }}
              />
            </div>
          </Flex>
          <Spin spinning={advertisingPerformanceLoading}>
            <div
              // className="d-flex  mt-5 gap-4"
              // style={{ overflow: "auto", minHeight: "390px" }}
              className="row gap-1 mt-4 mb-4"
              style={{
                minHeight: "390px",
              }}
            >
              {advertisingPerformance?.length === 0 ? (
                <div className="mt-10 pt-10">
                  <Empty />
                </div>
              ) : (
                ""
              )}
              {advertisingPerformance?.map((d, i) => (
                <div className="col">
                  <div key={i} className="card ">
                    <div className="card-header  mt-5" style={{ minHeight: 0 }}>
                      <div className="card-title">{d?.week_number}</div>
                      <div className="card-toolbar">
                        {dayjs(d?.week_start_date, "YYYY-MM-DD").format(
                          "MM/DD/YY"
                        )}
                        &nbsp;to&nbsp;
                        {dayjs(d?.week_end_date, "YYYY-MM-DD").format(
                          "MM/DD/YY"
                        )}
                      </div>
                    </div>
                    <div className="card-body py-3 px-0">
                      <div className="box-content px-5 mx-5">
                        <div className="row">
                          <div className="col-12 d-grid">
                            <label
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  week_number: d?.week_number,
                                  metric_name: "paid_sales",
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {advertisingPerformanceLabel?.["paid_sales"]}
                            </label>
                            <b>${numberformat(d?.paid_sales || 0)}</b>
                          </div>
                          <div className="col-12 d-grid mt-3">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  metric_name: "units_sold",
                                  week_number: d?.week_number,
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["units_sold"]}
                            </label>
                            <b>{numberformat(d?.units_sold || 0)}</b>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 d-grid text-end">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  week_number: d?.week_number,
                                  metric_name: "acos",
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["acos"]}
                            </label>
                            <b>
                              <Tag color="red" bordered={false}>
                                {numberformat(d?.acos || 0)}%
                              </Tag>
                            </b>
                          </div>
                          <div className="col-12 d-grid text-end mt-3">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  metric_name: "roas",
                                  week_number: d?.week_number,
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["roas"]}
                            </label>
                            <b>{numberformat(d?.roas || 0)}</b>
                          </div>
                        </div>
                      </div>
                      <div
                        className="box-content px-5 mx-5"
                        style={{ background: "#edf1f5" }}
                      >
                        <div className="row">
                          <div className="col-12 d-grid">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  metric_name: "advertising_cost",
                                  week_number: d?.week_number,
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {
                                advertisingPerformanceLabel?.[
                                  "advertising_cost"
                                ]
                              }
                            </label>
                            <b>${numberformat(d?.advertising_cost || 0)}</b>
                          </div>
                          <div className="col-12 d-grid mt-3">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  week_number: d?.week_number,
                                  metric_name: "impressions",
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["impressions"]}
                            </label>
                            <b>{numberformat(d?.impressions || 0)}</b>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 d-grid text-end">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  week_number: d?.week_number,
                                  end_date: d?.week_end_date,
                                  metric_name: "tacos",
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                  metric_value: d?.tacos,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["tacos"]}
                            </label>
                            <b>
                              <Tag color="green" bordered={false}>
                                {numberformat(d?.tacos || 0)}%
                              </Tag>
                            </b>
                          </div>
                          <div className="col-12 d-grid mt-3 text-end">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  metric_name: "clicks",
                                  week_number: d?.week_number,
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["clicks"]}
                            </label>
                            <b>{numberformat(d?.clicks || 0)}</b>
                          </div>
                        </div>
                      </div>

                      <div className="box-content px-5 mx-5">
                        <div className="row">
                          <div className="col-12 d-grid">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  week_number: d?.week_number,
                                  metric_name: "avg_ctr",
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["avg_ctr"]}
                            </label>
                            <b>{numberformat(d?.avg_ctr || 0)}%</b>
                          </div>
                          <div className="col-12 d-grid mt-3">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  week_number: d?.week_number,
                                  metric_name: "avg_cpc",
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["avg_cpc"]}
                            </label>
                            <b>${numberformat(d?.avg_cpc || 0)}</b>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 d-grid text-end">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  metric_name: "avg_cpa",
                                  week_number: d?.week_number,
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                  metric_value: d?.avg_cpa,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {advertisingPerformanceLabel?.["avg_cpa"]}
                            </label>
                            <b>${numberformat(d?.avg_cpa || 0)}</b>
                          </div>
                          <div className="col-12 d-grid mt-3 text-end">
                            <label
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAdvertisingPerformanceLineItems({
                                  start_date: d?.week_start_date,
                                  end_date: d?.week_end_date,
                                  week_number: d?.week_number,
                                  metric_name: "impression_share",
                                  ad_product: selectedFilterTab?.ad_product,
                                  sip_targeting_type:
                                    selectedFilterTab?.sip_targeting_type,
                                  sip_medium: selectedFilterTab?.sip_medium,
                                  sip_destination:
                                    selectedFilterTab?.sip_destination,
                                  sip_placement:
                                    selectedFilterTab?.sip_placement,
                                });
                                setAdvertisingPerformanceLineView(true);
                              }}
                            >
                              {
                                advertisingPerformanceLabel?.[
                                  "impression_share"
                                ]
                              }
                            </label>
                            <b>{numberformat(d?.impression_share || 0)}%</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Spin>
        </div>
      ) : (
        <Watermark content="Sample">
          <img src={`/assets/media/ads360.png`} style={{ width: "100%" }} />
        </Watermark>
      )}
      {ReportData(visible, setVisible, reportingData)}
      {viewConfig && (
        <Config
          metricSettingLoading={metricSettingLoading}
          metricSettingList={metricSettingList}
          onClose={() => setViewConfig(false)}
          onSave={saveMetricSetting}
          open={viewConfig}
          data={[]}
        />
      )}
    </WrapperContant>
  );
};

export default AdvertisingDashboard;
