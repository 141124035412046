import {
  Button,
  Checkbox,
  DatePicker,
  Empty,
  Flex,
  Input,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { MakeApiCall } from "../../../../apis";
import { TableLoading } from "../../../../components/table-animation";
import { TableWrapper } from "../style";
import { RenderTable } from "../../../../config";
import DatePickerView from "./date-picker-view";
import { useHistory } from "react-router-dom";

dayjs.extend(quarterOfYear);
const LineItemsSales = ({
  selectedRow,
  close,
  titles,
  loading,
  page,
  getAllLineItemList,
  selectedMetric,
  APIAll,

  setFiltersLine,
  resetTitle,
  filtersLine,
  selectedRangeLine,
  setSelectedRangeLine,
  summaryType = null,
  endPoint = "sales/get-line-items",
}) => {
  console.log(selectedRow, "selectedRow");

  const history = useHistory();
  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [resetTable, setResetTable] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});

  function filterFinancialData(data, filters) {
    if (Object?.keys(filters)?.length === 0) {
      return data;
    }
    return data.filter((item) => {
      for (const key in filters) {
        // Check if the key exists in the item and the value matches
        if (item[key] && filters[key].includes(item[key].toString())) {
          continue;
        } else {
          return false; // If any condition fails, exclude the item
        }
      }
      return true; // Include the item if all conditions are met
    });
  }

  const [resetFlag, setResetFlag] = useState(false);
  const filteredData = filterFinancialData(
    resetFlag ? getAllLineItemList : list,
    selectedFilters
  );
  useEffect(() => {
    if (!resetTable) {
      setSelectedFilters({});
      setTimeout(() => {
        setResetTable(true);
      }, 50);
    }
  }, [resetTable]);

  const getLineItemsList = async (data) => {
    setList([]);
    setListLoading(true);

    const removeObj = {
      paid: [
        "sales",
        "spend",
        "acos",
        "roas",
        "avg_bid",
        "orders",
        "units_sold",
        "impression",
        "ctr",
        "click_thrus",
        "usp",
        "aiv",
      ],
      brb: ["sales", "units_sold", "click_thrus"],
      order: [
        "quantity",
        "item_price",
        "item_tax",
        "shipping_price",
        "shipping_tax",
        "gift_wrap_price",
        "gift_wrap_tax",
        "item_promotion_discount",
        "ship_promotion_discount",
      ],
    };

    try {
      const response = await MakeApiCall(
        selectedRow?.asin
          ? `sales/performance-line-items?start_date=${
              data?.start_date || filtersLine?.start_date || ""
            }&end_date=${data?.end_date || filtersLine?.end_date || ""}&asin=${
              selectedRow?.asin_ || ""
            }`
          : `${endPoint}?start_date=${
              data?.start_date || filtersLine?.start_date || ""
            }&end_date=${
              data?.end_date || filtersLine?.end_date || ""
            }&metric_name=${data?.metric_name}${
              summaryType ? `&summary_type=${summaryType}` : ""
            }${
              filtersLine?.metric_type
                ? `&metric_type=${filtersLine?.metric_type}`
                : ""
            }`,
        "get",
        null,
        true
      );
      if (response?.status) {
        setList(
          response?.data?.filter((d) => {
            const hasZeroValue = removeObj?.[selectedRow?.columns]
              ? removeObj?.[selectedRow?.columns].every(
                  (property) => d[property] === "0" || d[property] === 0
                )
              : false;

            return !hasZeroValue;
          }) || [] // Set the filtered array as the new list, or an empty array if response or data is falsy
        );

        setListLoading(false);
      } else {
        setList([]);
        setListLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setList([]);
      setListLoading(false);
      message.destroy();

      message.error(error?.message);
    }
  };

  const findFixTitle =
    selectedRow?.columns === "Top1000SearchTermsSummary" ||
    selectedRow?.columns === "ItemPerformance" ||
    selectedRow?.columns === "SearchCatalogPerformanceSummary" ||
    selectedRow?.columns === "order";

  const titlesObj = {
    Top1000SearchTermsSummary: "Top 1000 Search Terms Summary",
    ItemPerformance: "Item Performance",
    SearchCatalogPerformanceSummary: "Search Catalog Performance Summary",
    order: "Orders",
  };

  useEffect(() => {
    if (findFixTitle) {
      setListLoading(false);
      setResetTable(false);
      setResetFlag(true);
      return;
    }
    getLineItemsList({ ...filtersLine, metric_name: selectedRow?.type });
    return () => {};
  }, []);

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const disabledDate = (current) => {
    // Disable future dates
    return current && current > moment().endOf("day");
  };

  const filterIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6668 2H1.3335L6.66683 8.30667V12.6667L9.3335 14V8.30667L14.6668 2Z"
        stroke="#696974"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  function addCommasToNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const filterIconSelected = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6668 2H1.3335L6.66683 8.30667V12.6667L9.3335 14V8.30667L14.6668 2Z"
        stroke="#00000000"
        fill="#1f97d3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const propsFilter = (type) => ({
    filterMode: "tree",
    filterIcon: (filtered) => (filtered ? filterIconSelected : filterIcon),
    sorter: (a, b) => {
      return typeof a?.[type] === "string"
        ? (a?.[type] || "")?.localeCompare(b?.[type] || "")
        : parseFloat(a?.[type] || 0) - parseFloat(b?.[type] || 0);
    },
    defaultSortOrder: type === "report_date" ? "ascend" : "",
    onFilter: (value, record) => {
      const columnValue = record[type]?.toString()?.toLowerCase();
      const filterValue =
        typeof value === "number" ? value : value?.toLowerCase();

      return columnValue?.includes(filterValue);
    },

    filterSearch: true,
    defaultFilteredValue:
      type === selectedRow?.filterType
        ? [...new Set(filteredData?.map((d) => d?.[type]))]
        : type === selectedRow?.filterType
        ? [
            ...new Set(
              filteredData
                ?.filter((d) => d?.type === selectedRow?.otherFilters?.type)
                ?.map((d) => d?.type)
            ),
          ]
        : [],
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const newOP =
        selectedRow?.filterType === type
          ? [
              ...new Set(
                getAllLineItemList?.map((d) => d?.[selectedRow?.filterType])
              ),
            ]
          : [...new Set(filteredData?.map((d) => d?.[type]))];

      const filteredOptions = newOP?.filter((option) =>
        option?.toString()?.toLowerCase().includes(searchText.toLowerCase())
      );

      if (type === selectedRow?.filterType) {
      }
      return (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: 8, display: "block", width: "100%" }}
          />
          <Checkbox
            checked={selectedKeys?.length === filteredOptions?.length}
            onChange={(e) => {
              setSelectedKeys(
                selectedKeys?.length === filteredOptions?.length
                  ? []
                  : filteredOptions?.filter((d) => d)
              );
            }}
            className="my-3"
          >
            {selectedKeys?.length === filteredOptions?.length ? (
              <b>Deselect All</b>
            ) : (
              <b>Select All</b>
            )}
          </Checkbox>
          <Checkbox.Group
            style={{
              display: "grid",
              justifyItems: "start",
              gap: "10px",
              marginBottom: "15px",
              maxHeight: "250px",
              overflow: "auto",
            }}
            options={filteredOptions?.filter((d) => d)}
            value={selectedKeys}
            onChange={(e) => {
              setSelectedKeys(e);
            }}
          />
          {}
          <Button
            type="primary"
            onClick={() => {
              setSelectedFilters(
                Object.fromEntries(
                  Object.entries({
                    ...selectedFilters,
                    [type]: selectedKeys,
                  }).filter(([key, value]) => value.length > 0)
                )
              );
              if (type === selectedRow?.filterType) {
                if (resetFlag === false) {
                  setResetFlag(true);
                }
              }
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Apply
          </Button>
          {(selectedFilters?.[type] || type === selectedRow?.filterType) && (
            <Button
              onClick={() => {
                if (type === selectedRow?.filterType) {
                  setSelectedKeys(newOP);

                  if (resetFlag === false) {
                    setResetFlag(true);
                  }
                } else {
                  clearFilters();
                  setSelectedKeys([]);
                }

                confirm();

                setSelectedFilters(
                  Object.fromEntries(
                    Object.entries({
                      ...selectedFilters,
                      [type]: [],
                    }).filter(([key, value]) => value.length > 0)
                  )
                );
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          )}
        </div>
      );
    },
  });
  const CommaAndFloat = (e) => {
    return parseFloat(e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return parseFloat(e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const columns = {
    sales: [
      {
        title: "Date",
        dataIndex: "report_date",
        key: "report_date",
        align: "center",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        align: "left",
        ...propsFilter("source"),
        key: "source",
      },

      {
        title: "$",
        dataIndex: "sales",
        ...propsFilter("sales"),
        key: "sales",
        width: "180px",
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",

        key: "orders",
        align: "right",
        ...propsFilter("orders"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        align: "right",
        key: "units_sold",
        ...propsFilter("units_sold"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Click thrus",
        dataIndex: "click_thrus",
        align: "right",
        key: "click_thrus",
        ...propsFilter("click_thrus"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "USP (%)",
        dataIndex: "usp",
        align: "right",
        key: "usp",
        ...propsFilter("usp"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "AIV ($)",
        dataIndex: "aiv",
        align: "right",
        key: "aiv",
        ...propsFilter("aiv"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
    ],
    paid: [
      {
        title: "Date",
        dataIndex: "report_date",
        key: "report_date",
        align: "center",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        align: "left",
        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        align: "left",
        ...propsFilter("type"),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        align: "left",
        key: "campaign_name",

        render: (e) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 260,
              }}
            >
              {e}
            </div>
          );
        },
        ...propsFilter("campaign_name"),
      },
      {
        title: "$",
        dataIndex: "sales",
        align: "right",
        key: "sales",
        width: "180px",

        ...propsFilter("sales"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Spend ($)",
        dataIndex: "spend",
        key: "spend",
        ...propsFilter("spend"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "ACOS (%)",
        dataIndex: "acos",
        align: "right",
        key: "acos",
        ...propsFilter("acos"),

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "ROAS (%)",
        dataIndex: "roas",
        align: "right",
        key: "roas",
        ...propsFilter("roas"),

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Avg Bid (%)",
        dataIndex: "avg_bid",
        key: "avg_bid",
        ...propsFilter("avg_bid"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",
        key: "orders",
        ...propsFilter("orders"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        key: "units_sold",
        ...propsFilter("units_sold"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Impressions",
        dataIndex: "impression",
        key: "impression",
        ...propsFilter("impression"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CTR (%)",
        dataIndex: "ctr",
        key: "ctr",
        ...propsFilter("ctr"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Click thrus",
        dataIndex: "click_thrus",
        key: "click_thrus",
        ...propsFilter("click_thrus"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CR (%)",
        dataIndex: "usp",
        key: "usp",
        ...propsFilter("usp"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "AIV ($)",
        dataIndex: "aiv",
        key: "aiv",
        ...propsFilter("aiv"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
    ],
    total_cr: [
      {
        title: "Date",
        dataIndex: "report_date",
        key: "report_date",
        align: "center",
        ...propsFilter("report_date"),
      },
      // {
      //   title: "ASIN",
      //   dataIndex: "child_asin",
      //   key: "child_asin",
      //   align: "center",
      //   ...propsFilter("child_asin"),
      // },
      {
        title: "$",
        dataIndex: "sales",
        key: "sales",
        align: "right",
        ...propsFilter("sales"),
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        key: "units_sold",
        align: "right",
        ...propsFilter("units_sold"),
      },
      {
        title: "Orders",
        dataIndex: "orders",
        key: "orders",
        align: "right",
        ...propsFilter("orders"),
      },
      {
        title: "Page Views",
        dataIndex: "page_views",
        key: "page_views",
        align: "right",
        ...propsFilter("page_views"),
      },
      {
        title: "Clicks",
        dataIndex: "clicks",
        key: "clicks",
        align: "right",
        ...propsFilter("clicks"),
      },
      {
        title: "Ordered Product Sales B2B ($)",
        dataIndex: "ordered_product_sales_b2b",
        key: "ordered_product_sales_b2b",
        align: "right",
        ...propsFilter("ordered_product_sales_b2b"),
      },
      {
        title: "Units Ordered B2B",
        dataIndex: "units_ordered_b2b",
        key: "units_ordered_b2b",
        align: "right",
        ...propsFilter("units_ordered_b2b"),
      },
      {
        title: "Total Order Items B2B",
        dataIndex: "total_order_items_b2b",
        key: "total_order_items_b2b",
        align: "right",
        ...propsFilter("total_order_items_b2b"),
      },
      {
        title: "Browser Sessions B2B",
        dataIndex: "browser_sessions_b2b",
        key: "browser_sessions_b2b",
        align: "right",
        ...propsFilter("browser_sessions_b2b"),
      },
      {
        title: "Browser Sessions",
        dataIndex: "browser_sessions",
        key: "browser_sessions",
        align: "right",
        ...propsFilter("browser_sessions"),
      },
      {
        title: "Mobile App Sessions",
        dataIndex: "mobile_app_sessions",
        key: "mobile_app_sessions",
        align: "right",
        ...propsFilter("mobile_app_sessions"),
      },
      {
        title: "Mobile App Sessions B2B",
        dataIndex: "mobile_app_sessions_b2b",
        key: "mobile_app_sessions_b2b",
        align: "right",
        ...propsFilter("mobile_app_sessions_b2b"),
      },
      {
        title: "Sessions",
        dataIndex: "sessions",
        key: "sessions",
        align: "right",
        ...propsFilter("sessions"),
      },
      {
        title: "Sessions B2B",
        dataIndex: "sessions_b2b",
        key: "sessions_b2b",
        align: "right",
        ...propsFilter("sessions_b2b"),
      },
      {
        title: "Browser Session (%)",
        dataIndex: "browser_session_percentage",
        key: "browser_session_percentage",
        align: "right",
        ...propsFilter("browser_session_percentage"),
      },
      {
        title: "Browser Session (%) B2B",
        dataIndex: "browser_session_percentage_b2b",
        key: "browser_session_percentage_b2b",
        align: "right",
        ...propsFilter("browser_session_percentage_b2b"),
      },
      {
        title: "Session (%)",
        dataIndex: "session_percentage",
        key: "session_percentage",
        align: "right",
        ...propsFilter("session_percentage"),
      },
      {
        title: "Session (%) B2B",
        dataIndex: "session_percentage_b2b",
        key: "session_percentage_b2b",
        align: "right",
        ...propsFilter("session_percentage_b2b"),
      },
      {
        title: "Browser Page Views",
        dataIndex: "browser_page_views",
        key: "browser_page_views",
        align: "right",
        ...propsFilter("browser_page_views"),
      },
      {
        title: "Browser Page Views B2B",
        dataIndex: "browser_page_views_b2b",
        key: "browser_page_views_b2b",
        align: "right",
        ...propsFilter("browser_page_views_b2b"),
      },
      {
        title: "Mobile App Page Views",
        dataIndex: "mobile_app_page_views",
        key: "mobile_app_page_views",
        align: "right",
        ...propsFilter("mobile_app_page_views"),
      },
      {
        title: "Browser Page Views (%)",
        dataIndex: "browser_page_views_percentage",
        key: "browser_page_views_percentage",
        align: "right",
        ...propsFilter("browser_page_views_percentage"),
      },
      {
        title: "Browser Page Views (%) B2B",
        dataIndex: "browser_page_views_percentage_b2b",
        key: "browser_page_views_percentage_b2b",
        align: "right",
        ...propsFilter("browser_page_views_percentage_b2b"),
      },
      {
        title: "Mobile App Page Views (%)",
        dataIndex: "mobile_app_page_views_percentage",
        key: "mobile_app_page_views_percentage",
        align: "right",
        ...propsFilter("mobile_app_page_views_percentage"),
      },
      {
        title: "Mobile App Page Views (%) B2B",
        dataIndex: "mobile_app_page_views_percentage_b2b",
        key: "mobile_app_page_views_percentage_b2b",
        align: "right",
        ...propsFilter("mobile_app_page_views_percentage_b2b"),
      },
      {
        title: "Page View (%)",
        dataIndex: "page_view_percentage",
        key: "page_view_percentage",
        align: "right",
        ...propsFilter("page_view_percentage"),
      },
      {
        title: "Page View (%) B2B",
        dataIndex: "page_view_percentage_b2b",
        key: "page_view_percentage_b2b",
        align: "right",
        ...propsFilter("page_view_percentage_b2b"),
      },
      {
        title: "Buy Box (%)",
        dataIndex: "buy_box_percentage",
        key: "buy_box_percentage",
        align: "right",
        ...propsFilter("buy_box_percentage"),
      },
      {
        title: "Buy Box (%) B2B",
        dataIndex: "buy_box_percentage_b2b",
        key: "buy_box_percentage_b2b",
        align: "right",
        ...propsFilter("buy_box_percentage_b2b"),
      },
      {
        title: "Unit Session (%)",
        dataIndex: "unit_session_percentage",
        key: "unit_session_percentage",
        align: "right",
        ...propsFilter("unit_session_percentage"),
      },
      {
        title: "Unit Session (%) B2B",
        dataIndex: "unit_session_percentage_b2b",
        key: "unit_session_percentage_b2b",
        align: "right",
        ...propsFilter("unit_session_percentage_b2b"),
      },
    ],
    paid_funnel: [
      {
        title: "Date",
        dataIndex: "report_date",
        key: "report_date",
        align: "center",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        align: "left",
        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        align: "left",
        ...propsFilter("type"),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        align: "left",
        key: "campaign_name",

        render: (e) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 260,
              }}
            >
              {e}
            </div>
          );
        },
        ...propsFilter("campaign_name"),
      },
      {
        title: "Clicks",
        dataIndex: "clicks",
        align: "right",
        key: "clicks",
        width: "180px",
        ...propsFilter("clicks"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(0))}`;
        },
      },
      {
        title: "$",
        dataIndex: "sales",
        align: "right",
        key: "sales",
        width: "180px",
        ...propsFilter("sales"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Spend ($)",
        dataIndex: "spend",
        key: "spend",
        ...propsFilter("spend"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "ACOS (%)",
        dataIndex: "acos",
        align: "right",
        key: "acos",
        ...propsFilter("acos"),

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "ROAS (%)",
        dataIndex: "roas",
        align: "right",
        key: "roas",
        ...propsFilter("roas"),

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Avg Bid (%)",
        dataIndex: "avg_bid",
        key: "avg_bid",
        ...propsFilter("avg_bid"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",
        key: "orders",
        ...propsFilter("orders"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        key: "units_sold",
        ...propsFilter("units_sold"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Impressions",
        dataIndex: "impression",
        key: "impression",
        ...propsFilter("impression"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CTR (%)",
        dataIndex: "ctr",
        key: "ctr",
        ...propsFilter("ctr"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Keyword",
        dataIndex: "keyword",
        key: "keyword",
        ...propsFilter("keyword"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Keyword Type",
        dataIndex: "keyword_type",
        key: "keyword_type",
        ...propsFilter("keyword_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Match Type",
        dataIndex: "match_type",
        key: "match_type",
        ...propsFilter("match_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Targeting",
        dataIndex: "targeting",
        key: "targeting",
        ...propsFilter("targeting"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Targeting Type",
        dataIndex: "sip_targeting_type",
        key: "sip_targeting_type",
        ...propsFilter("sip_targeting_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Medium",
        dataIndex: "sip_medium",
        key: "sip_medium",
        ...propsFilter("sip_medium"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Destination",
        dataIndex: "sip_destination",
        key: "sip_destination",
        ...propsFilter("sip_destination"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Destination URL",
        dataIndex: "sip_destination_url",
        key: "sip_destination_url",
        ...propsFilter("sip_destination_url"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Placement",
        dataIndex: "sip_placement",
        key: "sip_placement",
        ...propsFilter("sip_placement"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
    ],
    external_clicks_cr: [
      {
        title: "Date",
        dataIndex: "report_date",
        key: "report_date",
        align: "center",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        align: "left",
        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        align: "left",
        ...propsFilter("type"),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        align: "left",
        key: "campaign_name",

        render: (e) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 260,
              }}
            >
              {e}
            </div>
          );
        },
        ...propsFilter("campaign_name"),
      },
      {
        title: "Clicks",
        dataIndex: "clicks",
        align: "right",
        key: "clicks",
        width: "180px",
        ...propsFilter("clicks"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(0))}`;
        },
      },
      {
        title: "$",
        dataIndex: "sales",
        align: "right",
        key: "sales",
        width: "180px",
        ...propsFilter("sales"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Spend ($)",
        dataIndex: "spend",
        key: "spend",
        ...propsFilter("spend"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "ACOS (%)",
        dataIndex: "acos",
        align: "right",
        key: "acos",
        ...propsFilter("acos"),

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "ROAS (%)",
        dataIndex: "roas",
        align: "right",
        key: "roas",
        ...propsFilter("roas"),

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Avg Bid (%)",
        dataIndex: "avg_bid",
        key: "avg_bid",
        ...propsFilter("avg_bid"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",
        key: "orders",
        ...propsFilter("orders"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        key: "units_sold",
        ...propsFilter("units_sold"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Impressions",
        dataIndex: "impression",
        key: "impression",
        ...propsFilter("impression"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CTR (%)",
        dataIndex: "ctr",
        key: "ctr",
        ...propsFilter("ctr"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Keyword",
        dataIndex: "keyword",
        key: "keyword",
        ...propsFilter("keyword"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Keyword Type",
        dataIndex: "keyword_type",
        key: "keyword_type",
        ...propsFilter("keyword_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Match Type",
        dataIndex: "match_type",
        key: "match_type",
        ...propsFilter("match_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Targeting",
        dataIndex: "targeting",
        key: "targeting",
        ...propsFilter("targeting"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Targeting Type",
        dataIndex: "sip_targeting_type",
        key: "sip_targeting_type",
        ...propsFilter("sip_targeting_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Medium",
        dataIndex: "sip_medium",
        key: "sip_medium",
        ...propsFilter("sip_medium"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Destination",
        dataIndex: "sip_destination",
        key: "sip_destination",
        ...propsFilter("sip_destination"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Destination URL",
        dataIndex: "sip_destination_url",
        key: "sip_destination_url",
        ...propsFilter("sip_destination_url"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Placement",
        dataIndex: "sip_placement",
        key: "sip_placement",
        ...propsFilter("sip_placement"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
    ],
    paid_clicks_cr: [
      {
        title: "Date",
        dataIndex: "report_date",
        key: "report_date",
        align: "center",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        align: "left",
        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        align: "left",
        ...propsFilter("type"),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        align: "left",
        key: "campaign_name",

        render: (e) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 260,
              }}
            >
              {e}
            </div>
          );
        },
        ...propsFilter("campaign_name"),
      },
      {
        title: "Clicks",
        dataIndex: "clicks",
        align: "right",
        key: "clicks",
        width: "180px",
        ...propsFilter("clicks"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(0))}`;
        },
      },
      {
        title: "$",
        dataIndex: "sales",
        align: "right",
        key: "sales",
        width: "180px",
        ...propsFilter("sales"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Spend ($)",
        dataIndex: "spend",
        key: "spend",
        ...propsFilter("spend"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "ACOS (%)",
        dataIndex: "acos",
        align: "right",
        key: "acos",
        ...propsFilter("acos"),

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "ROAS (%)",
        dataIndex: "roas",
        align: "right",
        key: "roas",
        ...propsFilter("roas"),

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Avg Bid (%)",
        dataIndex: "avg_bid",
        key: "avg_bid",
        ...propsFilter("avg_bid"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",
        key: "orders",
        ...propsFilter("orders"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        key: "units_sold",
        ...propsFilter("units_sold"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Impressions",
        dataIndex: "impression",
        key: "impression",
        ...propsFilter("impression"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CTR (%)",
        dataIndex: "ctr",
        key: "ctr",
        ...propsFilter("ctr"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Keyword",
        dataIndex: "keyword",
        key: "keyword",
        ...propsFilter("keyword"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Keyword Type",
        dataIndex: "keyword_type",
        key: "keyword_type",
        ...propsFilter("keyword_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Match Type",
        dataIndex: "match_type",
        key: "match_type",
        ...propsFilter("match_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "Targeting",
        dataIndex: "targeting",
        key: "targeting",
        ...propsFilter("targeting"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Targeting Type",
        dataIndex: "sip_targeting_type",
        key: "sip_targeting_type",
        ...propsFilter("sip_targeting_type"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Medium",
        dataIndex: "sip_medium",
        key: "sip_medium",
        ...propsFilter("sip_medium"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Destination",
        dataIndex: "sip_destination",
        key: "sip_destination",
        ...propsFilter("sip_destination"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Destination URL",
        dataIndex: "sip_destination_url",
        key: "sip_destination_url",
        ...propsFilter("sip_destination_url"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
      {
        title: "SIP Placement",
        dataIndex: "sip_placement",
        key: "sip_placement",
        ...propsFilter("sip_placement"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e}</span>;
        },
      },
    ],
    brb: [
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",
        align: "left",
        render: (e) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 260,
              }}
            >
              {e}
            </div>
          );
        },
        ...propsFilter("campaign_name"),
      },
      {
        title: "Ad Group Name",
        dataIndex: "ad_group",
        key: "ad_group",
        align: "left",
        render: (e) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 260,
              }}
            >
              {e}
            </div>
          );
        },
        ...propsFilter("ad_group"),
      },
      {
        title: "Creative Name",
        dataIndex: "creative",
        key: "creative",
        align: "left",
        ...propsFilter("creative"),
        render: (e) => {
          return e || "-";
        },
      },
      {
        title: "$",
        dataIndex: "sales",
        key: "sales",
        width: "180px",
        ...propsFilter("sales"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        key: "units_sold",
        ...propsFilter("units_sold"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return addCommasToNumber(e?.toLocaleString());
        },
      },
      {
        title: "Click Throughs",
        dataIndex: "click_thrus",
        key: "click_thrus",
        ...propsFilter("click_thrus"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return addCommasToNumber(e?.toLocaleString());
        },
      },
    ],
    order: [
      {
        title: "Amazon Order Id",
        dataIndex: "amazon_order_id",
        align: "left",
        key: "amazon_order_id",
        ...propsFilter("amazon_order_id"),
      },
      {
        title: "Purchase Date",
        dataIndex: "format_order_date",
        key: "format_order_date",
        align: "center",
        ...propsFilter("format_order_date"),
      },
      {
        title: "Order Status",
        dataIndex: "order_status",
        key: "order_status",
        align: "left",
        ...propsFilter("order_status"),
      },
      {
        title: "Fulfillment Channel",
        dataIndex: "fulfillment_channel",
        align: "right",
        key: "fulfillment_channel",
        ...propsFilter("fulfillment_channel"),
      },
      {
        title: "Sales Channel",
        dataIndex: "sales_channel",
        key: "sales_channel",
        align: "right",
        ...propsFilter("sales_channel"),
      },
      {
        title: "Order Channel",
        align: "right",
        dataIndex: "order_channel",
        key: "order_channel",
        ...propsFilter("order_channel"),
      },
      {
        title: "Product Name",
        dataIndex: "product_name",
        key: "product_name",
        align: "left",

        ...propsFilter("product_name"),

        render: (e) => {
          return (
            <Tooltip title={e}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "400px",
                }}
              >
                {e || "-"}
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
        align: "left",
        ...propsFilter("sku"),
      },
      {
        title: "ASIN",
        dataIndex: "asin",
        key: "asin",
        align: "right",
        ...propsFilter("asin"),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        align: "right",
        key: "quantity",
        ...propsFilter("quantity"),
      },
      {
        title: "Currency",
        dataIndex: "currency",
        align: "left",
        key: "currency",
        ...propsFilter("currency"),
      },
      {
        title: "Item Price ($)", // ($)
        dataIndex: "item_price",
        key: "item_price",
        ...propsFilter("item_price"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Item Tax ($)", // ($)
        dataIndex: "item_tax",
        key: "item_tax",
        ...propsFilter("item_tax"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Shipping Price ($)", // ($)
        dataIndex: "shipping_price",
        key: "shipping_price",
        ...propsFilter("shipping_price"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Shipping Tax ($)", // ($)
        dataIndex: "shipping_tax",
        key: "shipping_tax",
        ...propsFilter("shipping_tax"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Gift Wrap Price ($)", // ($)
        dataIndex: "gift_wrap_price",
        key: "gift_wrap_price",
        ...propsFilter("gift_wrap_price"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Gift Wrap Tax ($)", // ($)
        dataIndex: "gift_wrap_tax",
        key: "gift_wrap_tax",
        ...propsFilter("gift_wrap_tax"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Item Promotion Discount ($)",
        dataIndex: "item_promotion_discount",
        key: "item_promotion_discount",
        ...propsFilter("item_promotion_discount"),

        align: "right",
      },
      {
        title: "Ship Promotion Discount ($)",
        dataIndex: "ship_promotion_discount",
        key: "ship_promotion_discount",
        ...propsFilter("ship_promotion_discount"),

        align: "right",
      },
      {
        title: "Ship City",
        dataIndex: "ship_city",
        key: "ship_city",
        ...propsFilter("ship_city"),

        align: "right",
      },
      {
        title: "Ship State",
        dataIndex: "ship_state",
        key: "ship_state",
        ...propsFilter("ship_state"),

        align: "right",
      },
      {
        title: "Ship Postal Code",
        dataIndex: "ship_postal_code",
        key: "ship_postal_code",
        ...propsFilter("ship_postal_code"),

        align: "right",
      },
      {
        title: "Ship Country",
        dataIndex: "ship_country",
        key: "ship_country",
        ...propsFilter("ship_country"),

        align: "right",
      },
      {
        title: "Is Business Order",
        dataIndex: "is_business_order",
        key: "is_business_order",
        ...propsFilter("is_business_order"),

        align: "right",
      },
    ],
    organic_traffic: [
      {
        title: "Date",
        dataIndex: "report_date",
        key: "report_date",
        align: "center",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",

        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",

        ...propsFilter("type"),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",

        render: (e) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 260,
              }}
            >
              {e}
            </div>
          );
        },
        ...propsFilter("campaign_name"),
      },
      {
        title: "$",
        dataIndex: "sales",
        width: "180px",
        key: "sales",

        ...propsFilter("sales"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Spend ($)",
        dataIndex: "spend",
        key: "spend",
        ...propsFilter("spend"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "ACOS (%)",
        dataIndex: "acos",
        key: "acos",
        ...propsFilter("acos"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "ROAS (%)",
        dataIndex: "roas",
        key: "roas",
        ...propsFilter("roas"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Avg Bid (%)",
        dataIndex: "avg_bid",
        key: "avg_bid",
        ...propsFilter("avg_bid"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",
        key: "orders",
        ...propsFilter("orders"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        key: "units_sold",
        ...propsFilter("units_sold"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Impressions",
        dataIndex: "impression",
        key: "impression",
        ...propsFilter("impression"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CTR (%)",
        dataIndex: "ctr",
        key: "ctr",
        ...propsFilter("ctr"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Click thrus",
        dataIndex: "click_thrus",
        key: "click_thrus",
        ...propsFilter("click_thrus"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CR (%)",
        dataIndex: "usp",
        key: "usp",
        ...propsFilter("usp"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Total Page Views from BR (%)",
        dataIndex: "usp_",
        key: "usp_",
        ...propsFilter("usp_"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Paid Click thrus from all paid totals (%)",
        dataIndex: "usp__",
        key: "usp__",
        ...propsFilter("usp__"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Calculated organic clicks (%)",
        dataIndex: "usp___",
        key: "usp___",
        ...propsFilter("usp___"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "AIV ($)",
        dataIndex: "aiv",
        key: "aiv",
        ...propsFilter("aiv"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
    ],
    external_traffic: [
      {
        title: "Date",
        dataIndex: "report_date",
        key: "report_date",
        align: "center",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",

        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",

        ...propsFilter("type"),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",

        render: (e) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 260,
              }}
            >
              {e}
            </div>
          );
        },
        ...propsFilter("campaign_name"),
      },
      {
        title: "$",
        dataIndex: "sales",
        width: "180px",
        key: "sales",

        ...propsFilter("sales"),
        align: "right",
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Spend ($)",
        dataIndex: "spend",
        key: "spend",
        ...propsFilter("spend"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "ACOS (%)",
        dataIndex: "acos",
        key: "acos",
        ...propsFilter("acos"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "ROAS (%)",
        dataIndex: "roas",
        key: "roas",
        ...propsFilter("roas"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Avg Bid (%)",
        dataIndex: "avg_bid",
        key: "avg_bid",
        ...propsFilter("avg_bid"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",
        key: "orders",
        ...propsFilter("orders"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_sold",
        key: "units_sold",
        ...propsFilter("units_sold"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Impressions",
        dataIndex: "impression",
        key: "impression",
        ...propsFilter("impression"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CTR (%)",
        dataIndex: "ctr",
        key: "ctr",
        ...propsFilter("ctr"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Click thrus",
        dataIndex: "click_thrus",
        key: "click_thrus",
        ...propsFilter("click_thrus"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },

      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",
        ...propsFilter("campaign_name"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Click-throughs (%)",
        dataIndex: "click_thrus",
        key: "click_thrus",
        ...propsFilter("click_thrus"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      // {
      //   title: "# of Purchases",
      //   dataIndex: "cr",
      //   key: "cr",
      //   ...propsFilter("cr"),
      //   align: "right",

      //   render: (e) => {
      //     if (!e || e == 0) {
      //       return "-";
      //     }
      //     return <span>{addCommasToNumber(e || 0).toLocaleString()}%</span>;
      //   },
      // },
      {
        title: "$",
        dataIndex: "sales",
        key: "sales",
        width: "180px",
        ...propsFilter("sales"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "CR (%)",
        dataIndex: "cr",
        key: "cr",
        ...propsFilter("cr"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "Brand Referral Bonus (%)",
        dataIndex: "cr",
        key: "cr",
        ...propsFilter("cr"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{addCommasToNumber(e || 0).toLocaleString()}</span>;
        },
      },
      {
        title: "AIV ($)",
        dataIndex: "aiv",
        key: "aiv",
        ...propsFilter("aiv"),
        align: "right",

        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
    ],
    Top1000SearchTermsSummary: [
      {
        title: "Year",
        dataIndex: "select_year",
        align: "center",
        key: "select_year",
        ...propsFilter("select_year"),
      },
      {
        title: "Week Number",
        dataIndex: "week_number",
        align: "center",
        key: "week_number",
        ...propsFilter("week_number"),
        width: 160,
      },

      {
        title: "Month Number",
        dataIndex: "month_number",
        align: "center",
        key: "month_number",
        ...propsFilter("month_number"),
        width: 160,
        render: (e) => {
          return e || "-";
        },
      },
      {
        title: "Brand",
        dataIndex: "brand",
        key: "brand",

        ...propsFilter("brand"),
        render: (e) => {
          return e || "-";
        },
      },

      {
        title: "Search Query",
        dataIndex: "search_query",
        key: "search_query",

        align: "left",
        width: 400,
        ...propsFilter("search_query"),
        render: (e) => {
          return e;
        },
      },
      {
        title: "Search Query Score",
        dataIndex: "search_query_score",
        key: "search_query_score",
        align: "right",
        ...propsFilter("search_query_score"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Search Query Volume",
        dataIndex: "search_query_volume",
        key: "search_query_volume",
        align: "right",
        ...propsFilter("search_query_volume"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Impressions: Total Count",
        dataIndex: "impression_total_count",
        ...propsFilter("impression_total_count"),
        align: "right",
        key: "impression_total_count",
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Impressions: ASIN Count",
        dataIndex: "impression_asin_count",
        key: "impression_asin_count",
        align: "right",
        ...propsFilter("impression_asin_count"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Impressions: ASIN Share (%)",
        dataIndex: "impression_asin_share_percentage",
        key: "impression_asin_share_percentage",
        align: "right",
        ...propsFilter("impression_asin_share_percentage"),
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Clicks: Total Count",
        dataIndex: "clicks_total_count",
        align: "right",
        key: "clicks_total_count",
        ...propsFilter("clicks_total_count"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Clicks: Click Rate (%)",
        dataIndex: "clicks_rate_percentage",
        align: "right",
        ...propsFilter("clicks_rate_percentage"),
        key: "clicks_rate_percentage",
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "SIP IQ Score",
        dataIndex: "sip_iq_score",
        align: "right",
        ...propsFilter("sip_iq_score"),
        key: "sip_iq_score",
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Clicks: ASIN Count",
        ...propsFilter("clicks_asin_count"),
        align: "right",
        dataIndex: "clicks_asin_count",
        key: "clicks_asin_count",
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Clicks: ASIN Share (%)",
        align: "right",
        dataIndex: "clicks_asin_share_percentage",
        ...propsFilter("clicks_asin_share_percentage"),
        render: (e) => {
          return CommaAndFloat(e);
        },
        key: "clicks_asin_share_percentage",
      },
      {
        title: "Clicks: Price (Median)",
        align: "right",
        dataIndex: "clicks_price_median",
        ...propsFilter("clicks_price_median"),
        render: (e) => {
          return CommaAndFloat(e);
        },
        key: "clicks_price_median",
      },
      {
        title: "Clicks: ASIN Price (Median)",
        align: "right",
        dataIndex: "clicks_asin_price_median",
        ...propsFilter("clicks_asin_price_median"),
        render: (e) => {
          return CommaAndFloat(e);
        },
        key: "clicks_asin_price_median",
      },
      {
        title: "Clicks: Same Day Shipping Speed",
        align: "right",
        dataIndex: "clicks_same_day_shipping_speed",
        ...propsFilter("clicks_same_day_shipping_speed"),
        key: "clicks_same_day_shipping_speed",
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Clicks: 1D Shipping Speed",
        align: "right",
        dataIndex: "clicks_1d_shipping_speed",
        ...propsFilter("clicks_1d_shipping_speed"),
        key: "clicks_1d_shipping_speed",
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Clicks: 2D Shipping Speed",
        dataIndex: "clicks_2d_shipping_speed",
        align: "right",
        key: "clicks_2d_shipping_speed",
        ...propsFilter("clicks_2d_shipping_speed"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "SIP Click Score",
        dataIndex: "sip_click_score",
        align: "right",
        ...propsFilter("sip_click_score"),
        render: (e) => {
          return CommaAndFloat(e);
        },
        key: "sip_click_score",
      },
      {
        title: "Cart Adds: Total Count",
        dataIndex: "cart_adds_total_count",
        align: "right",
        key: "cart_adds_total_count",
        ...propsFilter("cart_adds_total_count"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Cart Adds: Cart Add Rate (%)",
        ...propsFilter("cart_add_rate_percentage"),
        align: "right",
        dataIndex: "cart_add_rate_percentage",
        render: (e) => {
          return CommaAndFloat(e);
        },
        key: "cart_add_rate_percentage",
      },
      {
        title: "Cart Adds: ASIN Count",
        dataIndex: "cart_add_asin_count",
        align: "right",
        ...propsFilter("cart_add_asin_count"),
        key: "cart_add_asin_count",
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Cart Adds: ASIN Share (%)",
        ...propsFilter("cart_add_asin_share_percentage"),
        align: "right",
        render: (e) => {
          return CommaAndFloat(e);
        },
        dataIndex: "cart_add_asin_share_percentage",
        key: "cart_add_asin_share_percentage",
      },
      {
        title: "Cart Adds: Price (Median)",
        dataIndex: "cart_add_price_median",
        align: "right",
        ...propsFilter("cart_add_price_median"),
        render: (e) => {
          return CommaAndFloat(e);
        },
        key: "cart_add_price_median",
      },
      {
        title: "Cart Adds: ASIN Price (Median)",
        align: "right",
        dataIndex: "cart_adds_asin_price_median",
        key: "cart_adds_asin_price_median",
        ...propsFilter("cart_adds_asin_price_median"),
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Cart Adds: Same Day Shipping Speed",
        dataIndex: "cart_adds_same_day_shipping_speed",
        align: "right",
        key: "cart_adds_same_day_shipping_speed",
        ...propsFilter("cart_adds_same_day_shipping_speed"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Cart Adds: 1D Shipping Speed",
        dataIndex: "clicks_1d_shipping_speed",
        ...propsFilter("clicks_1d_shipping_speed"),
        align: "right",
        key: "clicks_1d_shipping_speed",
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Cart Adds: 2D Shipping Speed",
        dataIndex: "clicks_2d_shipping_speed",
        key: "clicks_2d_shipping_speed",
        align: "right",
        ...propsFilter("clicks_2d_shipping_speed"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "SIP Cart Score",
        dataIndex: "sip_cart_score",
        key: "sip_cart_score",
        ...propsFilter("sip_cart_score"),
        align: "right",
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Purchases: Total Count",
        dataIndex: "purchases_total_count",
        key: "purchases_total_count",
        align: "right",
        ...propsFilter("purchases_total_count"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Purchases: Purchase Rate (%)",
        dataIndex: "purchase_rate_percentage",
        align: "right",
        key: "purchase_rate_percentage",
        ...propsFilter("purchase_rate_percentage"),
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Purchases: ASIN Count",
        dataIndex: "purchases_asin_count",
        align: "right",
        key: "purchases_asin_count",
        ...propsFilter("purchases_asin_count"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Purchases: ASIN Share (%)",
        dataIndex: "purchases_asin_price_median",
        ...propsFilter("purchases_asin_price_median"),
        align: "right",
        key: "purchases_asin_price_median",
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Purchases: Price (Median)",
        dataIndex: "purchases_price_median",
        align: "right",
        ...propsFilter("purchases_price_median"),
        key: "purchases_price_median",
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Purchases: ASIN Price (Median)",
        align: "right",
        dataIndex: "purchases_asin_price_median",
        key: "purchases_asin_price_median",
        ...propsFilter("purchases_asin_price_median"),
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Purchases: Same Day Shipping Speed",
        dataIndex: "purchases_same_day_shipping_speed",
        align: "right",
        ...propsFilter("purchases_same_day_shipping_speed"),
        key: "purchases_same_day_shipping_speed",
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Purchases: 1D Shipping Speed",
        align: "right",
        dataIndex: "purchases_1d_shipping_speed",
        key: "purchases_1d_shipping_speed",
        ...propsFilter("purchases_1d_shipping_speed"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Purchases: 2D Shipping Speed",
        align: "right",
        dataIndex: "purchases_2d_shipping_speed",
        key: "purchases_2d_shipping_speed",
        ...propsFilter("purchases_2d_shipping_speed"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "SIP Purchase Score",
        dataIndex: "sip_purchase_score",
        align: "right",
        key: "sip_purchase_score",
        ...propsFilter("sip_purchase_score"),
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Reporting Date",
        dataIndex: "reporting_date",
        align: "center",
        ...propsFilter("reporting_date"),
        key: "reporting_date",
      },
    ],
    SearchCatalogPerformanceSummary: [
      {
        title: "Year",
        dataIndex: "select_year",
        align: "center",
        key: "select_year",
        ...propsFilter("select_year"),
      },
      {
        title: "Week Number",
        dataIndex: "week_number",
        key: "week_number",
        align: "center",
        ...propsFilter("week_number"),
      },
      {
        title: "Month Number",
        dataIndex: "month_number",
        align: "center",
        key: "month_number",
        ...propsFilter("month_number"),
      },
      {
        title: "ASIN Title",

        dataIndex: "title",
        ...propsFilter("title"),
        key: "title",
      },
      {
        title: "SKU",
        dataIndex: "sku",
        ...propsFilter("sku"),
        key: "sku",
      },

      {
        title: "Category",
        dataIndex: "category",
        ...propsFilter("category"),
        key: "category",
      },
      {
        title: "Impressions",
        dataIndex: "impressions",
        ...propsFilter("impressions"),
        key: "impressions",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Rating (Median)",
        dataIndex: "impressions_rating_median",
        align: "right",
        ...propsFilter("impressions_rating_median"),
        key: "impressions_rating_median",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Price (Median)",
        align: "right",
        dataIndex: "impressions_price_median",
        ...propsFilter("impressions_price_median"),
        key: "impressions_price_median",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Same Day Shipping Speed",
        align: "right",
        dataIndex: "impressions_same_day_shipping_speed",
        ...propsFilter("impressions_same_day_shipping_speed"),
        key: "impressions_same_day_shipping_speed",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "1D Shipping Speed",
        align: "right",
        dataIndex: "impressions_1d_shipping_speed",
        ...propsFilter("impressions_1d_shipping_speed"),
        key: "impressions_1d_shipping_speed",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        align: "right",
        title: "2D Shipping Speed",
        ...propsFilter("impressions_2d_shipping_speed"),
        dataIndex: "impressions_2d_shipping_speed",
        key: "impressions_2d_shipping_speed",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Clicks",
        dataIndex: "clicks",
        ...propsFilter("clicks"),
        align: "right",
        key: "clicks",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Click Rate (CTR) (%)",
        ...propsFilter("clicks_rate_ctr"),
        align: "right",
        dataIndex: "clicks_rate_ctr",
        key: "clicks_rate_ctr",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Price (Median) ($)",
        ...propsFilter("clicks_price_median"),
        dataIndex: "clicks_price_median",
        align: "right",
        key: "clicks_price_median",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Same Day Shipping Speed",
        ...propsFilter("clicks_same_day_shipping_speed"),
        dataIndex: "clicks_same_day_shipping_speed",
        align: "right",
        key: "clicks_same_day_shipping_speed",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "1D Shipping Speed",
        dataIndex: "clicks_1d_shipping_speed",
        align: "right",
        ...propsFilter("clicks_1d_shipping_speed"),
        render: (e) => {
          return Comma(e || 0);
        },
        key: "clicks_1d_shipping_speed",
      },
      {
        title: "2D Shipping Speed",
        dataIndex: "clicks_2d_shipping_speed",
        align: "right",
        ...propsFilter("clicks_2d_shipping_speed"),
        key: "clicks_2d_shipping_speed",
      },
      {
        title: "Cart Adds",
        dataIndex: "cart_adds",
        align: "right",
        ...propsFilter("cart_adds"),
        key: "cart_adds",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Price (Median) ($)",
        dataIndex: "cart_adds_price_median",
        align: "right",
        ...propsFilter("cart_adds_price_median"),
        key: "cart_adds_price_median",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Same Day Shipping Speed",
        dataIndex: "cart_adds_same_day_shipping_speed",
        align: "right",
        ...propsFilter("cart_adds_same_day_shipping_speed"),
        key: "cart_adds_same_day_shipping_speed",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "1D Shipping Speed",
        dataIndex: "cart_adds_1d_shipping_speed",

        align: "right",
        ...propsFilter("cart_adds_1d_shipping_speed"),
        key: "cart_adds_1d_shipping_speed",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "2D Shipping Speed",
        dataIndex: "cart_adds_2d_shipping_speed",

        align: "right",
        ...propsFilter("cart_adds_2d_shipping_speed"),
        key: "cart_adds_2d_shipping_speed",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Purchases",
        dataIndex: "purchases",
        align: "right",
        ...propsFilter("purchases"),
        key: "purchases",
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Search Traffic Sales",
        align: "right",
        dataIndex: "purchases_search_traffic_sales",
        key: "purchases_search_traffic_sales",
        ...propsFilter("purchases_search_traffic_sales"),
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Conversion Rate (%)",
        align: "right",
        dataIndex: "purchases_conversion_rate_pect",
        key: "purchases_conversion_rate_pect",
        ...propsFilter("purchases_conversion_rate_pect"),
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Rating (Median)",
        align: "right",
        dataIndex: "purchases_rating_median",
        key: "purchases_rating_median",
        ...propsFilter("purchases_rating_median"),
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "Price (Median) ($)",
        align: "right",
        dataIndex: "purchases_price_median",
        key: "purchases_price_median",
        render: (e) => {
          return Comma(e || 0);
        },
        ...propsFilter("purchases_price_median"),
      },
      {
        title: "Same Day Shipping Speed",
        dataIndex: "purchases_same_day_shipping_speed",
        align: "right",
        key: "purchases_same_day_shipping_speed",
        ...propsFilter("purchases_same_day_shipping_speed"),
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "1D Shipping Speed",
        align: "right",
        dataIndex: "purchases_1d_shipping_speed",
        key: "purchases_1d_shipping_speed",
        ...propsFilter("purchases_1d_shipping_speed"),
        render: (e) => {
          return Comma(e || 0);
        },
      },
      {
        title: "2D Shipping Speed",
        dataIndex: "purchases_2d_shipping_speed",
        key: "purchases_2d_shipping_speed",
        align: "right",
        ...propsFilter("purchases_2d_shipping_speed"),
        render: (e) => {
          return Comma(e || 0);
        },
      },

      {
        title: "Reporting Date",
        dataIndex: "reporting_date",
        key: "reporting_date",
        align: "center",
        ...propsFilter("reporting_date"),
      },
    ],
    TopSearchTermsSummary: [
      {
        title: "Search Term",
        dataIndex: "search_term",
        key: "search_term",
        ...propsFilter("search_term"),
      },
      {
        title: "Search Frequency Rank",
        dataIndex: "search_frequency_rank",
        key: "search_frequency_rank",
        align: "right",
        ...propsFilter("search_frequency_rank"),
        render: (e) => {
          return Comma(e);
        },
      },
      {
        title: "Top Clicked Item #1 SKU",
        dataIndex: "Top Clicked Item #1 SKU",
        key: "Top Clicked Item #1 SKU",
        ...propsFilter("month_number"),
      },
      {
        title: "Top Clicked Item #1 ASIN",
        dataIndex: "top_clicked_product_1asin",
        key: "top_clicked_product_1asin",
        ...propsFilter("top_clicked_product_1asin"),
      },
      {
        title: "Top Clicked Item #1 Item Title",
        dataIndex: "top_clicked_product_1product_title",
        key: "top_clicked_product_1product_title",
        ...propsFilter("top_clicked_product_1product_title"),
      },
      {
        title: "Top Clicked Item #1 Click share",
        dataIndex: "top_clicked_product_1click_share",
        align: "right",
        key: "top_clicked_product_1click_share",
        render: (e) => {
          return CommaAndFloat(e);
        },
        ...propsFilter("top_clicked_product_1click_share"),
      },
      {
        title: "Top Clicked Item #1 Conversion Rate (%)",
        dataIndex: "Top Clicked Item #1 Conversion Rate",
        align: "right",
        key: "Top Clicked Item #1 Conversion Rate",
        ...propsFilter("month_number"),
        render: (e) => {
          return <span>{CommaAndFloat(e)}</span>;
        },
      },

      {
        title: "Top Clicked Item #2 SKU",
        dataIndex: "Top Clicked Item #2 SKU",
        key: "Top Clicked Item #2 SKU",
        ...propsFilter("month_number"),
      },
      {
        title: "Top Clicked Item #2 ASIN",
        dataIndex: "top_clicked_product_2asin",
        key: "top_clicked_product_2asin",
        ...propsFilter("top_clicked_product_2asin"),
      },
      {
        title: "Top Clicked Item #2 Item Title",
        dataIndex: "top_clicked_product_2product_title",
        key: "top_clicked_product_2product_title",
        ...propsFilter("top_clicked_product_2product_title"),
      },
      {
        title: "Top Clicked Item #2 Click share",
        align: "right",
        dataIndex: "top_clicked_product_2click_share",
        key: "top_clicked_product_2click_share",
        ...propsFilter("top_clicked_product_2click_share"),
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Top Clicked Item #2 Conversion Rate (%)",
        dataIndex: "Top Clicked Item #2 Conversion Rate",
        align: "right",
        key: "Top Clicked Item #2 Conversion Rate",
        ...propsFilter("month_number"),
        render: (e) => {
          return <span>{CommaAndFloat(e)}</span>;
        },
      },

      {
        title: "Top Clicked Item #3 SKU",
        dataIndex: "Top Clicked Item #3 SKU",
        key: "Top Clicked Item #3 SKU",
        ...propsFilter("month_number"),
      },
      {
        title: "Top Clicked Item #3 ASIN",
        dataIndex: "top_clicked_product_3asin",
        key: "top_clicked_product_3asin",
        ...propsFilter("top_clicked_product_3asin"),
      },
      {
        title: "Top Clicked Item #3 Item Title",
        dataIndex: "top_clicked_product_3product_title",
        key: "top_clicked_product_3product_title",
        align: "right",
        ...propsFilter("top_clicked_product_3product_title"),
      },
      {
        title: "Top Clicked Item #3 Click share",
        dataIndex: "top_clicked_product_3click_share",
        align: "right",
        key: "top_clicked_product_3click_share",
        ...propsFilter("top_clicked_product_3click_share"),
        render: (e) => {
          return CommaAndFloat(e);
        },
      },
      {
        title: "Top Clicked Item #3 Conversion Rate (%)",
        dataIndex: "Top Clicked Item #3 Conversion Rate",
        align: "right",
        key: "Top Clicked Item #3 Conversion Rate",
        ...propsFilter("month_number"),
        render: (e) => {
          return <span>{CommaAndFloat(e)}</span>;
        },
      },
    ],
    ItemPerformance: [
      {
        title: "Date",
        dataIndex: "sales_date",
        align: "center",
        key: "sales_date",
        ...propsFilter("sales_date"),
      },
      {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
        ...propsFilter("sku"),
      },
      {
        title: "Parent ASIN",
        dataIndex: "parent_asin",
        key: "parent_asin",
        ...propsFilter("parent_asin"),
      },
      // {
      //   title: "Child ASIN",
      //   dataIndex: "child_asin",
      //   key: "child_asin",
      //   ...propsFilter("child_asin"),
      // },
      {
        title: "Item Title",
        dataIndex: "title",
        key: "title",
        ...propsFilter("title"),
      },
      {
        title: "Product Sales ($)",
        align: "right",
        dataIndex: "ordered_product_sales",
        key: "ordered_product_sales",
        ...propsFilter("ordered_product_sales"),
        render: (e) => {
          return <span>{CommaAndFloat(e || 0)}</span>;
        },
      },
      {
        title: "B2B Sales ($)",
        align: "right",
        dataIndex: "ordered_product_sales_b2b",
        key: "ordered_product_sales_b2b",
        ...propsFilter("ordered_product_sales_b2b"),
        render: (e) => {
          return <span>{CommaAndFloat(e || 0)}</span>;
        },
      },
      {
        title: "Units Sold",
        align: "right",
        dataIndex: "units_ordered",
        key: "units_ordered",
        ...propsFilter("units_ordered"),
        render: (e) => {
          return <span>{Comma(e || 0)}</span>;
        },
      },
      {
        title: "B2B Units",
        dataIndex: "units_ordered_b2b",
        align: "right",
        key: "units_ordered_b2b",
        ...propsFilter("units_ordered_b2b"),
        render: (e) => {
          return <span>{Comma(e || 0)}</span>;
        },
      },
      {
        title: "Browser Sessions",
        align: "right",
        dataIndex: "browser_sessions",
        key: "browser_sessions",
        ...propsFilter("browser_sessions"),
        render: (e) => {
          return <span>{Comma(e || 0)}</span>;
        },
      },
      {
        title: "Mobile Sessions",
        align: "right",
        dataIndex: "mobile_app_sessions",
        key: "mobile_app_sessions",
        ...propsFilter("mobile_app_sessions"),
        render: (e) => {
          return <span>{Comma(e || 0)}</span>;
        },
      },
      {
        title: "Total Sessions",
        dataIndex: "sessions",
        align: "right",
        key: "sessions",
        ...propsFilter("sessions"),
        render: (e) => {
          return <span>{Comma(e || 0)}</span>;
        },
      },
      {
        title: "Browser Session Rate (%)",
        align: "right",
        // dataIndex: "browser_session_rate",
        // key: "browser_session_rate",
        ...propsFilter("browser_session_rate"),
        render: (d) => {
          return (
            <span>
              {CommaAndFloat(
                parseFloat((d?.browser_sessions || 0) / (d?.sessions || 0))
              )}
            </span>
          );
        },
      },
      {
        align: "right",
        title: "Browser Page Views",
        dataIndex: "browser_page_views",
        key: "browser_page_views",
        ...propsFilter("browser_page_views"),
        render: (e) => {
          return <span>{Comma(e || 0)}</span>;
        },
      },
      {
        title: "Mobile Page Views",
        align: "right",
        dataIndex: "mobile_app_page_views",
        key: "mobile_app_page_views",
        ...propsFilter("mobile_app_page_views"),
        render: (e) => {
          return <span>{Comma(e || 0)}</span>;
        },
      },
      {
        title: "Total Page Views",
        dataIndex: "page_views",
        align: "right",
        key: "page_views",
        ...propsFilter("page_views"),
        render: (e) => {
          return <span>{Comma(e || 0)}</span>;
        },
      },
      {
        title: "Browser Page View Rate (%)",
        // dataIndex: "browser_page_view_rate",
        // key: "browser_page_view_rate",
        ...propsFilter("browser_page_view_rate"),
        align: "right",
        render: (d) => {
          return (
            <span>
              {CommaAndFloat(
                parseFloat((d?.browser_page_views || 0) / (d?.page_views || 0))
              )}
            </span>
          );
        },
      },
      {
        title: "Buy Box (%)",
        dataIndex: "buy_box_percentage",
        key: "buy_box_percentage",
        ...propsFilter("buy_box_percentage"),
        align: "right",
        render: (e) => {
          return <span>{CommaAndFloat(e || 0)}</span>;
        },
      },
      {
        title: "USP (%)",
        // dataIndex: "usp_pr",
        // key: "usp_pr",
        ...propsFilter("usp_pr"),
        align: "right",

        render: (d) => {
          return (
            <span>
              {CommaAndFloat((d?.units_ordered || 0) / (d?.sessions || 0))}
            </span>
          );
        },
      },
      {
        title: "Click to Sales (%)",
        align: "right",
        // dataIndex: "click_to_sales_pr",
        // key: "click_to_sales_pr",
        ...propsFilter("click_to_sales_pr"),

        render: (d) => {
          return (
            <span>
              {CommaAndFloat((d?.units_ordered || 0) / (d?.page_views || 0))}
            </span>
          );
        },
      },
    ],
    avg_summary: [
      {
        title: "ID",
        dataIndex: "avg",
        align: "right",
        width: 120,
        key: "avg",
        ...propsFilter("avg"),
      },
      {
        title: "Date",
        dataIndex: "avg",
        align: "right",
        key: "avg",
        width: 120,
        ...propsFilter("avg"),
      },
      {
        title: "Type",
        dataIndex: "avg",
        align: "right",
        key: "avg",
        width: 120,
        ...propsFilter("avg"),
      },
      {
        title: "Sales 7D",
        dataIndex: "avg",
        align: "right",
        width: 160,
        key: "avg",
        ...propsFilter("avg"),
      },
      {
        title: "Clicks",
        dataIndex: "avg",
        width: 130,
        align: "right",
        key: "avg",
        ...propsFilter("avg"),
      },
      {
        title: "Sales 14D",
        width: 180,
        dataIndex: "avg",
        align: "right",
        key: "avg",
        ...propsFilter("avg"),
      },
      {
        title: "Total Product Sales-14D",
        dataIndex: "avg",
        align: "right",
        width: 250,
        key: "avg",
        ...propsFilter("avg"),
      },
      {
        title: "Ordered Product Sales",
        dataIndex: "avg",
        align: "right",
        width: 250,
        key: "avg",
        ...propsFilter("avg"),
      },

      {
        title: "Page Views",
        dataIndex: "avg",
        width: 220,
        align: "right",
        key: "avg",
        ...propsFilter("avg"),
      },
      {
        title: "Created Date",
        dataIndex: "avg",
        align: "right",
        width: 220,
        key: "avg",
        ...propsFilter("avg"),
      },
      {
        title: "Updated Date",
        dataIndex: "avg",
        align: "right",
        width: 220,
        key: "avg",
        ...propsFilter("avg"),
      },
    ],
    average_external_14d_click: [
      {
        title: "Report Date",
        dataIndex: "report_date",
        align: "right",

        key: "report_date",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        align: "right",
        key: "source",

        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        align: "right",
        key: "type",

        ...propsFilter("type"),
      },
      {
        title: "Sales 14D",
        dataIndex: "sales14D",
        align: "right",

        key: "sales14D",
        ...propsFilter("sales14D"),
      },
      {
        title: "Clicks",
        dataIndex: "clicks",

        align: "right",
        key: "clicks",
        ...propsFilter("clicks"),
      },
      {
        title: "Total Product Sales 14D",

        dataIndex: "total_product_sales_14D",
        align: "right",
        key: "total_product_sales_14D",
        ...propsFilter("total_product_sales_14D"),
      },
      {
        title: "Click Thrus",
        dataIndex: "click_thrus",
        align: "right",

        key: "click_thrus",
        ...propsFilter("click_thrus"),
      },
      {
        title: "Ordered Product Sales",
        dataIndex: "order_product_sales",
        align: "right",

        key: "order_product_sales",
        ...propsFilter("order_product_sales"),
      },

      {
        title: "Page Views",
        dataIndex: "page_views",

        align: "right",
        key: "page_views",
        ...propsFilter("page_views"),
      },
    ],
    average_14d_paid_click: [
      {
        title: "Report Date",
        dataIndex: "report_date",
        align: "right",

        key: "report_date",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        align: "right",
        key: "source",

        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        align: "right",
        key: "type",

        ...propsFilter("type"),
      },
      {
        title: "Sales 14D",
        dataIndex: "sales14D",
        align: "right",

        key: "sales14D",
        ...propsFilter("sales14D"),
      },
      {
        title: "Clicks",
        dataIndex: "clicks",

        align: "right",
        key: "clicks",
        ...propsFilter("clicks"),
      },
      {
        title: "Total Product Sales 14D",

        dataIndex: "total_product_sales_14D",
        align: "right",
        key: "total_product_sales_14D",
        ...propsFilter("total_product_sales_14D"),
      },
      {
        title: "Click Thrus",
        dataIndex: "click_thrus",
        align: "right",

        key: "click_thrus",
        ...propsFilter("click_thrus"),
      },
      {
        title: "Ordered Product Sales",
        dataIndex: "order_product_sales",
        align: "right",

        key: "order_product_sales",
        ...propsFilter("order_product_sales"),
      },

      {
        title: "Page Views",
        dataIndex: "page_views",

        align: "right",
        key: "page_views",
        ...propsFilter("page_views"),
      },
    ],
    average_total_click: [
      {
        title: "Report Date",
        dataIndex: "report_date",
        align: "right",

        key: "report_date",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        align: "right",
        key: "source",
        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        align: "right",
        key: "type",

        ...propsFilter("type"),
      },
      {
        title: "Sales 14D",
        dataIndex: "sales14D",
        align: "right",

        key: "sales14D",
        ...propsFilter("sales14D"),
      },
      {
        title: "Clicks",
        dataIndex: "clicks",

        align: "right",
        key: "clicks",
        ...propsFilter("clicks"),
      },
      {
        title: "Total Product Sales 14D",

        dataIndex: "total_product_sales_14D",
        align: "right",
        key: "total_product_sales_14D",
        ...propsFilter("total_product_sales_14D"),
      },
      {
        title: "Click Thrus",
        dataIndex: "click_thrus",
        align: "right",

        key: "click_thrus",
        ...propsFilter("click_thrus"),
      },
      {
        title: "Ordered Product Sales",
        dataIndex: "order_product_sales",
        align: "right",

        key: "order_product_sales",
        ...propsFilter("order_product_sales"),
      },

      {
        title: "Page Views",
        dataIndex: "page_views",

        align: "right",
        key: "page_views",
        ...propsFilter("page_views"),
      },
    ],
    ads_sponsored_products: [
      {
        title: "Report Date",
        dataIndex: "report_date",
        align: "left",
        key: "report_date",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        align: "left",
        key: "source",
        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        align: "left",
        key: "type",
        ...propsFilter("type"),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        align: "left",
        key: "campaign_name",
        ...propsFilter("campaign_name"),
      },
      {
        title: "$",
        dataIndex: "sales",
        align: "right",
        key: "sales",
        ...propsFilter("sales"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Cost ($)",
        dataIndex: "cost",
        align: "right",
        key: "cost",
        ...propsFilter("cost"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "ACOS (%)",
        dataIndex: "acos",
        align: "right",
        key: "acos",
        ...propsFilter("acos"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "ROAS (%)",
        dataIndex: "roas",
        align: "right",
        key: "roas",
        ...propsFilter("roas"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",
        align: "right",
        key: "orders",
        ...propsFilter("orders"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_solds",
        align: "right",
        key: "units_solds",
        ...propsFilter("units_solds"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Impression",
        dataIndex: "impression",
        align: "right",
        key: "impression",
        ...propsFilter("impression"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Impression Share (%)",
        dataIndex: "impression_share",
        align: "right",
        key: "impression_share",
        ...propsFilter("impression_share"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "CTR",
        dataIndex: "ctr",
        align: "right",
        key: "ctr",
        ...propsFilter("ctr"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "CPC ($)",
        dataIndex: "cpc",
        align: "right",
        key: "cpc",
        ...propsFilter("cpc"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
    ],
    ads_avd_sponsored_products: [
      {
        title: "Report Date",
        dataIndex: "report_date",
        align: "left",
        key: "report_date",
        ...propsFilter("report_date"),
      },
      {
        title: "Source",
        dataIndex: "source",
        align: "left",
        key: "source",
        ...propsFilter("source"),
      },
      {
        title: "Type",
        dataIndex: "type",
        align: "left",
        key: "type",
        ...propsFilter("type"),
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        align: "left",
        key: "campaign_name",
        ...propsFilter("campaign_name"),
      },
      {
        title: "$",
        dataIndex: "sales",
        align: "right",
        key: "sales",
        ...propsFilter("sales"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Cost ($)",
        dataIndex: "cost",
        align: "right",
        key: "cost",
        ...propsFilter("cost"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "ACOS (%)",
        dataIndex: "acos",
        align: "right",
        key: "acos",
        ...propsFilter("acos"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "ROAS (%)",
        dataIndex: "roas",
        align: "right",
        key: "roas",
        ...propsFilter("roas"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Orders",
        dataIndex: "orders",
        align: "right",
        key: "orders",
        ...propsFilter("orders"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Units Sold",
        dataIndex: "units_solds",
        align: "right",
        key: "units_solds",
        ...propsFilter("units_solds"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Impression",
        dataIndex: "impression",
        align: "right",
        key: "impression",
        ...propsFilter("impression"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "Impression Share (%)",
        dataIndex: "impression_share",
        align: "right",
        key: "impression_share",
        ...propsFilter("impression_share"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "CTR",
        dataIndex: "ctr",
        align: "right",
        key: "ctr",
        ...propsFilter("ctr"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
      {
        title: "CPC ($)",
        dataIndex: "cpc",
        align: "right",
        key: "cpc",
        ...propsFilter("cpc"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return <span>{e.toLocaleString()}</span>;
        },
      },
    ],
    top10ItemPerformance: [
      {
        title: "ID",
        dataIndex: "key",
        align: "center",
        key: "key",
        ...propsFilter("key"),
        render: (e) => {
          return <div className="d-flex justify-content-center w-100">{e}</div>;
        },
      },
      {
        title: "Purchase Date",
        dataIndex: "purchase_date",
        align: "left",
        key: "purchase_date",
        ...propsFilter("purchase_date"),
      },
      {
        title: "Amazon Order ID",
        dataIndex: "amazon_order_id",
        align: "left",
        key: "amazon_order_id",
        ...propsFilter("amazon_order_id"),
      },
      {
        title: "SKU",
        dataIndex: "sku",
        align: "left",
        key: "sku",
        ...propsFilter("sku"),
      },
      {
        title: "ASIN",
        dataIndex: "asin",
        align: "left",
        key: "asin",
        ...propsFilter("asin"),
      },
      {
        title: "Item Title",
        dataIndex: "product_name",
        align: "left",
        key: "product_name",
        ...propsFilter("product_name"),
        render: (e) => {
          return (
            <Tooltip title={e}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "300px",
                  display: "block",
                }}
              >
                {e}
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        align: "center",
        key: "quantity",
        ...propsFilter("quantity"),
      },
      {
        title: "Currency",
        dataIndex: "currency",
        align: "left",
        key: "currency",
        ...propsFilter("currency"),
      },
      {
        title: "Item Price ($)",
        dataIndex: "item_price",
        align: "left",
        key: "item_price",
        ...propsFilter("item_price"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Item Promotion Discount ($)",
        dataIndex: "item_promotion_discount",
        align: "left",
        key: "item_promotion_discount",
        ...propsFilter("item_promotion_discount"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Unit Sold",
        dataIndex: "unit_sold",
        align: "center",
        key: "unit_sold",
        ...propsFilter("unit_sold"),
      },
      {
        title: "Sessions",
        dataIndex: "session",
        align: "center",
        key: "session",
        ...propsFilter("session"),
      },
      {
        title: "USP (%)",
        dataIndex: "usp",
        align: "left",
        key: "usp",
        ...propsFilter("usp"),
      },
      {
        title: "Velocity",
        dataIndex: "velocity",
        align: "center",
        key: "velocity",
        ...propsFilter("velocity"),
      },
      {
        title: "LP",
        dataIndex: "last_period",
        align: "center",
        key: "last_period",
        ...propsFilter("last_period"),
      },
      {
        title: "LP Velocity",
        dataIndex: "last_period_velocity",
        align: "center",
        key: "last_period_velocity",
        ...propsFilter("last_period_velocity"),
      },

      {
        title: "Variance (%)",
        dataIndex: "variance",
        align: "left",
        key: "variance",
        ...propsFilter("variance"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Fulfillment Channel",
        dataIndex: "fulfillment_channel",
        align: "left",
        key: "fulfillment_channel",
        ...propsFilter("fulfillment_channel"),
      },
      {
        title: "Ship Service Level",
        dataIndex: "ship_service_level",
        align: "left",
        key: "ship_service_level",
        ...propsFilter("ship_service_level"),
      },
      {
        title: "Item Status",
        dataIndex: "item_status",
        align: "left",
        key: "item_status",
        ...propsFilter("item_status"),
      },
      {
        title: "Order Channel",
        dataIndex: "order_channel",
        align: "left",
        key: "order_channel",
        ...propsFilter("order_channel"),
      },
      {
        title: "URL",
        dataIndex: "url",
        align: "left",
        key: "url",
        ...propsFilter("url"),
      },
      {
        title: "Item Tax ($)",
        dataIndex: "item_tax",
        align: "left",
        key: "item_tax",
        ...propsFilter("item_tax"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Shipping Price ($)",
        dataIndex: "shipping_price",
        align: "left",
        key: "shipping_price",
        ...propsFilter("shipping_price"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Shipping Tax ($)",
        dataIndex: "shipping_tax",
        align: "left",
        key: "shipping_tax",
        ...propsFilter("shipping_tax"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Gift Wrap Price ($)",
        dataIndex: "gift_wrap_price",
        align: "left",
        key: "gift_wrap_price",
        ...propsFilter("gift_wrap_price"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Gift Wrap Tax ($)",
        dataIndex: "gift_wrap_tax",
        align: "left",
        key: "gift_wrap_tax",
        ...propsFilter("gift_wrap_tax"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Ship Promotion Discount ($)",
        dataIndex: "ship_promotion_discount",
        align: "left",
        key: "ship_promotion_discount",
        ...propsFilter("ship_promotion_discount"),
        render: (e) => {
          if (!e || e == 0) {
            return "-";
          }
          return `${addCommasToNumber(parseFloat(e).toFixed(2))}`;
        },
      },
      {
        title: "Ship City",
        dataIndex: "ship_city",
        align: "left",
        key: "ship_city",
        ...propsFilter("ship_city"),
      },
      {
        title: "Ship State",
        dataIndex: "ship_state",
        align: "left",
        key: "ship_state",
        ...propsFilter("ship_state"),
      },
      {
        title: "Ship Postal Code",
        dataIndex: "ship_postal_code",
        align: "left",
        key: "ship_postal_code",
        ...propsFilter("ship_postal_code"),
      },
      {
        title: "Ship Country",
        dataIndex: "ship_country",
        align: "left",
        key: "ship_country",
        ...propsFilter("ship_country"),
      },
      {
        title: "Promotion IDS",
        dataIndex: "promotion_ids",
        align: "left",
        key: "promotion_ids",
        ...propsFilter("promotion_ids"),
      },
      {
        title: "Is Business Order",
        dataIndex: "is_business_order",
        align: "left",
        key: "is_business_order",
        ...propsFilter("is_business_order"),
      },
      {
        title: "Purchase Order Number",
        dataIndex: "purchase_order_number",
        align: "left",
        key: "purchase_order_number",
        ...propsFilter("purchase_order_number"),
      },
      {
        title: "Price Designation",
        dataIndex: "price_designation",
        align: "left",
        key: "price_designation",
        ...propsFilter("price_designation"),
      },
    ],
  };

  const calculateTotal = (data, key) => {
    return data.reduce((sum, item) => sum + parseFloat(item[key]), 0);
  };

  const handleDateRangeChange = (value, dateString) => {
    const selectedPresetRange = presetRanges.filter((range) => {
      return (
        dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
        dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
      );
    });

    const date1 = new Date(dateString?.[0]);
    const date2 = new Date(dateString?.[1]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    setSelectedRangeLine(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    return selectedPresetRange?.length !== 0
      ? selectedPresetRange?.[0]?.label
      : "custom";
  };

  const getCaps = {
    aov: "AOV",
    aiv: "AIV",
    unit_sold: "Units Sold",
  };

  const GetTitle = () => {
    if (resetFlag && resetTitle) {
      return resetTitle || "";
    }
    if ("top10ItemPerformance" === selectedRow?.columns) {
      return "Top 10 Item Performance";
    }
    return selectedRow?.type?.length === 3
      ? "Total Net Sales"
      : selectedRow?.type?.length === 5
      ? "Total Paid Sales"
      : (selectedRow?.type === "organic_sales" ||
          selectedRow?.type === "external_sales" ||
          selectedRow?.type === "paid_sales") &&
        selectedFilters?.source?.length > 1
      ? "Sales Transaction"
      : (selectedRow?.type === "sponsored_products" ||
          selectedRow?.type === "sponsored_brands" ||
          selectedRow?.type === "sponsored_brands_video" ||
          selectedRow?.type === "sponsored_display" ||
          selectedRow?.type === "sponsored_television") &&
        selectedFilters?.[selectedRow?.filterType]?.length > 1
      ? "Paid Sales"
      : (selectedRow?.type === "order_sales" ||
          selectedRow?.type === "order_count" ||
          selectedRow?.type === "unit_sold" ||
          selectedRow?.type === "aov" ||
          selectedRow?.type === "aiv") &&
        selectedFilters?.[selectedRow?.filterType]?.length > 1
      ? "Orders Transaction"
      : getCaps?.[selectedRow?.type] ||
        selectedRow?.type?.split("_")?.join(" ");
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const downloadFileLineItems = async (filename, filtersLine, title) => {
    message.destroy();
    message.loading("Downloading...");
    const response = await MakeApiCall(
      `download-manager/export`,
      "post",
      {
        source_type: page,
        record_type: selectedRow.asin ? "Item Performance" : title,
        start_date: filtersLine?.start_date,
        end_date: filtersLine?.end_date,
        param_filter: {
          start_date: filtersLine?.start_date,
          end_date: filtersLine?.end_date,
          metric_name: selectedRow?.type,
          summary_type: "",
          selection_type: "",
          asin: selectedRow.asin_,
        },
      },
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      history.push("/download-manager");
      console.log(response, "response");
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const downloadExcel = (filename, title) => {
    downloadFileLineItems(filename, filtersLine, title);
    return;
    const wb = XLSX.utils.book_new();

    const changeKeyList = filteredData?.map((d) => {
      const dataObject = {};

      Object?.keys(d || {}).forEach((key) => {
        const indexTitle = columns?.[selectedRow?.columns]?.filter(
          (g) => g?.dataIndex === key
        );
        const { title, dataIndex } = indexTitle?.[0] || [];

        dataObject[title] = d?.[dataIndex]; // You can set initial values to null if needed
      });
      return dataObject;
    });

    // Add a worksheet to the workbook
    const ws = XLSX.utils.json_to_sheet(changeKeyList);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Convert the workbook to a binary Excel file (binary string)
    const excelBinaryString = XLSX.write(wb, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <TableWrapper
      id="kt_content_container"
      className="container-fluid custom-ui mt-5"
    >
      <div className="card">
        <div
          className="card-header"
          style={{
            background: "white",
            zIndex: 9,
            top: "0px",
            position: "sticky",
          }}
        >
          <div className="card-title" style={{ textTransform: "capitalize" }}>
            <div className="me-3">
              <span
                className="svg-icon svg-icon-muted svg-icon-2hx"
                style={{ cursor: "pointer" }}
                onClick={() => close()}
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    opacity="0.3"
                    x={2}
                    y={2}
                    width={20}
                    height={20}
                    rx={5}
                    fill="currentColor"
                  />
                  <path
                    d="M12.0657 12.5657L14.463 14.963C14.7733 15.2733 14.8151 15.7619 14.5621 16.1204C14.2384 16.5789 13.5789 16.6334 13.1844 16.2342L9.69464 12.7029C9.30968 12.3134 9.30968 11.6866 9.69464 11.2971L13.1844 7.76582C13.5789 7.3666 14.2384 7.42107 14.5621 7.87962C14.8151 8.23809 14.7733 8.72669 14.463 9.03696L12.0657 11.4343C11.7533 11.7467 11.7533 12.2533 12.0657 12.5657Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>

            {findFixTitle ? (
              titlesObj?.[selectedRow?.columns]
            ) : selectedRow.asin ? (
              resetFlag ? (
                "Catalog Performance"
              ) : (
                <Flex vertical>
                  <Flex gap={5}>
                    <Typography.Text>
                      ASIN:{" "}
                      <Tag color="blue" bordered={false}>
                        {selectedRow?.asin_ || "-"}
                      </Tag>
                    </Typography.Text>
                    <Typography.Text>
                      SKU:{" "}
                      <Tag color="cyan" bordered={false}>
                        {selectedRow?.sku || "-"}
                      </Tag>
                    </Typography.Text>
                  </Flex>
                  <Flex align="center">
                    <Typography.Text style={{ display: "flex" }}>
                      Item Title:{" "}
                    </Typography.Text>
                    <Tooltip title={selectedRow?.title} placement="bottom">
                      <Tag
                        className="single-line-text"
                        style={{
                          width: "380px",
                          background: "#00000000",
                          position: "relative",
                          top: "1px",
                        }}
                        bordered={false}
                      >
                        {selectedRow?.title || "-"}
                      </Tag>
                    </Tooltip>
                  </Flex>
                </Flex>
              )
            ) : resetFlag && resetTitle ? (
              resetTitle
            ) : selectedRow?.title ? (
              selectedRow?.title || GetTitle()
            ) : (
              GetTitle()
            )}

            <Button
              type="link"
              style={{
                textDecoration: "underline",
              }}
              onClick={() => {
                setResetTable(false);
                setResetFlag(true);
              }}
              className="ms-4"
            >
              Reset Filters
            </Button>
          </div>
          <div className="card-toolbar">
            <Tag color="blue-inverse">
              {selectedRangeLine === "custom"
                ? "Selected Period"
                : selectedRangeLine}
            </Tag>
            <DatePickerView
              // presets={presetRanges}
              // open
              // format={"YYYY-MMM-DD"}
              // allowClear={false}
              // size="middle"
              // className="me-4"
              // style={{
              //   width: "254px",
              //   border: "1px solid #D7D7D7",
              // }}
              value={
                filtersLine?.start_date && filtersLine?.end_date
                  ? [
                      dayjs(filtersLine?.start_date, "YYYY-MM-DD"),
                      dayjs(filtersLine?.end_date, "YYYY-MM-DD"),
                    ]
                  : []
              }
              onChange={(e, _) => {
                const obj = {
                  start_date: e?.start_date
                    ? dayjs(e?.start_date).format("YYYY-MM-DD")
                    : null,
                  end_date: e?.end_date
                    ? dayjs(e?.end_date).format("YYYY-MM-DD")
                    : null,
                };
                handleDateRangeChange(e, _);
                // if (selectedRow?.asin === true) {
                //   getLineItemsList({ ...obj, metric_name: selectedRow?.type });
                //   // return;
                // }
                if (resetFlag) {
                  APIAll({
                    ...obj,
                    metric_name: selectedMetric,
                  });
                } else {
                  getLineItemsList({ ...obj, metric_name: selectedRow?.type });
                  APIAll({
                    ...obj,
                    metric_name: selectedMetric,
                  });
                }

                setFiltersLine(obj);
              }}
              disabledDate={disabledDate}
            />
            <div
              style={{
                background: "#00B660",
                borderRadius: 6,
                padding: "6px 15px",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() =>
                  downloadExcel(
                    selectedRow?.title
                      ? selectedRow?.title || GetTitle()
                      : GetTitle(),
                    selectedRow?.title
                      ? selectedRow?.title || GetTitle()
                      : GetTitle()
                  )
                }
                style={{
                  color: "#FAFAFB",
                  fontSize: 13,

                  fontWeight: 500,
                  letterSpacing: 0.1,
                  wordWrap: "break-word",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  className="me-3"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
                    fill="white"
                  />
                </svg>
                Export
              </div>
            </div>
          </div>
        </div>

        <div className="card-body pt-0 pb-0">
          {listLoading || !resetTable ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <div className="selected-row-financial">
              <Table
                dataSource={(resetFlag ? getAllLineItemList : list)?.map(
                  (d, i) => ({ ...d, key: i + 1 })
                )}
                rowKey={"key"}
                scroll={{
                  x: "max-content",
                  y: selectedRow?.asin
                    ? "calc(100vh - 320px)"
                    : "calc(100vh - 360px)",
                }}
                columns={columns?.[selectedRow?.columns]?.map((d) => {
                  if ((resetFlag ? getAllLineItemList : list)?.length === 0) {
                    return {
                      ...d,
                      className: "minWidth-table",
                      width: d?.title?.length + 22 * 8,
                      render: (props, row, index) =>
                        RenderTable(props, row, index, d, 10),
                    };
                  }
                  return {
                    ...d,
                    className:
                      d?.title === "#" || d?.title === "ID"
                        ? "maxWidth-table"
                        : "minWidth-table",
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d, 2),
                  };
                })}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                pagination={false}
                fixed={true}
                sticky={{
                  offsetHeader: "68px",
                }}
              />
            </div>
          )}
        </div>

        <div
          className="card-footer pt-0"
          style={{
            position: "sticky",
            bottom: 0,
            paddingBottom: "10px",
            zIndex: 9,
            background: "#FFF",
            display: selectedRow?.asin
              ? "none"
              : (calculateTotal(filteredData, "sales") ||
                  calculateTotal(filteredData, "item_price")) == 0
              ? "none"
              : selectedRow?.columns === "Top1000SearchTermsSummary" ||
                selectedRow?.columns === "ItemPerformance"
              ? "none"
              : "block",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              textAlign: "right",
            }}
            className="d-flex align-items-center justify-content-end"
          >
            <span strong>
              {selectedRow?.columns === "paid_funnel" ||
              selectedRow?.columns === "average_external_14d_click" ||
              selectedRow?.columns === "average_total_click" ||
              selectedRow?.columns === "average_14d_paid_click"
                ? "Total Clicks:"
                : "Total Amount:"}{" "}
              <span
                style={{
                  color:
                    (calculateTotal(filteredData, "sales") ||
                      calculateTotal(filteredData, "item_price")) < 0
                      ? "red"
                      : "green",
                }}
              >
                {selectedRow?.columns === "paid_funnel" ||
                selectedRow?.columns === "average_external_14d_click" ||
                selectedRow?.columns === "average_total_click" ||
                selectedRow?.columns === "average_14d_paid_click" ||
                selectedRow?.totalLabel?.label === "Total Clicks"
                  ? ""
                  : "$"}
                {selectedRow?.totalLabel?.label === "Total Clicks" ||
                selectedRow?.columns === "average_external_14d_click" ||
                selectedRow?.columns === "average_total_click" ||
                selectedRow?.columns === "average_14d_paid_click" ||
                selectedRow?.columns === "ads_avd_sponsored_products" ||
                selectedRow?.columns === "ads_sponsored_products"
                  ? addCommasToNumber(
                      calculateTotal(
                        filteredData,
                        selectedRow?.totalLabel?.key
                      ).toFixed(0)
                    )
                  : addCommasToNumber(
                      (
                        calculateTotal(filteredData, "sales") ||
                        calculateTotal(filteredData, "item_price")
                      ).toFixed(2)
                    )}
              </span>
            </span>
          </div>
        </div>
      </div>
    </TableWrapper>
  );
};

export default LineItemsSales;
